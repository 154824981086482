import Messages from '../Api/Messages';
import snackBarStatus from './snackbarActions';

export const ACTIONS = {
  CLEAR_ARCHIVE_MESSAGES_PROPS: '[Messages] clear archive messages props',
  CLEAR_MESSAGES: '[Messages] Clear messages',
  CLEAR_SELECTED_MESSAGE: '[Messages] Clear selected message',
  ERROR: '[Messages] Error',
  GET_MESSAGES: '[Messages] Get messages',
  GET_MORE_MESSAGES: '[Messages] Get more messages',
  GET_MESSAGES_REPLY_NOTIFICATIONS: '[Messages] Get replies notifications',
  MESSAGES_LOADING: '[Messages] Loading',
  MESSAGES_LOADED: '[Messages] Loaded',
  MESSAGES_MORE_LOADING: '[Messages] Loading more',
  CHECK: '[Messages] Check',
  CLEAR_CHECKED: '[Messages] Clear checked',
  SELECT: '[Messages] Select',
  SELECT_TYPE: '[Messages] SelectType',
  SELECT_TYPE_MESSAGE: '[Messages] SelMESSAGES_LOADEDectMessageType',
  SEND_MESSAGE: '[Messages] Send message',
  SET_CODE: '[Messages] Set code',
  UPDATE_IN_LIST: '[Messages] Update message in list',
  CANCEL_SCHEDULED_IN_LIST: '[Messages] Cancel scheduled message in list',
  UPDATE_FILTER_MESSAGE: '[Messages] Update filter message',
  SET_PENDING_REPLIES: '[Messages] Pending replies',
  SENT_MESSAGES_LAST_7_DAYS: '[Messages] Last messages',
  UPDATE_LAST_MESSAGE_DATE: '[Messages] Update last message date',
  GET_STATISTICS_MESSAGE: '[Messages] Get statistics messages',
  MESSAGES_PAGINATION: '[Messages] Pagination update',
  ARCHIVE_MESSAGES: '[Messages] Archive message(s)',
};

const updateLoading = bool => dispatch => dispatch({ type: ACTIONS.MESSAGES_LOADING, payload: bool });
const updateLoaded = bool => dispatch => dispatch({ type: ACTIONS.MESSAGES_LOADED, payload: bool });
const updateMoreLoading = bool => dispatch => dispatch({ type: ACTIONS.MESSAGES_MORE_LOADING, payload: bool });

export const updateMessageInList = message => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_IN_LIST,
    payload: message,
  });
};

export const cancelScheduledMessageInList = messagePublicId => dispatch => {
  dispatch({
    type: ACTIONS.CANCEL_SCHEDULED_IN_LIST,
    payload: messagePublicId,
  });
};

export const getMessages = (type, searchTerm, sort, date, page = 1) =>
  async (dispatch, getState) => {
    try {
      const { groupsReducer: { selectedGroup } } = getState();
      const group = selectedGroup && selectedGroup.public_id ? selectedGroup.public_id : false;

      updateLoading(true)(dispatch);
      updateLoaded(false)(dispatch);
      const response = await Messages.getMessages({
        type,
        searchTerm,
        sort,
        date,
        group,
        page,
      });

      const { data, currentPage, nextPage, previousPage, totalPages } = response.data.data;

      dispatch({ type: ACTIONS.MESSAGES_PAGINATION, payload: { currentPage, nextPage, previousPage, totalPages } });
      dispatch({ type: ACTIONS.GET_MESSAGES, payload: data });

      updateLoaded(true)(dispatch);
      updateLoading(false)(dispatch);
    } catch (e) {
      updateLoaded(true)(dispatch);
      updateLoading(false)(dispatch);
    }
  };

export const getMoreMessages = () => async (dispatch, getState) => {
  try {
    const {
      messagesReducer: { messagesPagination: { currentPage, totalPages } },
      messagesFiltersReducer: { filters: { type, date }, sort, searchTerm },
      groupsReducer: { selectedGroup },
    } = getState();

    const page = currentPage + 1;
    if (totalPages > currentPage) {
      const group = selectedGroup && selectedGroup.public_id ? selectedGroup.public_id : false;

      updateMoreLoading(true)(dispatch);

      const response = await Messages.getMessages({
        type,
        searchTerm,
        sort,
        date,
        group,
        page,
      });

      const { data, currentPage, nextPage, previousPage, totalPages } = response.data.data;

      dispatch({ type: ACTIONS.MESSAGES_PAGINATION, payload: { currentPage, nextPage, previousPage, totalPages } });
      dispatch({ type: ACTIONS.GET_MORE_MESSAGES, payload: data });

      updateMoreLoading(false)(dispatch);
    }
  } catch (e) {
    updateMoreLoading(false)(dispatch);
  }
};

export const refreshMessages = () => async (dispatch, getState) => {
  const {
    messagesReducer: { isLoadedMessages },
    messagesFiltersReducer: { filters: { type, date }, sort, searchTerm },
  } = getState();

  return isLoadedMessages ? getMessages(type, searchTerm, sort, date, 1)(dispatch, getState) : Promise.resolve();
};

// TODO: remove?
export const clearMessages = () => dispatch => {
  dispatch({ type: ACTIONS.CLEAR_MESSAGES });
};

export const clearSelectedMessage = () => dispatch => {
  dispatch({ type: ACTIONS.CLEAR_SELECTED_MESSAGE });
};

export const checkMessage = publicId => dispatch => {
  dispatch({ type: ACTIONS.CHECK, payload: publicId });
};

export const clearCheckedMessages = () => dispatch => {
  dispatch({ type: ACTIONS.CLEAR_CHECKED });
};

export const archiveMessages = publicIds => {
  return dispatch => {
    updateLoading(true)(dispatch);
    return Messages.archiveMessages(publicIds).then(response => {
      if (response.status === 200) {
        clearCheckedMessages()(dispatch);

        snackBarStatus({
          payload: {
            title: `Message${publicIds.length > 1 ? 's' : ''} successfully archived`,
            type: 'success',
            enable: true,
          },
        })(dispatch);
      }

      updateLoading(false)(dispatch);
    });
  };
};

export const deleteMessages = publicIds => {
  return dispatch => {
    updateLoading(true)(dispatch);
    return Messages.deleteMessages(publicIds).then(response => {
      if (response.status === 200) {
        clearCheckedMessages()(dispatch);

        snackBarStatus({
          payload: {
            title: `Message${publicIds.length > 1 ? 's' : ''} successfully deleted`,
            type: 'success',
            enable: true,
          },
        })(dispatch);
      }

      updateLoading(false)(dispatch);
    });
  };
};

export const unArchiveMessages = publicIds => {
  return dispatch => {
    updateLoading(true)(dispatch);
    return Messages.unArchiveMessages(publicIds).then(response => {
      if (response.status === 200) {
        clearCheckedMessages()(dispatch);

        snackBarStatus({
          payload: {
            title: `Message${publicIds.length > 1 ? 's' : ''} successfully unarchived`,
            type: 'success',
            enable: true,
          },
        })(dispatch);
      }

      updateLoading(false)(dispatch);
    });
  };
};

export const selectMessage = message => dispatch =>
  dispatch({ type: ACTIONS.SELECT, payload: message });

export const getPendingReplies = () => async (dispatch, getState) => {
  const { organizationReducer: { organization } } = getState();
  const organizationPublicId = organization ? organization.public_id : localStorage.getItem('selectedOrgPublicId');

  try {
    const { status, data } = await Messages.getPendingReplies(organizationPublicId);

    if (status === 200) {
      dispatch({
        type: ACTIONS.SET_PENDING_REPLIES,
        payload: data.data.unread_message,
      });
    }
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const lastSevenDaysMessages = () => async (dispatch, getState) => {
  const { organizationReducer: { organization } } = getState();
  const organizationPublicId = organization ? organization.public_id : localStorage.getItem('selectedOrgPublicId');

  try {
    const { status, data } = await Messages.lastSevenDaysMessage(organizationPublicId);

    if (status === 200) {
      dispatch({
        type: ACTIONS.SENT_MESSAGES_LAST_7_DAYS,
        payload: data.data.has_sent_message_last_7_days,
      });
    }
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const updateLastMessageDateValue = message => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_LAST_MESSAGE_DATE,
    payload: {
      message,
    },
  });
};

export const getStatisticsMessages = (organization, message) => async dispatch => {
  try {
    const { status, data } = await Messages.getStatisticsMessages(organization, message);

    if (status === 200) {
      dispatch({
        type: ACTIONS.GET_STATISTICS_MESSAGE,
        payload: data.data,
      });
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const clearMessagePagination = () => dispatch => {
  dispatch({
    type: ACTIONS.MESSAGES_PAGINATION,
    payload: [],
  });
};
