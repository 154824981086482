import React from 'react';
import EvaTooltip from '../EvaTooltip';
import PropTypes from 'prop-types';
import './index.sass';

const MyAvailableGroupsList = ({ groups, onAddGroup }) => {
// TODO: move most of params to a component state.

  return <section className="my-available-groups-list">
    <div className="section-title">Available Groups</div>
    <div className="list-wrapper">
      {groups.map(availableGroup => (
        <div className="organization-item-wrapper" key={availableGroup.organization.public_id}>
          <div className="org-name">{availableGroup.organization.name}</div>
          <div className="groups-list-wrapper">
            {availableGroup.groups.map(group => (
              <div className="available-group-item" key={group.public_id}>
                <div className="group-inner-wrapper">
                  <div className="group-name">{group.name}</div>
                  {group.description && (<div className="group-description">{group.description}</div>)}
                </div>
                <EvaTooltip title="Join">
                  <button onClick={() => onAddGroup(group.public_id)} />
                </EvaTooltip>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  </section>;
};

MyAvailableGroupsList.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object).isRequired, // TODO: provide group object
  onAddGroup: PropTypes.func.isRequired,
};

export default MyAvailableGroupsList;
