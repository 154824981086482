import snackBarStatus from './snackbarActions';
import Location from '../Api/Location';

export const ACTIONS = {
  GET_LIST: '[States] Get states',
  LOADING: '[States] Loading',
};

const setLoading = bool => dispatch => {
  dispatch({
    type: ACTIONS.LOADING,
    payload: bool,
  });
};

export const getStates = (countryPublicId) => async (dispatch, getState) => {
  const { statesDictionaryReducer: { hash, loading } } = getState();

  if (!countryPublicId || loading || hash[countryPublicId]) return;

  setLoading(true)(dispatch);

  return Location.getStates(countryPublicId)
    .then((response) => {
      if (response.status === 200) {
        const stateList = response.data.data
          .sort((a, b) => {
            if (a.name > b.name) return 1;
            if (b.name > a.name) return -1;
            return 0;
          })
          .map(o => ({ text: o.name, value: o.public_id }));

        dispatch({ type: ACTIONS.GET_LIST, payload: { countryId: countryPublicId, list: stateList } });
      }
      return response;
    })
    .catch((error) => {
      snackBarStatus({
        payload: {
          title: error.message,
          type: 'error',
          enabled: true,
        },
      })(dispatch);
      return error;
    })
    .finally(() => {
      setLoading(false)(dispatch);
    });
};
