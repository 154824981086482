import { Datepicker } from '@mobiscroll/react';
import React, { useEffect, useState } from 'react';
import { getYears } from '../../Helpers/date';
import './index.sass';

const BirthdayPicker = ({ value, isInvalid, placeholder = 'Day of Birth', onChange }) => {
  const [age, setAge] = useState('');
  const [openPicker, setOpenPicker] = React.useState(false);

  useEffect(() => {
    const diff = getYears(value);
    setAge(diff ? `${diff}y` : '-');
  }, [value]);

  const show = () => setOpenPicker(true);

  const onClose = () => setOpenPicker(false);

  return (
    <div className={`birthday-picker ${isInvalid ? 'invalid' : ''}`}>
      <Datepicker
        controls={['date']}
        inputComponent="input"
        inputProps={{
          className: 'birthday-picker-input',
          placeholder,
        }}
        min={new Date(1900, 1, 1)}
        max={new Date()}
        showOnClick={false}
        showOnFocus={false}
        isOpen={openPicker}
        onClose={onClose}
        value={value}
        onChange={onChange}
        buttons={['set', 'cancel']}
      />
      <button onClick={show}/>
      <input value={age} type="text" className="input-age" readOnly/>
    </div>
  );
};

export default BirthdayPicker;
