import AXIOS from '../Config/AxiosConfig';
import headers from '../Api/Helper';

const baseURL = '/eva/facebook';

const Facebook = {
  getPages(accessToken) {
    return AXIOS.post(
      '/eva/facebook/token/pages',
      { access_token: accessToken },
      { headers: headers() },
    );
  },
  // logOut(data) {
  //   const { integration, groupId } = data;
  //   return AXIOS.delete(
  //     `/eva/facebook/userId=${integration.user_id}&groupId=${groupId}&accessToken=${integration.access_token}`,
  //     { headers: headers() },
  //   );
  // },
  setIntegration(pages, groupId) {
    if (pages && groupId) {
      return AXIOS.post(
        '/eva/facebook/settings',
        {
          group_id: groupId,
          facebook_integrations: pages,
        },
        { headers: headers() },
      );
    }

    return null;
  },


  getOrgFbPages(publicId) {
    return AXIOS.get(`${baseURL}/organization/${publicId}`, {
      headers: headers(),
    });
  },
  submitOrgFbToken(publicId, token) {
    return AXIOS.post(`${baseURL}/organization/${publicId}`, { token }, {
      headers: headers(),
    });
  },
  removeOrgFbConnection(publicId) {
    return AXIOS.post(`${baseURL}/organization/${publicId}/remove`, {}, {
      headers: headers(),
    });
  },
  // getGroupFbPages(publicId) {
  //   return AXIOS.get(`${baseURL}/group/${publicId}/pages`, {
  //     headers: headers()
  //   });
  // },
  updateGroupFbPages(publicId, pagesPublicIdsArray) {
    return AXIOS.post(`${baseURL}/group/${publicId}`, { facebookPages: pagesPublicIdsArray }, {
      headers: headers(),
    });
  },

};

export default Facebook;
