import existObject from './ObjectUtilities';

export const findAllMembersInArray = attr => item =>
  item[attr] === 'All Members';

export const putAllMembersFirst = (groups, attr = 'name') => {
  if (!Array.isArray(groups)) {
    return groups;
  }

  const allMembers = groups.find(findAllMembersInArray(attr));

  if (!allMembers) {
    return groups;
  }

  const index = groups.findIndex(findAllMembersInArray(attr));

  if (index > 0) {
    groups.splice(index, 1);
    groups.unshift(allMembers);
  }
  return groups;
};

export const extractOrgFromGroup = group =>
  group && group.group && group.group.organization;

export const doesGroupBelongToOrg = (group, org) => {
  const groupOrg = extractOrgFromGroup(group);

  return groupOrg && groupOrg.public_id === org.public_id;
};

export const filterGroupsByOrg = (groups, org) => {
  if (!Array.isArray(groups) || !org || !org.public_id) {
    return groups;
  }

  return groups.filter(group => doesGroupBelongToOrg(group, org));
};

export const findGroupInArray = group => arrayGroup => {
  const finalGroup = existObject(group, 'group') ? group.group : group;
  const finalArrayGroup = existObject(arrayGroup, 'group')
    ? arrayGroup.group
    : arrayGroup;
  const isGroupInArray = finalGroup.publicId === finalArrayGroup.public_id;

  return isGroupInArray && !finalArrayGroup.organization_general_group;
};

export const filterGroupsByGroupAdmin = (groups, groupAdminGroups) => {
  if (!Array.isArray(groups)) {
    return groups;
  }

  if (!Array.isArray(groupAdminGroups)) {
    return [];
  }

  return groups.filter(group => groupAdminGroups.some(findGroupInArray(group)));
};

export const reduceGroupAdminGroups = (allGroups, orgGroups) => {
  const hasGroups =
    existObject(orgGroups, 'groups') && Array.isArray(orgGroups.groups);
  return hasGroups ? allGroups.concat(orgGroups.groups) : allGroups;
};

export const mergeGroupAdminGroups = groupsByOrg => {
  if (!Array.isArray(groupsByOrg)) {
    return groupsByOrg;
  }

  return groupsByOrg.reduce(reduceGroupAdminGroups, []);
};

export const allGroupsToShow = allGroups => {
  const sortAll = allGroups.sort((a, b) => {
    if (a.text.toLowerCase() > b.text.toLowerCase()) {
      return 1;
    }
    if (b.text.toLowerCase() > a.text.toLowerCase()) {
      return -1;
    }
    return 0;
  });

  const extractAllMembersGroup = sortAll.filter(
    group =>
      group.text
        .toLowerCase()
        .trim()
        .replace(/\s/g, '') === 'allmembers',
  );

  return [...extractAllMembersGroup, ...sortAll].filter(
    (value, index, self) => self.indexOf(value) === index,
  );
};

export const getGroupsFromDictionary = (publicIdsArray, dictionary) =>
  (publicIdsArray && publicIdsArray.length > 0 && dictionary && dictionary.length > 0) ?
    dictionary.filter(g => publicIdsArray.includes(g.publicId)) : [];
