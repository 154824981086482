import { ACTIONS } from '../Actions/groupsDictionaryActions';

const initialState = {
  list: [],
  allMembersPublicId: null,
  isLoaded: false,
  isLoading: false,
};

const groupsDictionaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.LOAD:
      const isAllMembersGroup = action.payload.find(g => g.organizationGeneralGroup);
      return {
        ...state,
        allMembersPublicId: isAllMembersGroup ? isAllMembersGroup.publicId : null,
        list: action.payload,
        isLoaded: true,
      };
    case ACTIONS.CLEAR:
      return {
        ...state,
        list: [],
        isLoaded: false,
      };
    case ACTIONS.LOADED:
      return { ...state, isLoaded: action.payload };
    case ACTIONS.LOADING:
      return { ...state, isLoading: action.payload };
    case ACTIONS.ADD:
      return {
        ...state,
        list: state.list.concat(action.payload),
      };
    case ACTIONS.UPDATE:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.publicId === action.payload.publicId) {
            return {
              ...item,
              ...action.payload,
              group: {
                ...item.group,
                ...action.payload.group,
              },
            };
          }
          return { ...item };
        }),
      };
    default:
      return state;
  }
};

export default groupsDictionaryReducer;
