import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import SvgIcon from '@material-ui/core/SvgIcon';
// Eva Components
import EditGroup from '../../Containers/Groups/EditGroup';
import MemberList from '../MemberList';
import Groups from '../Groups';
import NotAvaileable from '../NotAvaileable';
import SettingsGroup from '../SettingsGroup';
// Action Creators
import { changeTab, OPTIONS } from '../../Actions/middleMenuActions';
import { clearShowMemberFromGroup } from '../../Actions/memberActions';
import { enableRightDrawer as enableRightDrawerShort } from '../../Actions/rightDrawerShortActions';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import { changeStatusButton, getGroupsActivity, handleMiddleMenuLeft } from '../../Actions/groupsActions';
import { openCustomModal } from '../../Actions/customModalActions';
// PropTypes
import MiddleMenuTabsPropType from '../../PropTypes/MiddleMenuTabsPropType';
import MiddleMenuPropType from '../../PropTypes/MiddleMenuPropType';
// Styles
import Notification from '../../Components/AnimatedNotification';
import Group from './Group';
import Helpers from '../../Helpers';
import GroupInviteMembers from '../../Containers/GroupInviteMembers';
import Statistics from '../../Containers/Statistics';
import EvaTooltip from '../EvaTooltip';
import GroupMessages from '../../Containers/GroupMessages';
import BackIcon from '../../Theme/Icons/back-messages.svg';
import colors from '../../Theme/Colors';
import ButtonStandard from '../Button';
import { MIN_TABLET_WIDTH } from '../../Config/Constants/Responsive';
// import MessageModalManager, { MESSAGE_MODAL_MANAGER_VIEWS } from '../../Containers/MessageModalManager';
import './index.sass';

const MiddleMenu = props => (
  <Typography component="div" style={{ backgroundColor: '#FAFAFA' }}>
    {props.children}
  </Typography>
);

MiddleMenu.propTypes = MiddleMenuPropType;

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderLeft: '2px solid #e3e3e3',
    flexGrow: 1,
    height: window.innerHeight,
  },
  [theme.breakpoints.only('xs')]: {
    root: {
      borderLeft: '0',
    },
    tabRoot: {
      minWidth: '25% !important',
      marginTop: '0px !important',
    },
    tabRootBack: {
      minWidth: '25% !important',
      marginTop: '0px !important',
      borderBottom: '2px solid #dcdcdc',
    },
    tabsRoot: {
      marginTop: '0px !important',
    },
    flexContainer: {
      flexDirection: 'row !important',
    },
  },
  [theme.breakpoints.only('sm')]: {
    root: {
      borderLeft: '0',
    },
    tabRoot: {
      minWidth: '25% !important',
      marginTop: '0px !important',
    },
    tabRootBack: {
      minWidth: '25% !important',
      marginTop: '0px !important',
      borderBottom: '2px solid #dcdcdc',
    },
    flexContainer: {
      flexDirection: 'row !important',
    },
    tabsRoot: {
      marginTop: '0px !important',
    },
  },
  flexContainer: {
    flexDirection: 'column',
    padding: '6px !important',
  },
  wrapper: {
    width: '100vw',
    marginTop: '25px',
    '&.desktop': {
      width: 'auto',
      marginTop: 'unset',
    },
  },
  tabRoot: {
    minWidth: '100%',
    marginTop: '20px',
  },
  tabRootBack: {
    minWidth: '25% !important',
    marginTop: '0px !important',
    borderBottom: '2px solid #dcdcdc',
  },
  tabsIndicator: {
    background: 'transparent',
  },
  disabledBack: {
    display: 'none',
  },
  tabsRoot: {
    display: 'block',
    textTransform: 'initial',
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    color: '#a9a9a9',
    width: '100%',
    marginTop: '20px',
    '&:focus': {
      color: '#9a66bf',
    },
  },
  tabsRootLeft: {
    display: 'block',
    textTransform: 'initial',
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    color: '#a9a9a9',
    width: '100%',
    marginTop: '5px',
    paddingRight: '5px',
    '&:focus': {
      color: '#9a66bf',
    },
  },
  tabSelected: { color: '#9a66bf' },
  appBar: {
    background: '#fff',
  },
  tabsContainer: {
    zIndex: 10,
    marginTop: '4px',
    borderLeft: `2px solid ${colors.greyCheck}`,
    boxShadow: '5px 0 10px 0 #dcdcdc',
  },
});

class MiddleMenuTabs extends React.Component {
  componentDidMount() {
    const { organization, main_role } = this.props;
    if (this.props.isAddMember && window.innerWidth <= MIN_TABLET_WIDTH) {
      this.props.changeTab(<GroupInviteMembers/>, 7);
    } else if (window.innerWidth > MIN_TABLET_WIDTH) {
      const initialTransitionState = {
        statusButton1: false,
        statusButton2: false,
        transitions: {
          transition1: false,
          transition2: false,
          transition3: false,
          transition4: false,
        },
      };
      this.props.changeStatusButton({ payload: initialTransitionState });
      this.props.getGroupsActivity(organization.public_id, main_role).then(res => {
        if (res.status === 200) {
          this.props.changeTab(<div/>, 10);
          this.startNotificationsAnimation();
        }
      });
    }
  }

  componentWillUnmount() {
    this.props.changeTab(<div/>, 10);
  }

  getNotifications = () => {
    const { isLiteMode, groupsActivity, statusButton1, statusButton2, transitions } = this.props;
    // plurals forms of the data identifiers
    // const activeGroupsIdentfier =
    //   groupsActivity.increaseActivity && groupsActivity.increaseActivity === 1
    //     ? 'group that has'
    //     : 'groups that have';
    // const inactiveGroupsIdentfier =
    //   groupsActivity.decreaseActivity && groupsActivity.decreaseActivity === 1
    //     ? 'group that has'
    //     : 'groups that have';
    const membersIdentfier =
      groupsActivity.memberActivity && groupsActivity.memberActivity === 1 ? 'member' : 'members';
    const { transition1, transition2, transition3, transition4 } = transitions;

    if (isLiteMode) {
      return '';
    }

    return (
      <div id="notification-list">
        {transition3 && (
          <React.Fragment>
            <Notification
              texts={[
                'Your groups have no new members this week. Would you like to invite new members?',
              ]}
              onAnimationComplete={() => this.startNextAnimationState(3)}
            />
            {statusButton2 && (
              <ButtonStandard
                className="notifications-button"
                title="Invite new members"
                onClick={() => this.handleInviteMembers()}
              />
            )}
          </React.Fragment>
        )}
        {transition4 && (
          <React.Fragment>
            <Notification
              texts={[
                `Your groups have ${groupsActivity.memberActivity} new ${membersIdentfier} this week. Great job!`,
              ]}
            />
          </React.Fragment>
        )}
      </div>
    );
  };

  getActivityStatus = status => {
    let value = '';
    switch (status) {
      case 'engaged':
        value = 'status-engaged';
        break;
      case 'active':
        value = 'status-active';
        break;
      case 'static':
        value = 'status-static';
        break;
      case 'inactive':
        value = 'status-inactive';
        break;
      default:
        break;
    }
    return value;
  };

  handleInviteMembers = () => {
    this.props.changeTab(<GroupInviteMembers/>, 7);
  };

  startNextAnimationState = transitionNumber => {
    const { groupsActivity, transitions } = this.props;

    switch (transitionNumber) {
      case 1:
        setTimeout(() => {
          // if (groupsActivity.decreaseActivity > 0) {
          //   this.props.changeStatusButton({
          //     payload: { transitions: { ...transitions, transition2: true } },
          //   });
          // }
          // else if (groupsActivity.memberActivity === 0) {
          if (groupsActivity.memberActivity === 0) {
            this.props.changeStatusButton({
              payload: { transitions: { ...transitions, transition3: true } },
            });
          } else if (groupsActivity.memberActivity > 0) {
            this.props.changeStatusButton({
              payload: { transitions: { ...transitions, transition4: true } },
            });
          }
        }, 500);
        break;
      case 2:
        this.props.changeStatusButton({
          // display the button of bubble 2
          payload: { statusButton1: true },
        });
        setTimeout(() => {
          if (groupsActivity.memberActivity === 0) {
            this.props.changeStatusButton({
              payload: { transitions: { ...transitions, transition3: true } },
            });
          } else if (groupsActivity.memberActivity > 0) {
            this.props.changeStatusButton({
              payload: { transitions: { ...transitions, transition4: true } },
            });
          }
        }, 500);
        break;
      case 3:
        this.props.changeStatusButton({
          // display the button of bubble 3
          payload: { statusButton2: true },
        });
        break;
      default:
        break;
    }
  };

  startNotificationsAnimation = () => {
    const { groupsActivity, transitions } = this.props;

    // if (groupsActivity.increaseActivity > 0) {
    //   this.props.changeStatusButton({
    //     payload: { transitions: { ...transitions, transition1: true } },
    //   });
    // } else if (groupsActivity.decreaseActivity > 0) {
    //   this.props.changeStatusButton({
    //     payload: { transitions: { ...transitions, transition2: true } },
    //   });
    // } else if (groupsActivity.memberActivity === 0) {
    if (groupsActivity.memberActivity === 0) {
      this.props.changeStatusButton({
        payload: { transitions: { ...transitions, transition3: true } },
      });
    } else if (groupsActivity.memberActivity > 0) {
      this.props.changeStatusButton({
        payload: { transitions: { ...transitions, transition4: true } },
      });
    }
  };

  resetMiddleMenu = () => {
    const resetMiddleMenuParams = {
      status: '',
      dinamicGrid: 7,
      dinamicGridMiddleMenu: 12,
    };
    const closeMiddleMenuParams = {
      status: 'animation-left-out',
      dinamicGrid: 7,
      dinamicGridMiddleMenu: 12,
    };
    this.props.handleMiddleMenuLeft(closeMiddleMenuParams);
    setTimeout(() => {
      this.props.handleMiddleMenuLeft(resetMiddleMenuParams);
    }, 1000);
  };

  handleChange = (event, value) => {
    event.preventDefault();
    let selectedComponent;
    let valueMenu = value;
    const { selectedGroup, middleMenuLeft: { status } } = this.props;
    const memberQuantity = selectedGroup ? selectedGroup.members_quantity : 0;

    if (window.innerWidth > MIN_TABLET_WIDTH) {
      this.props.enableRightDrawer({ payload: { enable: false } });
    }
    this.props.enableRightDrawerShort({ payload: { enable: false } });

    if (status === 'animation-left-on' && valueMenu !== OPTIONS.BACK_MIDDLE_MENU) {
      this.resetMiddleMenu();
    }

    switch (valueMenu) {
      case OPTIONS.BACK_MIDDLE_MENU:
        selectedComponent = <GroupMessages />;
        valueMenu = 2;
        this.resetMiddleMenu();
        break;
      case OPTIONS.MEMBERS_OPTION:
        this.props.clearShowMemberFromGroup();
        if (selectedGroup) {
          if (memberQuantity > 0) {
            selectedComponent = <MemberList/>;
          } else {
            selectedComponent = <Groups/>;
          }
        }
        break;

      case OPTIONS.STATS_OPTION:
        if (selectedGroup) {
          selectedComponent = <Statistics/>;
        }
        break;

      case OPTIONS.MESSAGES_OPTION:
        if (selectedGroup) {
          selectedComponent = <GroupMessages />;
        }
        break;

      case OPTIONS.SETTINGS_OPTION:
        if (selectedGroup) {
          if (selectedGroup.organization_general_group) {
            selectedComponent = <SettingsGroup/>;
          } else {
            selectedComponent = <EditGroup {...this.props} />;
          }
        }
        break;

      case OPTIONS.BUBBLE_MESSAGE_OPTION:
      default:
        if (selectedGroup) {
          selectedComponent = <NotAvaileable/>;
        }
        break;
    }
    this.props.changeTab(selectedComponent, valueMenu);
  };

  render() {
    const {
      classes,
      tab,
      middleMenuStatus,
      selectedGroup,
      middleMenuLeft: { status },
      windowWidth,
      isMessagesLoading,
      messagesPagination,
    } = this.props;
    const WindowHeight = window.innerHeight;
    const isBackButton = status !== 'animation-left-on' && true;
    return selectedGroup && (
      <div className={`${classes.wrapper} ${windowWidth > MIN_TABLET_WIDTH ? 'desktop' : ''}`}>
        <Hidden mdUp>
          <Group
            group={selectedGroup.name}
            date={Helpers.getDate(selectedGroup.updated_at)}
            description={selectedGroup.description}
            membersCount={selectedGroup.members_quantity}
            activityStatus={this.getActivityStatus(selectedGroup.activity_status)}
            status={selectedGroup.status}
            subGroupQuantity={selectedGroup.sub_groups_quantity}
          />
        </Hidden>
        <Grid container>
          {middleMenuStatus && (
            <Grid item xs={12} md={1} id="middle-menu-ipad" className={classes.tabsContainer}>
              <Tabs
                classes={{
                  root: status !== 'animation-left-on' ? classes.tabsRoot : classes.tabsRootLeft,
                  indicator: classes.tabsIndicator,
                  flexContainer: classes.flexContainer,
                }}
                value={tab}
                onChange={this.handleChange}
                variant="fullWidth"
              >
                <Tab
                  classes={{
                    root: classes.tabRootBack,
                    selected: classes.tabSelected,
                    disabled: classes.disabledBack,
                  }}
                  icon={
                    <EvaTooltip title="Back">
                      <img src={BackIcon} alt="Back"/>
                    </EvaTooltip>
                  }
                  disabled={isBackButton}
                />
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  icon={
                    <EvaTooltip title="Members">
                      <SvgIcon>
                        <path
                          d="M8,11 C11.032,11 13.5,8.533 13.5,5.5 C13.5,2.467 11.032,0 8,0 C4.968,0 2.5,2.467 2.5,5.5 C2.5,8.533 4.967,11 8,11 Z M17,3 L23,3 C23.55,3 24,2.55 24,2 C24,1.45 23.55,1 23,1 L17,1 C16.45,1 16,1.45 16,2 C16,2.55 16.45,3 17,3 Z M23,11 L17,11 C16.45,11 16,11.45 16,12 C16,12.55 16.45,13 17,13 L23,13 C23.55,13 24,12.55 24,12 C24,11.45 23.55,11 23,11 Z M8,13 C3.589,13 0,15.411 0,18.375 L0,22 L16,22 L16,18.375 C16,15.411 12.411,13 8,13 Z M23,6 L17,6 C16.45,6 16,6.45 16,7 C16,7.55 16.45,8 17,8 L23,8 C23.55,8 24,7.55 24,7 C24,6.45 23.55,6 23,6 Z"/>
                      </SvgIcon>
                    </EvaTooltip>
                  }
                  disabled={!selectedGroup.isEnabled}
                />
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  icon={
                    <EvaTooltip title="Messages">
                      <SvgIcon>
                        <path
                          d="M22 0H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h5v5l7-5h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-9 13H7c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm4-5H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1z"/>
                      </SvgIcon>
                    </EvaTooltip>
                  }
                  disabled={!selectedGroup.isEnabled}
                />
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  icon={
                    <EvaTooltip title="Statistics">
                      <SvgIcon>
                        <path
                          d="M21.9960835,5.99975001 L20.0031665,5.99975001 C18.8962127,5.99975001 17.99925,6.89671264 17.99925,8.00366651 L17.99925,21.9960835 C17.99925,23.1020374 18.8962127,24 20.0031665,24 L21.9960835,24 C23.1030374,24 24,23.1030374 24,21.9960835 L24,8.00366651 C24,6.89671264 23.1030374,5.99975001 21.9960835,5.99975001 Z M3.99683347,10.9995417 L2.0039165,10.9995417 C0.896962627,10.9995417 0,11.8965043 0,13.0034582 L0,21.9960835 C0,23.1020374 0.896962627,24 2.0039165,24 L3.99683347,24 C5.10378734,24 6.00074997,23.1030374 6.00074997,21.9960835 L6.00074997,13.0034582 C6.00074997,11.8965043 5.10378734,10.9995417 3.99683347,10.9995417 Z M12.9964585,0 L11.0035415,0 C9.89658764,0 8.99962502,0.896962627 8.99962502,2.0039165 L8.99962502,21.9960835 C8.99962502,23.1020374 9.89658764,24 11.0035415,24 L12.9964585,24 C14.1034124,24 15.000375,23.1030374 15.000375,21.9960835 L15.000375,2.0039165 C15.000375,0.896962627 14.1034124,0 12.9964585,0 Z"/>
                      </SvgIcon>
                    </EvaTooltip>
                  }
                  disabled={!selectedGroup.isEnabled}
                />
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  icon={
                    <EvaTooltip title="Settings">
                      <SvgIcon>
                        <path
                          d="M21.2847007,14.456 L19.7287007,13.148 C19.7757007,12.771 19.8087007,12.389 19.8087007,11.999 C19.8087007,11.609 19.7757007,11.227 19.7287007,10.85 L21.2847007,9.542 C22.0237007,8.921 22.2147007,7.844 21.7357007,6.998 L20.6067007,5.001 C20.1277007,4.154 19.1207007,3.785 18.2247007,4.128 L16.3317007,4.853 C15.7367007,4.389 15.0877007,3.994 14.3887007,3.695 L14.0587007,1.671 C13.9007007,0.707 13.0847007,0 12.1277007,0 L9.87070068,0 C8.91370068,0 8.09670068,0.707 7.93970068,1.671 L7.60970068,3.695 C6.90970068,3.994 6.26070068,4.389 5.66670068,4.853 L3.77370068,4.128 C2.87770068,3.785 1.86970068,4.154 1.39070068,5.001 L0.262700678,6.998 C-0.215299322,7.845 -0.0252993224,8.921 0.713700678,9.542 L2.26970068,10.85 C2.22270068,11.227 2.18970068,11.609 2.18970068,11.999 C2.18970068,12.389 2.22270068,12.771 2.26970068,13.148 L0.713700678,14.456 C-0.0252993224,15.077 -0.216299322,16.154 0.262700678,17 L1.39170068,18.997 C1.87070068,19.844 2.87770068,20.213 3.77370068,19.87 L5.66670068,19.145 C6.26170068,19.609 6.91070068,20.004 7.60970068,20.303 L7.93970068,22.327 C8.09770068,23.291 8.91370068,23.998 9.87070068,23.998 L12.1277007,23.998 C13.0847007,23.998 13.9017007,23.291 14.0587007,22.327 L14.3887007,20.303 C15.0887007,20.004 15.7377007,19.609 16.3317007,19.145 L18.2247007,19.87 C19.1207007,20.213 20.1287007,19.844 20.6077007,18.997 L21.7357007,17 C22.2147007,16.153 22.0237007,15.077 21.2847007,14.456 Z M10.9987007,15.998 C8.78970068,15.998 6.99870068,14.207 6.99870068,11.998 C6.99870068,9.789 8.78970068,7.998 10.9987007,7.998 C13.2077007,7.998 14.9987007,9.789 14.9987007,11.998 C14.9987007,14.207 13.2077007,15.998 10.9987007,15.998 Z"/>
                      </SvgIcon>
                    </EvaTooltip>
                  }
                />
              </Tabs>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={11} className="middle-menu-container-wrapper">
            <MiddleMenu>
              <div className="content-inner-wrapper">
                <Scrollbars
                  autoHeight
                  autoHide
                  autoHeightMin={WindowHeight - 214}
                  autoHeightMax={WindowHeight - 214}
                  onUpdate={({ top }) => {
                    if (
                      top > 0.99 &&
                      messagesPagination.nextPage &&
                      !isMessagesLoading &&
                      tab === OPTIONS.MESSAGES_OPTION
                    ) {
                      // this.handleScrollFrame();
                    }
                  }}
                >
                  <ReactCSSTransitionReplace
                    transitionName="cross-fade"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={10}
                  >
                    <div key={`${this.props.tab}-uniqueKey`}>
                      {this.props.tab === 10 ? this.getNotifications() : this.props.element}
                    </div>
                  </ReactCSSTransitionReplace>
                </Scrollbars>
              </div>
            </MiddleMenu>
          </Grid>
        </Grid>
      </div>
    );
  }
}

MiddleMenuTabs.propTypes = MiddleMenuTabsPropType;

MiddleMenuTabs.defaultProps = {};

const mS = ({
              middleMenuReducer: { element, tab },
              groupsReducer: {
                organizations,
                selectedGroup,
                middleMenuStatus,
                groupsActivity,
                statusButton1,
                statusButton2,
                transitions,
              },
              organizationReducer: { organization, organization: { lite: isLiteMode } },
              userProfileReducer: { main_role },
              responsiveReducer: { windowWidth },
              messagesReducer: { messagesPagination, isMessagesLoading },
            }) => ({
  element,
  tab,
  organizations,
  selectedGroup,
  middleMenuStatus,
  organization,
  groupsActivity,
  statusButton1,
  statusButton2,
  transitions,
  main_role,
  windowWidth,
  messagesPagination,
  isMessagesLoading,
  isLiteMode,
});

const mD = {
  changeTab,
  clearShowMemberFromGroup,
  enableRightDrawer,
  enableRightDrawerShort,
  openCustomModal,
  handleMiddleMenuLeft,
  getGroupsActivity,
  changeStatusButton,
};

export default withStyles(styles)(connect(mS, mD, null, { forwardRef: true })(MiddleMenuTabs));
