import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import ContentEditable from 'react-contenteditable';
import moment from 'moment';
import Avatar from '../../Components/Avatar';
import speechBubble from '../../Theme/Icons/speech-bubble.svg';
import memberImage from '../../Theme/Icons/member.svg';
import Color from '../../Theme/Colors';
import StartIcon from '../../Theme/Icons/start.png';
import CommentIcon from '../../Theme/Icons/mpa-comment.png';
import ReplyIcon from '../../Theme/Icons/mpa-reply.png';
import MyParishAppPostPostviewPropType from '../../PropTypes/MyParishAppPostPostviewPropType';
import './index.sass';

const styles = theme => ({
  tabsIndicator: {
    background: 'linear-gradient(to right, #9966bf 0%, #6174c9 98%)',
  },
  tabsRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    color: Color.grey,

    '&:focus': {
      color: Color.purple,
    },
  },
  starIcon: {
    width: '17px',
    height: '17px',
  },
  replyIcon: {
    width: '19px',
    height: '18px',
  },
  commentIcon: {
    width: '18px',
    height: '18px',
  },
});

class MyParishAppPostPostview extends Component {

  renderPreviewAllMembers = () => {
    const { classes, content } = this.props;

    return (
      <div className="message-container post-view">
        <div className="my-parish-app-all-content" id="scroll">
          <div className="my-parish-app-message show">
            <div className="my-parish-app-header">
              <ContentEditable
                innerRef={this.contentEditable}
                className="my-parish-app-body-text"
                id="scroll"
                tagName="p"
                disabled
                html={content}
              />
              <div className="mpa-preview-icons-container">
                <div className="mpa-preview-icons">
                  <img className={classes.starIcon} src={StartIcon} alt="speech-bubble" />
                </div>
                <div className="mpa-preview-icons">
                  <img className={classes.replyIcon} src={ReplyIcon} alt="speech-bubble" />
                </div>
                <div className="mpa-preview-icons">
                  <img className={classes.commentIcon} src={CommentIcon} alt="speech-bubble" />
                </div>
              </div>
            </div>
            <div className="mpa-all-footer">{moment().format('MMM DD, h:mm A')} | Message</div>
          </div>
        </div>
      </div>
    );
  };

  renderPreview = () => {
    const { content, subject, profileName, profilePic } = this.props;
    return (
      <div className="message-container">
        <div className="my-parish-app-content" id="scroll">
          <div className={'my-parish-app-message'}>
            <div className="my-parish-app-header">
              <Grid item xs={1} className="member-message-my-parish-app">
                <Avatar
                  borderless
                  className="my-parish-app-member-picture"
                  image={profilePic || memberImage}
                />
              </Grid>
              <div className="my-parish-app-header-text">
                <p className="my-parish-app-name">{profileName}</p>
                <div className="my-parish-app-lower-header">
                  <p className="my-parish-app-comments-count">Now | 0 Comments </p>
                </div>
              </div>
              <div className="my-parish-app-speech-bubble">
                <img src={speechBubble} alt="speech-bubble" />
                <span>Comment</span>
              </div>
            </div>
            <div className="my-parish-app-title">{subject}</div>
            <ContentEditable
              className="my-parish-app-body-text"
              id="scroll"
              tagName="pre"
              html={content}
              disabled
            />
          </div>
        </div>
      </div>
    );
  };

  render = () => (
    <div className="send-my-parish-app-container">
      {this.props.toAllMembers ? this.renderPreviewAllMembers() : this.renderPreview()}
    </div>
  )
}
MyParishAppPostPostview.propTypes = MyParishAppPostPostviewPropType;

export default withStyles(styles)(MyParishAppPostPostview);
