import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { requestPublicGroupAdminPromotionInfoAction } from '../../Actions/invitationActions';
import organizationImage from '../../Theme/Icons/org-icon.svg';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import { logout } from '../../Actions/loginActions';
import { isEmpty } from '../../Helpers/ObjectUtilities';
import InviteScreen from '../../Components/InviteScreen';
import { redirectToLogin } from '../../Helpers/redirectToLogin';
import getMemberInfo from '../../Helpers/getMemberInfo';
import { camelCaseObjects } from '../../Helpers/Utility';
import LoadingScreen from '../../Components/LoadingScreen';
import { injectIntl } from 'react-intl';

class InvitationGroupAdmin extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isInvitedUserLogged: false,
      isUserLogged: false,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { publicId, invitationToken },
      },
    } = this.props;
    this.props.requestPublicGroupAdminPromotionInfoAction(publicId, invitationToken);
  }

  static getDerivedStateFromProps = nextProps => {
    const { loggedMember, member } = nextProps;
    return {
      isInvitedUserLogged: member && loggedMember && member.public_id && member.public_id === loggedMember.public_id,
      isUserLogged: !!loggedMember.public_id,
    };
  };

  handleLogin = () => {
    if (this.state.isUserLogged) {
      this.props.logout(this.props.location.pathname);
    } else {
      redirectToLogin(this.props.location.pathname);
    }
  };

  handleLogout = () => this.props.logout(this.props.location.pathname);

  handleJoin = () => {
    const { publicId } = this.props.match.params;
    const url = `/invitation-submit/group/${publicId}`;

    if (this.state.isUserLogged) {
      this.props.logout(url);
    } else {
      this.props.navigationControllerRedirect(url);
    }
  };

  handleRegister = () => {
    const {
      match: {
        params: { publicId, invitationToken },
      },
    } = this.props;

    this.props.navigationControllerRedirect(`/complete-profile/${publicId}/${invitationToken}`);
  };

  render() {
    const {
      intl,
      member,
      group,
      group: { organization },
      notFound,
    } = this.props;

    let displayedName = getMemberInfo(camelCaseObjects(member)).displayedFullName;

    if (isEmpty(group)) {
      return <LoadingScreen/>;
    }

    if (isEmpty(group) && notFound) {
      this.props.navigationControllerRedirect('/not-found');
    }

    const picture = organization && organization.main_picture_url ?
      organization.main_picture_url : organizationImage;
    const title = group ? group.name : 'Nameless Group';
    const subtitle = organization ? organization.name : 'Nameless Org';

    return (
      <Scrollbars autoHeight autoHeightMin="100vh">
        <InviteScreen
          title={title}
          subtitle={subtitle}
          pictureUrl={picture}
          inviteTextHtml="You have been promoted to <br />Group admin of"
          registerButtonText={intl.formatMessage({ id: 'action.registerToJoin' })}
          loginButtonText={intl.formatMessage({ id: 'action.loginAndJoin' })}
          userName={displayedName}
          isInvitedUserLogged={this.state.isInvitedUserLogged}
          isActiveUser={member.status === 'active'}
          onRegister={this.handleRegister}
          onJoin={this.handleJoin}
          onLogin={this.handleLogin}
          onLogout={this.handleLogout}
        />
      </Scrollbars>
    );
  }
}

const mS = state => ({
  group: state.invitationReducer.group,
  member: state.invitationReducer.member,
  loggedMember: state.userProfileReducer.member,
  notFound: state.invitationReducer.notFound,
});

const mD = {
  requestPublicGroupAdminPromotionInfoAction,
  navigationControllerRedirect,
  logout,
};

export default connect(
  mS,
  mD,
)(injectIntl(InvitationGroupAdmin));
