import React from 'react';
import './index.sass';
import ButtonStandard from '../Button';
import { SUPPORT_PHONE_NUMBER } from '../../Config/env';

export const SuspendedOverlay = props => {
  const { onMenuToggle, onNavigateToMyProfile } = props;

  return (
    <div className="suspended-overlay">
      <div className="suspended-modal">
        <button className="drawer-toggle-label" onClick={onMenuToggle}></button>
        <div className="suspended-modal-title">Organization Suspend</div>
        <div className="suspended-modal-content">
          Access to this organization in Evangelus has been suspended.
          If you would like to reinstate this account or have any questions
          please contact customer support at {SUPPORT_PHONE_NUMBER} <br />or through
          this&nbsp;<a
          href="https://www.diocesan.com/quicksupport"
          target="_blank"
          rel="noopener noreferrer"
        >
          support form
        </a>
          .
        </div>

        <div className="suspended-modal-actions">
          <ButtonStandard
            title="View my profile"
            onClick={onNavigateToMyProfile}
          />
        </div>

      </div>
    </div>
  );
};



export default SuspendedOverlay;
