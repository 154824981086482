import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import { enableRightDrawer as enableRightDrawerShort } from '../../Actions/rightDrawerShortActions';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import { enableRightDrawerMessage } from '../../Actions/rightDrawerMessageActions';
import { enableRightDrawerTemplates } from '../../Actions/rightDrawerTemplatesActions';
import { enableRightDrawerGroupMessage } from '../../Actions/rightDrawerGroupMessagesActions';
import snackBarStatus from '../../Actions/snackbarActions';
import changeTab from '../../Actions/mainTabsActions';
import RightDrawer from '../RightDrawer';
import RightDrawerMessage from '../RightDrawerMessage';
import RightDrawerGroupMessages from '../RightDrawerGroupMessages';
import RightDrawerTemplates from '../RightDrawerTemplates';
import RightDrawerShort from '../RightDrawerShort';
import SnackBar from '../Snackbar';
import { selectMessage } from '../../Actions/messagesActions';
import Debouncer from '../../Helpers/debouncer';
import './index.sass';
import Modal from '../Modal';
import CustomModal from '../CustomModal';
import { handleMiddleMenuLeft } from '../../Actions/groupsActions';
import Loading from '../Loading';
import RightDrawerManageTemplate from '../RightDrawerManageTemplate';
import ButtonCompose from '../ButtonCompose';

const TabContainer = props => (
  <Typography className={props.customTypography} component="div">
    {props.children}
  </Typography>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  wrapper: {},
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  tab: {
    color: theme.palette.background.paper,
  },
  labelIcon: {
    '&>span>i': {
      marginRight: '10px',
      marginBottom: '0 !important',
    },
  },
  tabsIndicator: {
    background: 'linear-gradient(to right, #9966bf 0%, #6174c9 98%)',
    height: 4,
  },
  tabsRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    paddingLeft: '40px',

    '&:focus': {
      color: '#9a66bf',
    },
  },
  textColorInherit: {
    color: '#6e6e6e',
    opacity: 1,
  },
  tabRoot: {
    paddingRight: '50px',
  },
  tabSelected: { color: '#9a66bf' },
  tabHidden: { display: 'none' },
  flexContainer: {
    display: 'flex',
    '@media only screen and (min-width: 768px) and (max-width: 1023px)': {
      justifyContent: 'space-around',
    },
  },
  badge: {
    backgroundColor: '#EA5858',
    color: 'white',
    right: -20,
  },
  customTypography: { height: 'calc(100vh - 125px)' },
  '@media (max-width: 768px)': {
    customTypography: { height: 'calc(100vh - 80px)' },
  },
});

const routePerValue = ['/groups', '/messages', '/templates'];
class SimpleTabs extends React.Component {
  UNSAFE_componentWillMount() {
    this.debouncerInstance = Debouncer(500, () => { }, { autoShutdown: false });
    if (
      localStorage.getItem('previousPath') !== undefined &&
      localStorage.getItem('previousPath') === 'designer'
    ) {
      localStorage.removeItem('previousPath');
    }
  }

  componentWillUnmount() {
    this.debouncerInstance.clearDebounce();
  }

  handleChange = (event, value) => {
    this.props.enableRightDrawer({ payload: { enable: false } });
    this.props.enableRightDrawerShort({ payload: { enable: false } });
    this.props.enableRightDrawerMessage({ payload: { enable: false } });
    this.props.enableRightDrawerTemplates({ payload: { enable: false } });
    this.props.enableRightDrawerGroupMessage({ payload: { enable: false } });
    this.props.changeTab({ payload: { value } });
    // this.props.selectMessage();
    this.props.handleMiddleMenuLeft({
      status: '',
      dinamicGrid: 7,
      dinamicGridMiddleMenu: 12,
    });

    this.debouncerInstance.debounce(routePerValue[value || 0] || '/', this.props.history.push);
  };

  render() {
    const { classes, content, value, replyNotificationsCounter } = this.props;
    const updValue = value !== null ? value : 3; // Set hidden tab as active if no selected tab (to avoid errors)
    return (
      <div className={classes.root}>
        <div className="page-app-bar">
          <AppBar
            position="static"
            style={{
              backgroundColor: '#F3F3F3',
            }}
            className={classes.appBar}
          >
            <div className="content-inner-wrapper">
              <Tabs
                className="main-menu-tabs"
                classes={{
                  root: classes.tabsRoot,
                  indicator: classes.tabsIndicator,
                  flexContainer: classes.flexContainer,
                }}
                value={updValue}
                onChange={this.handleChange}
              >
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                    labelIcon: classes.labelIcon,
                    textColorInherit: classes.textColorInherit,
                  }}
                  label="Groups"
                  icon={<i className="groups-icons" />}
                />
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                    labelIcon: classes.labelIcon,
                    textColorInherit: classes.textColorInherit,
                  }}
                  label={
                    replyNotificationsCounter ? (
                      <Badge
                        classes={{ badge: classes.badge }}
                        badgeContent={replyNotificationsCounter}
                      >
                        Messages
                      </Badge>
                    ) : (
                      'Messages'
                    )
                  }
                  icon={<i className="messages-icons" />}
                />
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                    labelIcon: classes.labelIcon,
                    textColorInherit: classes.textColorInherit,
                  }}
                  label="Templates"
                  icon={<i className="templates-icons" />}
                />
                <Tab
                  classes={{
                    root: classes.tabHidden,
                    textColorInherit: classes.textColorInherit,
                  }}
                  label="Hidden"
                />
              </Tabs>
              <ButtonCompose />
            </div>
          </AppBar>
        </div>
        <div className="page-container">
          <TabContainer customTypography={classes.customTypography}>
            <SnackBar />
            <RightDrawerShort />
            <RightDrawerMessage />
            <RightDrawerGroupMessages />
            <RightDrawerTemplates />
            <RightDrawer />
            <RightDrawerManageTemplate />
            <Modal />
            <Loading />
            {content}
          </TabContainer>
        </div>
      </div>
    );
  }
}

SimpleTabs.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  enableRightDrawer: PropTypes.func.isRequired,
  enableRightDrawerShort: PropTypes.func.isRequired,
  enableRightDrawerMessage: PropTypes.func.isRequired,
  enableRightDrawerTemplates: PropTypes.func.isRequired,
  enableRightDrawerGroupMessage: PropTypes.func.isRequired,
  snackBarStatus: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired,
  value: PropTypes.number,
  changeTab: PropTypes.func.isRequired,
};

const mS = (state, ownProps) => ({
  value: state.mainTabsReducer !== null ? state.mainTabsReducer.value : ownProps.value,
  replyNotificationsCounter: state.messagesReducer.replyNotificationsCounter,
});

const mD = {
  enableRightDrawer,
  enableRightDrawerShort,
  enableRightDrawerMessage,
  enableRightDrawerTemplates,
  enableRightDrawerGroupMessage,
  snackBarStatus,
  changeTab,
  handleMiddleMenuLeft,
  selectMessage,
};
export default withRouter(withStyles(styles)(
  connect(
    mS,
    mD,
  )(SimpleTabs),
));
