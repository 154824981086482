import snackBarStatus from './snackbarActions';
import Location from '../Api/Location';

export const ACTIONS = {
  GET_LIST: '[Countries] Get',
  LOADING: '[Countries] Loading',
};

const setLoading = bool => dispatch => {
  dispatch({
    type: ACTIONS.LOADING,
    payload: bool,
  });
};

export const getCountries = () => async (dispatch, getState) => {
  const { countriesDictionaryReducer: { isLoading, isLoaded } } = getState();

  if (isLoading || isLoaded) return;

  setLoading(true)(dispatch);

  return Location.getCountries()
    .then((response) => {

      if (response.status === 200) {
        dispatch({
          type: ACTIONS.GET_LIST,
          payload: response.data.data
            .map(o => ({ text: o.name, value: o.public_id })),
        });
      }
      return response;
    })
    .catch((error) => {
      snackBarStatus({
        payload: {
          title: error.message,
          type: 'error',
          enabled: true,
        },
      })(dispatch);
      return error;
    })
    .finally(() => {
      setLoading(false)(dispatch);
    });
};
