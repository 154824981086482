import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import ButtonSimple from '../ButtonSimple';
import evangelusLogo from '../../Theme/Icons/evangelusLogo.svg';
import LanguageSwitcher from '../LanguageSwitcher';
import './index.sass';

const InviteScreen = ({
  descriptionTextHtml, loginButtonText, registerButtonText, isActiveUser, locale,
  type, userName, inviteTextHtml, pictureUrl, title, subtitle, isInvitedUserLogged,
  isLanguageSwitchVisible, onLogin, onRegister, onLogout, onJoin,
}) => {
  const intl = useIntl();

  const renderUserName = () => (
    <div className="username">
      {type} {intl.formatMessage({ id: 'text.as' })} <b>{userName}</b>
    </div>
  );

  const renderLoggedUserButtons = () => (
    <section className="logged-as-pad">
      <ButtonSimple className="join-button" text={intl.formatMessage({ id: 'action.join' })} onClick={onJoin} />
      <div className="username">{type} {intl.formatMessage({ id: 'text.as' })} <b>{userName}</b></div>
      <button onClick={onLogout}>{intl.formatMessage({ id: 'action.logout' })}</button>
    </section>
  );

  const renderNotLoggedUserButtons = () => (
    <div>
      {descriptionTextHtml && (
        <article className="description-text" dangerouslySetInnerHTML={{ __html: descriptionTextHtml }} />)}

      {userName && isActiveUser && (
        <React.Fragment>
          <ButtonSimple className="common-action-button" text={loginButtonText} onClick={onLogin} />
          {renderUserName()}
        </React.Fragment>
      )}

      {userName && !isActiveUser && (
        <React.Fragment>
          <ButtonSimple className="common-action-button" text={registerButtonText} onClick={onRegister} />
          {renderUserName()}
        </React.Fragment>
      )}

      {!userName && (
        <React.Fragment>
          <ButtonSimple className="common-action-button" text={registerButtonText} onClick={onRegister} />
          <div className="login-note">{intl.formatMessage({ id: 'text.question.alreadyHaveAccount' })}</div>
          <button onClick={onLogin}>{loginButtonText}</button>
        </React.Fragment>
      )}
    </div>
  );

  return (
    <section className="invite-screen">
      <div className="content-inner-wrapper">
        <figure className="logo-picture">
          <img src={evangelusLogo} alt="Evangelus Logo" />
        </figure>

        {(isLanguageSwitchVisible) && <LanguageSwitcher />}

        <article className="invite-text" dangerouslySetInnerHTML={{ __html: inviteTextHtml }} />

        <figure className="invite-picture">
          <img src={pictureUrl} alt={title} />
          <figcaption>
            <div className="title">{title}</div>
            {subtitle && <div className="subtitle">{subtitle}</div>}
          </figcaption>
        </figure>

        {isInvitedUserLogged && renderLoggedUserButtons()}

        {!isInvitedUserLogged && renderNotLoggedUserButtons()}
      </div>
    </section>
  );
};

InviteScreen.propTypes = {
  inviteTextHtml: PropTypes.string.isRequired,
  descriptionTextHtml: PropTypes.string.isRequired,
  registerButtonText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  isActiveUser: PropTypes.bool,
  isLanguageSwitchVisible: PropTypes.bool,
  isInvitedUserLogged: PropTypes.bool,
  loginButtonText: PropTypes.string.isRequired,
  pictureUrl: PropTypes.string.isRequired,
  userName: PropTypes.string,
  onRegister: PropTypes.func.isRequired,
  onJoin: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

InviteScreen.defaultProps = {
  subtitle: null,
  type: '',
  descriptionTextHtml: '',
  userName: null,
  isActiveUser: false,
  isInvitedUserLogged: false,
};

export default InviteScreen;
