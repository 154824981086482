import AXIOS, { CancelToken } from '../Config/AxiosConfig';
import headers from './Helper';
import API_URL from '../Config/env';
import { REQUEST_CANCELLED_MESSAGE } from '../Config/Constants';
import Axios from 'axios';

const BASE_URL = '/eva/organization';
const URL_BASE = '/eva';

const Message = {
  cancelGetMessageToken: null,

  createDraftMessage(data) {
    return AXIOS.post(`${URL_BASE}/draft-message`, data, {
      headers: headers(),
    });
  },
  updateDraftMessage(data) {
    return AXIOS.put(`${URL_BASE}/draft-message/${data.publicId}`, data, {
      headers: headers(),
    });
  },
  deleteDraftMessage(data) {
    const { publicId } = data;
    return AXIOS.delete(`${URL_BASE}/draft-message/${publicId}`, {
      headers: headers(),
    });
  },
  getImageAsync(base64) {
    return AXIOS.post(
      `${URL_BASE}/message/draft/crop-img`,
      { img: `${base64}` },
      {
        headers: headers(),
      },
    );
  },
  sendDirectMessage(groupPublicId, data) {
    return AXIOS.post(`${URL_BASE}/groups/${groupPublicId}/send-message`, data, {
      headers: headers(),
    });
  },
  sendMessage(data, resendPublicId) {
    return AXIOS.post(`${URL_BASE}/groups/send-message${resendPublicId ? `?messagePublicId=${resendPublicId}` : ''}`,
      data, {
        headers: headers(),
      }
    );
  },

  getMessagePreview(messageID, groupID) {
    return AXIOS.get(`/public${URL_BASE}/message/preview/${messageID}?group=${groupID}`, {
      headers: headers(),
    });
  },
  getTimeZone() {
    return AXIOS.get('/timezone', {
      headers: headers(),
    });
  },

  /**
   * Asks the API if the current user is capable of canceling/editing
   * the scheduled message.
   * @param {string} messagePublicId Public ID of the current Organization
   * @param {string} orgId orgId ID of the organization to be canceled/edited
   */

  canCancelScheduledMessage(messagePublicId, orgId) {
    return AXIOS.get(`${BASE_URL}/${orgId}/message/${messagePublicId}/validate/cancel-edit`, {
      headers: headers(),
    });
  },

  /**
   * Requests the API to cancel the scheduled message and sets its status to
   * DRAFT!
   * @param {string} messagePublicId Public ID of the current message
   * @param {string} orgId Public id of the message to be canceled/edited
   */
  cancelScheduledMessage(messagePublicId, orgId) {
    return AXIOS.put(`${BASE_URL}/${orgId}/message/${messagePublicId}/schedule-cancel`, {}, {
      headers: headers(),
    });
  },
  getMessage(publicId) {
    if (this.cancelGetMessageToken) {
      this.cancelGetMessageToken.cancel(REQUEST_CANCELLED_MESSAGE);
    }
    this.cancelGetMessageToken = CancelToken.source();

    const promiseVal = AXIOS.get(`v2/eva/message/${publicId}`, {
      headers: headers(),
      cancelToken: this.cancelGetMessageToken.token,
    });

    promiseVal.catch(err => {
      if (Axios.isCancel(err)) {
        return false;
      }
      throw err;
    });
    return promiseVal;
  },
  getMessagesStatistics(publicId, type) {
    return AXIOS.get(`${API_URL}/eva/messages/${publicId}/metrics/${type}/export/json`, {
      headers: headers(),
    });
  },
  releaseMessageLockedMember(publicId) {
    return AXIOS.put(`${URL_BASE}/draft-message/${publicId}/release-locked-member`, {}, {
      headers: headers(),
    });
  },
};

export default Message;
