import PhoneSimple from './phone';

/* eslint-disable */
export const EmailSimple = text => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(text);
};

export const EmailSimpleSemiColon = text => {
  const re = /^(|([a-zA-Z0-9_\-[\].]+)@([a-zA-Z0-9_\-[\].]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-[\].]+)@([a-zA-Z0-9_\-[\].]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
  return re.test(text);
};

export const ValidEmailPhone = text => {
  if (PhoneSimple(text)) {
    return 'sms';
  } else if (EmailSimple(text)) {
    return 'email';
  }
  return null;
};
