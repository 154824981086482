/* eslint-disable camelcase */
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import GenericMessage from '../GenericMessage';
import EvangelusLogo from '../../Theme/Icons/evangelus-grey.svg';
import { getDateInTimezone } from '../../Helpers/date';
import Message from '../../Api/Message';
import LoadingScreen from '../LoadingScreen';
import AttachmentsPreview from '../AttachmentsPreview';
import './index.sass';

class MessagePublicPreview extends Component {
  state = {
    isNoLongerExist: false,
    message: {},
    groupName: '',
    isLoading: true,
  };

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    const { groupName, group } = params;

    Message.getMessagePreview(this.props.match.params.id, group)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            isNoLongerExist: false,
            message: res.data.data,
            groupName,
            isLoading: false,
          });
        } else {
          this.setState({ isNoLongerExist: true, isLoading: false });
        }
      })
      .catch(e => {
        this.setState({ isNoLongerExist: true, isLoading: false });
      });
  }

  render() {
    const { 
      message: {
        files, organization_name, organization_time_zone, web_content, updated_at, subject,
      },
      groupName,
      isLoading,
    } = this.state;
    const date = updated_at
      ? getDateInTimezone(updated_at, organization_time_zone, 'ddd, MMM DD, h:mm A')
      : '';
    const { isNoLongerExist } = this.state;

    if (isLoading) {
      return <LoadingScreen loadingText="Loading message..." />;
    }

    return (
      <div>
        {isNoLongerExist ? (
          <div style={{ 'margin-top': '20px' }}>
            <GenericMessage
              type="info"
              title="Oops!"
              message=" Seems like the message that you're looking for is no longer available"
            />
          </div>
        ) : (
          <div className="message-display-wrapper">
            <section id="display-sms-message">

              <header>
                <div className="msg-group-org">
                  <div className="group-name">{subject}</div>
                  <div className="organization-name">{organization_name} - {groupName}</div>
                </div>
                <div className="msg-date">
                  {date}
                </div>
              </header>

              <article className="designerContent ">{ReactHtmlParser(web_content)}</article>

              <AttachmentsPreview files={files} />
            </section>

            <footer className="footer-container">
              <figure><img src={EvangelusLogo} alt="Evangelus Logo" /></figure>
              <Link to="/login">Update your communication preferences</Link>
            </footer>
          </div>
        )}
      </div>
    );
  }
}

export default MessagePublicPreview;
