import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { revokeTemporaryAdminPrivileges } from '../../Actions/memberActions';
import { getOrganizationMember } from '../../Actions/organizationActions';
import { MOBILE } from '../../Config/Constants';
import styles from './styles';
import LoaderOverlay from '../LoaderOverlay';
import { redirectToAdmin } from '../../Helpers/redirectToAdmin';

class TempAdminBanner extends Component {
  state = { loading: false };

  componentDidMount() {
    const { organization, member } = this.props;
    if (!organization || !member) {
      this.props.getOrganizationMember(organization.public_id, member.public_id);
    }
  }

  componentDidUpdate(prevProps) {
    const { organization, member } = this.props;
    const mustUpdate = (organization && organization.public_id && member && member.public_id) &&
      (prevProps.organization.public_id !== organization.public_id ||
        prevProps.member.public_id !== member.public_id);

    if (mustUpdate) {
      this.props.getOrganizationMember(organization.public_id, member.public_id);
    }
  }

  handleReturnToAdmin = async event => {
    const { member, organization } = this.props;
    event.preventDefault();
    this.setState({ loading: true });

    await this.props.revokeTemporaryAdminPrivileges(member, organization)
      .then(() => {
        redirectToAdmin(organization.public_id);
      });

    this.setState({ loading: false });
  };

  render() {
    const { classes, viewType, isTemporaryAdmin, organization } = this.props;
    const { loading } = this.state;
    return isTemporaryAdmin ? (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          {viewType === MOBILE ? (
            <React.Fragment>
              Temporarily you are
              <span className={classes.bold}> Admin of {organization.name}. </span>
              <a className={classes.anchor} href="#!" onClick={this.handleReturnToAdmin}>Return here.</a>
            </React.Fragment>
          ) : (
            <React.Fragment>
              You are currently a Temporary
              <span className={classes.bold}> Admin of {organization.name}. </span>
              <a className={classes.anchor} href="#!" onClick={this.handleReturnToAdmin}>Click here</a>
              <span> to return to the</span>
              <span className={classes.bold}> Super Admin portal.</span>
            </React.Fragment>
          )}
          {loading && <LoaderOverlay/>}
        </div>
      </div>
    ) : '';
  }
}

const mD = {
  revokeTemporaryAdminPrivileges,
  getOrganizationMember,
};

const mS = store => ({
  isTemporaryAdmin: store.organizationReducer.temporaryAdmin,
  member: store.userProfileReducer.member,
  organization: store.organizationReducer.organization,
  viewType: store.responsiveReducer.viewType,
});

export default connect(mS, mD)(
  withStyles(styles)(TempAdminBanner),
);
