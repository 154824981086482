import React, { PureComponent } from 'react';
import { ButtonStandard } from '../Button';
import AnimatedNotification from '../../Components/AnimatedNotification';
import PropTypes from 'prop-types';

class MessagesNotifications extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isShowButton: false,
    };
  }

  handleAnimationComplete() {
    this.setState({ isShowButton: true });
  }

  render() {
    const { isMessagesSevenDays, counter, onNewMessage } = this.props;
    const { isShowButton } = this.state;
    const arrayMessage = [];
    let messageButton = 'Send a new message';

    if (isMessagesSevenDays !== null && counter !== null) {
      if (counter === 1) {
        arrayMessage.push(
          `It looks like there is ${counter} unread message with a reply. See your messages to the left.`,
        );
      } else if (counter > 1) {
        arrayMessage.push(
          `It looks like there are ${counter} unread messages with replies. See your recent messages to the left.`,
        );
      }
      if (isMessagesSevenDays)
        arrayMessage.push(
          'Great job sending messages this week. Make sure to follow up with everyone.',
        );
      else {
        messageButton = 'Let\'s send a new message';
        arrayMessage.push(
          'It looks like it\'s been a while since you\'ve connected with your members. I\'m sure they would love to hear from you more often.',
        );
      }
    }

    return (
      <div className="content-inner-wrapper">
        {arrayMessage.length > 0 && (
          <div className="notification-messages-container">
            <AnimatedNotification texts={arrayMessage} onAnimationComplete={this.handleAnimationComplete.bind(this)}/>
          </div>
        )}
        {isShowButton && (
          <ButtonStandard
            className="send-new-group-message"
            title={messageButton}
            onClick={onNewMessage}
          />
        )}
      </div>
    );
  }
}

MessagesNotifications.propTypes = {
  isMessagesSevenDays: PropTypes.bool,
  counter: PropTypes.number,
};

MessagesNotifications.defaultProps = {
  isMessagesSevenDays: false,
  counter: 0,
};

export default MessagesNotifications;
