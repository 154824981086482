import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
// import { connect } from 'react-redux';
import './index.sass';
import MembersAlphabeticList from '../MembersAlphabeticList';
import MemberActionsBar from '../MembersActionsBar';
import SearchInput from '../SearchInput';
import LoaderOverlay from '../LoaderOverlay';
import CustomMessage from '../CustomMessage';

/* eslint-disable no-underscore-dangle */
class MembersPicker extends PureComponent {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  eraseSearchInputValue = () => {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.value = '';
    }
  };

  renderNotFound = () => {
    return <CustomMessage
      isButton={false}
      type='warning'
      title=''
      message='No members found.'
    />;
  }

  render() {
    const {
      members, groupType, isFiltered, isFiltersOpen, isMemberAddMode, isLoading, isLoadingMore,
      selectedMembersHash, isAddButton, isRemoveButton, isAllSelectButtons, onEditMemberClick,
      onSelectMember, onAddMemberCallback, onAddCaminoMembersToAll, onRemoveSelected, onSearchInputChange,
      onSelectAllMembers, onUnselectAllMembers, onScrollFinished, searchTerm,
    } = this.props;

    const isNotFound = !isLoading && isFiltered && members.length === 0;

    return (
      <div className="members-picker">
        <div className="top-panel">
          <SearchInput value={searchTerm} ref={this.inputRef} onChange={onSearchInputChange} />
          <MemberActionsBar
            groupType={groupType}
            isSelected={Object.keys(selectedMembersHash).length > 0}
            isAddButton={isAddButton}
            isRemoveButton={isRemoveButton}
            isAllSelectButtons={isAllSelectButtons}
            onAddMemberCallback={onAddMemberCallback}
            onAddCaminoMembersToAll={onAddCaminoMembersToAll}
            onRemoveSelected={onRemoveSelected}
            onSelectAllMembers={onSelectAllMembers}
            onUnselectAllMembers={onUnselectAllMembers}
          />
        </div>

        {isNotFound && this.renderNotFound()}
        
        <div className="alphabetic-list-wrapper">
          <MembersAlphabeticList
            members={members}
            isLoading={isLoading}
            isFiltersOpen={isFiltersOpen}
            isMemberAddMode={isMemberAddMode}
            selectedMembersHash={selectedMembersHash}
            onEditMemberClick={onEditMemberClick}
            onSelectMember={onSelectMember}
            onScrollFinished={onScrollFinished}
          />
          {isLoadingMore && <div className="load-more-overlay"><LoaderOverlay/></div>}
        </div>
      </div>
    );
  }
}

MembersPicker.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchTerm: PropTypes.string,
  selectedMembersHash: PropTypes.objectOf(PropTypes.bool).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  isAddButton: PropTypes.bool,
  isRemoveButton: PropTypes.bool,
  isAllSelectButtons: PropTypes.bool,
  onEditMemberClick: PropTypes.func,
  onSelectMember: PropTypes.func.isRequired,
  onAddMemberCallback: PropTypes.func,
  onRemoveSelected: PropTypes.func,
  onSearchInputChange: PropTypes.func.isRequired,
  onSelectAllMembers: PropTypes.func,
  onUnselectAllMembers: PropTypes.func,
  onScrollFinished: PropTypes.func,
};

MembersPicker.defaultProps = {
  isAddButton: false,
  isRemoveButton: false,
  isAllSelectButtons: false,
  onEditMemberClick: null,
  onAddMemberCallback: null,
  onRemoveSelected: null,
  onSelectAllMembers: null,
  onUnselectAllMembers: null,
  onScrollFinished: () => {},
  searchTerm: '',
};

export default MembersPicker;
