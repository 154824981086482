export const switchStyles = {
  root: {
    height: '32px',
  },
  track: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 20,
    width: '52px',
    height: '32px',
    backgroundColor: '#a9a9a9',
    opacity: 1,
  },

  switchBase: {
    padding: '3px',
  },
  thumb: {
    backgroundColor: 'white',
    width: '26px',
    height: '26px',
  },
  switchColorSecondary: {
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#9a66bf',
      opacity: 1,
    },
  },
};
