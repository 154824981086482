import React, { Component } from 'react';
import LoaderOverlay from '../LoaderOverlay';
import PropTypes from 'prop-types';
import getMemberInfo from '../../Helpers/getMemberInfo';
import RemoveIcon from '../../Theme/Icons/remove.svg';
import EvaAvatar from '../Avatar';
import addCircleIcon from '../../Theme/Icons/plus-circle-inactive.svg';
import './index.sass';

class GroupAdminsList extends Component {

  handleAdd = (e) => {
    e.preventDefault();
    this.props.onAdd();
  }

  handleRemove = (e, publicId) => {
    e.preventDefault();
    this.props.onRemove(publicId);
  }

  renderList = () => {
    const { list, isGroupAdmin, userPublicId, showRemoveButton } = this.props;
    return (
      <div className={`list-wrapper ${showRemoveButton ? 'show-remove-buttons' : ''}`}>
        {list.map((member) => (
          <div className="list-item" key={member.publicId}>
            {showRemoveButton && !(isGroupAdmin && userPublicId === member.publicId) && (
              <button className="btn-member-remove"
                      onClick={(e) => this.handleRemove(e, member.publicId)}
                      onKeyPress={(e) => this.handleRemove(e, member.publicId)}
              >
                <img src={RemoveIcon} alt="Image"/>
              </button>
            )}
            <EvaAvatar
              borderless
              className="avatar-image"
              image={member.profilePictureUrl}
            />
            <div className="name">{getMemberInfo(member).displayedFullName}</div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { showAddButton, loading } = this.props;
    return loading ? <LoaderOverlay/> : (
      <section className="group-admins-list">
        <div className="section-title">Group Admin(s)</div>

        {showAddButton && (
          <div className="panel">
            <button
              className="add-admin-btn"
              onClick={this.handleAdd}
              onKeyPress={this.handleAdd}
            >
              <img
                src={addCircleIcon}
                alt="Add Group Admin"
              />
              <span>Add group admin</span>
            </button>
          </div>
        )}

        {this.renderList()}
      </section>
    );
  }
}

export default GroupAdminsList;

GroupAdminsList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired, // TODO: provide member type
  showAddButton: PropTypes.bool,
  showRemoveButton: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  isGroupAdmin: PropTypes.bool,
  userPublicId: PropTypes.string,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
};

GroupAdminsList.defaultProps = {
  showAddButton: false,
  showRemoveButton: false,
  isGroupAdmin: false,
  userPublicId: null,
  onAdd: () => {},
  onRemove: () => {},
};
