import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Scrollbars } from 'react-custom-scrollbars';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import TemplateOptionsPropType from '../../PropTypes/TemplateOptionsPropType';
import SearchInput from '../SearchInput';
import EvaTooltip from '../EvaTooltip';
import Checkbox from '../Checkbox';
import {
  changeState,
  getFolderById,
  getFolderByIdPaged,
  getTemplateById,
  selectFolder,
  selectTemplate,
  updateFolderTemplate,
  updateSelectedTemplate,
} from '../../Actions/templateActions';
import { applySelectedTemplate, changeTab, clearMessage } from '../../Actions/messageActions';
import { closeCustomModal, openCustomModal } from '../../Actions/customModalActions';
import { enableRightDrawerMessage } from '../../Actions/rightDrawerMessageActions';
import ButtonStandard from '../../Components/Button';
import colors from '../../Theme/Colors';
import gridIcon from '../../Theme/Icons/grid-inactive.svg';
import gridIconActive from '../../Theme/Icons/profile-view-active.svg';
import listIcon from '../../Theme/Icons/list.svg';
import listIconActive from '../../Theme/Icons/list-active.svg';
import {
  MIN_DESKTOP_WIDTH,
  SEARCH_IN_GALLERY_DELAY, SEARCH_TEMPLATES_DELAY,
  SORT_RECENT,
  TEMPLATE_PAGE_SIZE_GRID,
} from '../../Config/Constants';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import ButtonSelect from '../ButtonSelect';
import LoaderOverlay from '../LoaderOverlay';
import './index.sass';
import { debounce } from '../../Helpers/debounce';

const styles = () => ({
  cssRoot: {
    color: 'blue',
    backgroundColor: 'white',
    border: '2px solid',
    borderColor: '#9a66bf',
    borderRadius: '20px',
  },
  sortContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '18px',
  },
  sortDropdown: {
    width: '130px',
    zIndex: '1000',
  },
  renterTypeIcon: {
    width: '24px',
    height: '20px',
    marginRight: '10px',
    padding: '0',
    flexShrink: '0',
    '&>img': {
      height: '100%',
    },
  },
  templatesOptionsSearch: {
    width: '100%',
  },
  templateOptionsContainer: {
    width: '100%',
  },
  templateGalleryShow: {
    marginTop: '15px',
  },
  templateListItem: {
    display: 'flex',
    width: '100%',
    paddingTop: '18px',
    paddingBottom: '16px',
    height: '70px',
    borderBottom: `2px solid ${colors.whiteTwo}`,
    '&>.checkbox-container': {
      width: '40px',
      flexShrink: '0',
    },
    '&>div>.list-item-text': {
      fontFamily: 'GothamSSm-Medium',
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '&>div>.list-item-text.title': {
      color: colors.evaGrey,
      fontSize: '20px',
      height: '24px',
      lineHeight: '24px',
    },
    '&>div>.list-item-text.description': {
      color: colors.grey,
      fontSize: '16px',
      height: '19px',
      lineHeight: '19px',
      marginTop: '2px',
    },
    '&>div>.list-item-text.description2': {
      color: colors.grey,
      fontSize: '16px',
      height: '19px',
      lineHeight: '19px',
      marginTop: '5px',
    },
  },
  '@media (min-width: 1024px)': {
    templateOptionsContainer: {
      width: 'calc(100% - 15px)',
    },
    sortDropdown: {
      width: '95px',
    },
  },
});

// TODO: refactoring.
/* eslint-disable */
class TemplateOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedTemplates: [],
      itemSelected: null,
      searchTerm: '',
      typeRender: 'grid',
      valueBolita: 1,
      templateSelect: null,
      templateKey: null,
    };

    this.callSearchWithDebounce = debounce(this.handleSearch, SEARCH_TEMPLATES_DELAY);
  }

  componentDidUpdate(previousProps, proviousState) {
    const { templateFolderSelected, templateSelected } = this.props;
    if (previousProps.templateFolderSelected !== this.props.templateFolderSelected) {
      templateFolderSelected.forEach(templateCurrent => {
        const templateExist = templateSelected.find(tem => tem === templateCurrent.publicId);
        if (templateExist) {
          templateCurrent.check = true;
        }
      });
      this.props.updateFolderTemplate(templateFolderSelected);
      const templateToInsert = templateFolderSelected.find(
        tem => tem.publicId === templateSelected[0],
      );
      if (templateToInsert) {
        this.handleSelect({ key: templateToInsert.publicId, templateUrl: templateToInsert.source });
      }
    }

    if (previousProps.folderId !== this.props.folderId) {
      this.setState({ searchTerm: '' })
    }
  }

  componentWillUnmount() {
    this.setState({
      templateSelect: null
    })
  }

  handleSearch = () =>
    this.props.getFolderByIdPaged(this.props.folder, '', this.state.searchTerm, 1, TEMPLATE_PAGE_SIZE_GRID);

  changeTypeView = async type => {
    try {
      this.props.changeTypeRender(type);
    } catch (err) {
      console.error(err);
    }
  };

  getTemplate = key => {
    const { templateFolderSelected } = this.props;
    return templateFolderSelected[key];
  };

  handleSortChange = type => {
    const { folder } = this.props;
    const { searchTerm } = this.state;
    this.setState({ itemSelected: type });
    this.props.getFolderByIdPaged(folder, type, searchTerm, 1, TEMPLATE_PAGE_SIZE_GRID);
  };

  getDate = date => moment(date).fromNow();

  renderTemplates = key => {
    const { typeRender } = this.props;
    const template = this.getTemplate(key);
    if (typeRender === 'grid') {
      return this.renderGrid(template, key);
    }

    return this.renderList(template, key);
  };

  handleSelect = template => {
    const { key, templateUrl } = template;
    this.setState({
      templateKey: key,
      templateSelect: templateUrl,
    });
  };

  handleTemplatePick = async (publicId) => {
    const response = await this.props.getTemplateById(publicId);
    this.props.selectTemplate(response.data);
    this.props.onTemplatePick();
  };

  handleCheck = (publicId, key, e) => {
    const isChecked = e.target.checked;
    let { checkedTemplates } = this.state;

    const newArr = isChecked ? checkedTemplates.concat([publicId]) : checkedTemplates.filter(id => id === publicId);

    this.setState({ checkedTemplates: newArr });
  };

  selectTemplate = async key => {
    const template = await this.props.getTemplateById(key);
    changeState({ templateFolderSelected: template });
    this.forceUpdate();
  };

  handleFromListPick = () => {
    // TODO: change to radio buttons.
    const lastCheckedTplId = this.state.checkedTemplates[this.state.checkedTemplates.length - 1];

    if (lastCheckedTplId) {
      this.handleTemplatePick(lastCheckedTplId);
    }
  };

  calcTemplateListHeight = () => {
    const { windowHeight, windowWidth } = this.props;
    const { typeRender } = this.props;
    let templateListHeight = 200; // the previously hardcoded value
    if (windowWidth < MIN_DESKTOP_WIDTH) {
      templateListHeight = typeRender === 'grid' ? windowHeight - 340 : windowHeight - 400;
    } else {
      templateListHeight = typeRender === 'grid' ? windowHeight - 360 : windowHeight - 420;
    }
    const marginBottom = 24;
    return templateListHeight - marginBottom;
  };

  renderGrid = (template, key) => {
    const { folder, templateAllList, templateFolderSelected } = this.props;
    const folderSelect = templateAllList.find(select => select.publicId === folder);
    let parentfolder = '';
    let background = '';
    if (folderSelect) {
      parentfolder = folderSelect.name;
    }
    if (templateFolderSelected.length === 2) {
      background = {
        backgroundImage: `url(${template.mainPicture})`,
        marginBottom: 30,
        marginRight: 30,
      };
    } else {
      background = {
        backgroundImage: `url(${template.mainPicture})`,
        marginBottom: 30,
      };
    }
    return (
      <Grid
        item
        key={key}
        xs={6}
        sm={4}
        md={6}
        style={background}
        className="template-option-detail"
        onKeyPress={() => this.handleTemplatePick(template.publicId)}
        onClick={() => this.handleTemplatePick(template.publicId)}
      >
        <Grid container className="template-option-info template-option-container">
          <Grid item xs={12} className="template-option-title">
            {template.title}
          </Grid>
          <Grid item xs={12} className="template-option-date">
            {this.getDate(template.createdAt)}
          </Grid>
          <Grid item xs={12} className="template-option-footer">
            {parentfolder}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderList = (template, key) => {
    const { folder, templateAllList, classes } = this.props;
    const folderSelect = templateAllList.find(select => select.publicId === folder);
    let parentfolder = '';
    if (folderSelect) {
      parentfolder = folderSelect.name;
    }
    return (
      <div className={classes.templateListItem}>
        <div className="checkbox-container">
          <Checkbox
            filled
            outlined
            checked={template.check}
            onChange={(e) => this.handleCheck(template.publicId, key, e)}
            disabled={this.state.checked === 1}
          />
        </div>
        <div>
          <div className="list-item-text title">{template.title}</div>
          <div className="list-item-text description">{this.getDate(template.createdAt)}</div>
          <div className="list-item-text description2">{parentfolder}</div>
        </div>
      </div>
    );
  };

  genPageIndexList = () => {
    const { totalPages, page } = this.props;
    if (!totalPages || !page) {
      return [];
    }
    let bullets = [];
    for (let i = 0; i < totalPages; i++) {
      bullets.push(
        <div
          key={i.toString()}
          className={`${page === i + 1 ? 'bolita-selected' : ''} bolita`}
          style={{ marginRight: i < totalPages - 1 ? '10px' : '0px' }}
        />,
      );
    }
    return bullets;
  };

  handlePageChange = async displacement => {
    const { nextPage, previousPage, folder } = this.props;
    const { itemSelected } = this.state;
    const pageSize = TEMPLATE_PAGE_SIZE_GRID;
    try {
      if (displacement === 'left' && previousPage || displacement === 'right' && nextPage) {
        const page = displacement === 'left' && previousPage ? previousPage : nextPage;
        this.props.getFolderByIdPaged(
          folder,
          itemSelected,
          this.state.searchTerm,
          page,
          pageSize,
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  renderTemplatesList = () => {
    const { classes, templateFolderSelected } = this.props;
    return (
      templateFolderSelected.length === 2 ? (
        <Grid container className={classes.templateOptionsContainer}>
          {Object.keys(templateFolderSelected).map(this.renderTemplates.bind(this))}
        </Grid>
      ) : (
        <Grid
          container
          justify="space-between"
          className={classes.templateOptionsContainer}
        >
          {Object.keys(templateFolderSelected).map(this.renderTemplates.bind(this))}
        </Grid>
      )
    )
  };

  render() {
    const { classes, typeRender, isTemplatesLoading } = this.props;
    const { checkedTemplates, searchTerm } = this.state;
    const statusButton = checkedTemplates.length === 1;

    return (
      <Grid container id="template-options-container">
        <Grid item sm={12} className={classes.templatesOptionsSearch}>
          <Grid className="template-container">
            <div className="search-input">
              <SearchInput
                value={searchTerm}
                onChange={term => {this.setState({ searchTerm: term }, this.callSearchWithDebounce) }}
              />
            </div>
            <div className={classes.sortContainer}>
              <div className={classes.sortDropdown}>
                <ButtonSelect className="button-sort" values={SORT_RECENT} defaultValue={this.state.itemSelected}
                              onChange={this.handleSortChange}/>
              </div>
              <EvaTooltip title="List View">
                <button
                  className={classes.renterTypeIcon}
                  onClick={() => this.changeTypeView('list')}
                >
                  <img src={typeRender === 'list' ? listIconActive : listIcon} alt=""/>
                </button>
              </EvaTooltip>
              <EvaTooltip title="Grid View">
                <button
                  className={classes.renterTypeIcon}
                  onClick={() => this.changeTypeView('grid')}
                >
                  <img src={typeRender === 'grid' ? gridIconActive : gridIcon} alt=""/>
                </button>
              </EvaTooltip>
            </div>
            <Grid className="templates-option-panel">
              <Grid container>
                <Scrollbars
                  autoHeight
                  autoHideTimeout={0}
                  autoHideDuration={0}
                  autoHeightMin={this.calcTemplateListHeight()}
                  autoHeightMax={this.calcTemplateListHeight()}
                >
                  {isTemplatesLoading ? <LoaderOverlay/> : this.renderTemplatesList()}
                </Scrollbars>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={`template-gallery-show ${classes.templateGalleryShow}`}
          id="template-gallery-show"
        >
          <div className="template-arrows">
            <IconButton onClick={() => this.handlePageChange('left')}>
              <KeyboardArrowLeft/>
            </IconButton>
            {this.genPageIndexList()}
            <IconButton onClick={() => this.handlePageChange('right')}>
              <KeyboardArrowRight/>
            </IconButton>
          </div>
          {this.props.typeRender === 'list' ? (
            <div id="button-send-message">
              <ButtonStandard
                onClick={() => this.handleFromListPick()}
                title="Insert template"
                className={`${statusButton ? '' : 'disable'}`}
                disabled={!statusButton}
              />
            </div>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    );
  }
}

const mD = {
  applySelectedTemplate,
  changeState,
  changeTab,
  clearMessage,
  enableRightDrawerMessage,
  getFolderById,
  getFolderByIdPaged,
  getTemplateById,
  navigationControllerRedirect,
  selectFolder,
  selectTemplate,
  closeCustomModal,
  updateFolderTemplate,
  openCustomModal,
  updateSelectedTemplate,
};

const mS = ({
              templateReducer: {
                templateFolderSelected,
                folder,
                templateSelected,
                templateAllList,
                page,
                nextPage,
                previousPage,
                totalPages,
                template,
              },
              messageReducer: { publicId: messagePublicId, messageContent: { type: messageContentType }, html },
              responsiveReducer: { windowHeight, windowWidth },
            }) => ({
  templateFolderSelected,
  folder,
  templateSelected,
  templateAllList,
  html,
  windowHeight,
  windowWidth,
  page,
  nextPage,
  previousPage,
  totalPages,
  template,
  messagePublicId,
  messageContentType,
});

TemplateOptions.propTypes = TemplateOptionsPropType;
export default connect(
  mS,
  mD,
)(withStyles(styles)(TemplateOptions));
