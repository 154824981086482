import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import ViewMember from '../ViewMember';
import EditMemberPropTypes from '../../PropTypes/EditMemberPropType';
import { enableRightDrawer } from '../../Actions/rightDrawerShortActions';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import ButtonStandard from '../Button';
import { InputIconGridless as Input } from '../InputIcon';
import snackBarStatus from '../../Actions/snackbarActions';
import { deleteAccount } from '../../Actions/userActions';
import EditPicture from '../EditPicture';
import {
  editMember,
  editProfileUser,
  getMemberFullInfo,
  getMemberGroups,
  updateDisplayChangePassword,
  updateGroupNotificationPreferences,
  updateMember,
  updateMemberGroups,
} from '../../Actions/memberActions';
import {
  getAllGroupAsync,
  getGroupsAsync,
  getOrgAsync,
  removeMemberFromGroup,
  updateGroupsReducer,
} from '../../Actions/groupsActions';
import PhotoEditMember from '../../Theme/Icons/editprofile.svg';
import { InputPhone } from '../InputPhone';
import style from './styles';
import { getDateToday } from '../../Helpers/date';
import GroupsChannelsList from '../GroupsChannelsList';
import MemberPictureEdit from '../MemberPictureEdit';
import BirthdayPicker from '../BirthdayPicker';
import './index.sass';
import CountryPicker from '../CountryPicker';
import StatePicker from '../StatePicker';

/* eslint-disable */
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/* eslint-enable */

class EditMember extends Component {
  state = {
    dialog: false,
    inputFields: {
      firstName: { valid: false, stateName: 'firstNameValidation' },
      lastName: { valid: false, stateName: 'lastNameValidation' },
      email: { valid: false, stateName: 'emailValidation' },
      phone: { valid: false, stateName: 'phoneValidation' },
      referenceAddress: { valid: false, stateName: 'streetValidation' },
      cityText: { valid: false, stateName: 'cityValidation' },
      state: { valid: false, stateName: 'stateValidation' },
      postalCode: { valid: false, stateName: 'postalCodeValidation' },
    },
    dateValue: this.props.birthday,
  };

  handleRemoveGroupMember = groupId =>
    this.props.removeMemberFromGroup({ groupId, memberId: this.props.selectedMember.publicId });

  handleSelectTypeNotification = (group, opts) => {
    const { selectedMember: { publicId, phoneNumber, email } } = this.props;
    const { inputFields } = this.state;
    let continueSubmit = true;

    if (opts.isNotifyBySms && !phoneNumber) {
      this.setState({
        inputFields: {
          ...inputFields,
          phone: {
            ...inputFields.phone,
            valid: true,
          },
        },
      });

      continueSubmit = false;

      this.props.snackBarStatus({
        payload: {
          title: 'To receive SMS notifications, you need to provide a phone number first.',
          enable: true,
          type: 'formError',
        },
      });
    }

    if (opts.isNotifyByEmail && !email) {
      this.setState({
        inputFields: {
          ...inputFields,
          email: {
            ...inputFields.email,
            valid: true,
          },
        },
      });

      continueSubmit = false;

      this.props.snackBarStatus({
        payload: {
          title: 'To receive notifications through email, you need to specify one first.',
          enable: true,
          type: 'formError',
        },
      });
    }
    if (!group.isEnabled) {
      self.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'Denied, this group has been disabled.',
          type: 'formError',
        },
      });
    } else {
      if (continueSubmit) {
        this.props.updateGroupNotificationPreferences(opts, group.publicId, publicId);
      }
    }
  };

  handleError = (err = '') => {
    this.props.snackBarStatus({
      payload: {
        enable: true,
        title: err,
        type: 'formError',
      },
    });
  };

  handleChange = (e, attr) => {
    const payload = {};
    payload[`${attr}`] = e.target.value;
    this.props.updateMember(payload);
  };

  handleCountryChange = (id) => this.props.updateMember({ countryPublicId: id });

  handleStateChange = (id) => this.props.updateMember({ statePublicId: id });

  handlePhone = phoneNumber => {
    this.props.updateMember({ phoneNumber });
  };

  checkProfileImage = selectedMember => {
    let Photo = PhotoEditMember;
    const { forceReload } = this.props;
    let forceReloadQuery = '';
    if (forceReload) {
      forceReloadQuery = `?val=${forceReload}`;
    }
    if (selectedMember && selectedMember.profile_picture_url) {
      Photo = `${selectedMember.profile_picture_url}${forceReloadQuery}`;
    } else if (selectedMember && selectedMember.profilePictureUrl) {
      Photo = `${selectedMember.profilePictureUrl}${forceReloadQuery}`;
    }

    return Photo;
  };
  handleChangeBirthday = date => {
    const dateFormat = date.valueText ? moment(date.valueText).format('YYYY-MM-DD') : date.valueText;
    if (dateFormat > getDateToday()) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'The birthday date must not be higher than the current date',
          type: 'formError',
        },
      });
    } else {
      this.setState({ dateValue: dateFormat });
      this.props.updateMember({ birthday: dateFormat });
    }
  };

  changePicture = e => {
    e.preventDefault();
    if (!window.location.pathname === '/invited-complete-profile') {
      this.props.enableRightDrawer({
        payload: {
          enable: true,
          element: <EditPicture userTypeToEditPicture="group-member-user"/>,
          title: 'Select a profile picture',
          userTypeToEditPicture: 'current-session-user',
        },
      });
    }
    if (this.props.changeDisplay) {
      this.props.changeDisplay({ displayPicture: true });
    }
  };

  refreshMemberGroups = async () => {
    if (this.props.changeDisplay) {
      this.props.changeDisplay({ displayEdit: false });
    }
    this.props.enableRightDrawer({
      payload: {
        enable: true,
        element: <ViewMember/>,
        title: '',
      },
    });
  };

  submitLogout = e => {
    e.preventDefault();
    this.props.navigationControllerRedirect('/logout');
  };
  handleValidations = data => {
    const { inputFields } = this.state;
    Object.keys(inputFields).forEach(key => {
      inputFields[key].valid = false;
    });
    const validationErrors = [];
    if (data.phoneNumber && typeof data.phoneNumber === 'string' && data.phoneNumber.length) {
      const parsedNumber = parsePhoneNumberFromString(data.phoneNumber);
      if (!parsedNumber || !parsedNumber.isValid()) {
        inputFields.phone.valid = true;
        validationErrors.push('Invalid phone number');
        // return 'Invalid phone number';
      }
    }
    if (data.email && !emailRegex.test(data.email)) {
      validationErrors.push('Invalid email');
      // return 'Invalid email';
    }
    if (!data.phoneNumber && !data.email) {
      inputFields.email.valid = true;
      inputFields.phone.valid = true;
      validationErrors.push('A user must have either a phone number or an email set.');
    }
    this.setState(inputFields);
    return validationErrors.length ? validationErrors : null;
  };

  submit = async e => {
    e.preventDefault();
    const {
      publicId,
      firstName,
      lastName,
      email,
      phoneNumber,
      referenceAddress,
      cityText,
      countryPublicId,
      statePublicId,
      postalCode,
      birthday,
    } = this.props.selectedMember;

    const { afterUpdate, selectedGroup } = this.props;

    const { pathname } = this.props.location;

    if (pathname === '/invited-complete-profile' || pathname === '/my-profile') {
      const data = {
        publicId,
        firstName,
        lastName,
        birthday,
        phoneNumber,
        email,
        referenceAddress,
        cityText,
        countryPublicId,
        statePublicId,
        postalCode,
        // groups: this.props.myGroups.length > 0 && this.props.myGroups[0],
      };
      const errorMessages = this.handleValidations(data);
      if (errorMessages) {
        this.handleError(errorMessages);
        return;
      }
      this.props.updateDisplayChangePassword(true);
      this.props.editProfileUser(data).then(response => {
        if (response) {
          this.props.snackBarStatus({
            payload: {
              enable: true,
              title: 'Member has been Updated',
              type: 'success',
            },
          });
          this.props.changeDisplay(false);
          this.props.updateMember({
            firstName,
            lastName,
            birthday,
            phoneNumber,
            email,
            referenceAddress,
            cityText,
            countryPublicId,
            statePublicId,
            postalCode,
          });
        }
      });
    } else {
      const payloadData = {
        publicId,
        firstName,
        lastName,
        birthday,
        email,
        groups: selectedGroup,
        phoneNumber,
        postalCode,
        cityText,
        referenceAddress,
        countryPublicId,
        statePublicId,
      };
      const errors = this.handleValidations(payloadData);
      if (errors) {
        this.handleError(errors);
        return;
      }
      this.props.updateDisplayChangePassword(true);
      const response = await this.props.editMember(payloadData);

      if (response.code === 200) {
        this.props.snackBarStatus({
          payload: {
            enable: true,
            title: response.message,
            type: 'success',
          },
        });
        if (typeof afterUpdate === 'function') {
          afterUpdate();
        }

        if (selectedGroup) {
          const data = await this.props.getMemberFullInfo(publicId);
          this.props.updateMember(data);
        }
      } else {
        this.props.getMemberFullInfo(publicId);

        /* eslint-enable */
        this.props.snackBarStatus({
          payload: {
            title: response.message,
            type: 'error',
            enable: true,
          },
        });
      }
      this.refreshMemberGroups();
    }
  };

  handleOpen = () => {
    this.setState({
      dialog: true,
    });
  };

  handleDeleteAcount = () => {
    this.props.deleteAccount(); /* .then(() => {
      window.location = '/delete-account';
    }); */
  };

  handleClose = () => {
    this.setState({
      dialog: false,
    });
  };

  render() {
    /* eslint-disable */
    // TODO: refactor.
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      referenceAddress,
      countryPublicId,
      statePublicId,
      postalCode,
      selectedMember,
      memberGroups,
      classes,
      selectedMember: { cityText }
    } = this.props;

    const { inputFields } = this.state;
    /* eslint-enable */
    const { pathname } = window.location;
    const profilePicSelected = Boolean(
      selectedMember.profile_picture_url || selectedMember.profilePictureUrl,
    );

    return (
      <section className="view-member-wrapper edit-mode" id="edit-member-form">
        <div>
          <section className="member-info">

            <MemberPictureEdit
              isNoImage={!profilePicSelected}
              imageUrl={this.checkProfileImage(selectedMember)}
              onChange={this.changePicture}
            />

            <div className="member-info-fields">
              <div className="input-edit-member">
                <Input
                  type="text"
                  name="firstName"
                  value={firstName || ''}
                  placeholder="First name"
                  icon="firstName"
                  onChange={this.handleChange}
                  tooltipTitle="Name"
                  useId="special"
                  className={inputFields.firstName.valid && 'validation-form'}
                />
              </div>
              <div className="input-edit-member">
                <Input
                  type="text"
                  name="lastName"
                  value={lastName || ''}
                  placeholder="Last name"
                  onChange={this.handleChange}
                  tooltipTitle="Last name"
                  icon="lastName"
                  useDivContainer
                  useId="special"
                  className={inputFields.lastName.valid && 'validation-form'}
                />
              </div>
              <div className="input-edit-member">
                <Input
                  className={`disabled-field ${inputFields.email.valid && 'validation-form'}`}
                  type="text"
                  name="email"
                  value={email || ''}
                  placeholder="Email"
                  icon="email"
                  onChange={this.handleChange}
                  tooltipTitle="Email"
                  useId="special"
                />
              </div>
              <div
                className={`input-edit-member ${classes.rootInputPhone} ${inputFields.phone.valid &&
                'validation-form'}`}
                style={{ borderBottom: '2px solid #e3e3e3' }}
              >
                <InputPhone
                  style={{ width: '100%', marginBottom: '5px' }}
                  name="phoneNumber"
                  placeholder="Phone number"
                  value={phoneNumber || ''}
                  onInputPhoneChange={this.handlePhone}
                  useV3
                />
              </div>
              <div
                className={`${classes.root}`}
                id="input-edit-member"
                style={{ textAlign: 'center' }}
              >
                <BirthdayPicker value={this.state.dateValue} onChange={this.handleChangeBirthday} />
              </div>
              <div className="input-edit-member">
                <Input
                  type="text"
                  name="referenceAddress"
                  placeholder="Street address"
                  value={referenceAddress || ''}
                  icon="street"
                  onChange={this.handleChange}
                  tooltipTitle="Street Address"
                  useId="special"
                  className={inputFields.referenceAddress.valid && 'validation-form'}
                />
              </div>
              <div className="input-edit-member">
                <Input
                  type="text"
                  name="cityText"
                  placeholder="City"
                  value={cityText || ''}
                  icon="city"
                  onChange={this.handleChange}
                  tooltipTitle="City"
                  useId="special"
                  className={inputFields.cityText.valid && 'validation-form'}
                />
              </div>

              <CountryPicker value={countryPublicId} onChange={this.handleCountryChange} />
              <StatePicker countryId={countryPublicId} value={statePublicId} onChange={this.handleStateChange} />

              <div className="input-edit-member">
                <Input
                  type="text"
                  name="postalCode"
                  placeholder="Postal code"
                  value={postalCode || ''}
                  icon="postal-code"
                  onChange={this.handleChange}
                  tooltipTitle="Postal Code"
                  useId="special"
                  className={inputFields.postalCode.valid && 'validation-form'}
                />
              </div>
            </div>
          </section>

          <section>
            {this.props.location.pathname !== '/invited-complete-profile' && (
              <div className="section-title">Groups</div>
            )}

            <GroupsChannelsList
              isEditMode
              groups={memberGroups}
              onChange={this.handleSelectTypeNotification}
              onRemove={this.handleRemoveGroupMember}
            />

          </section>

          <div>
            <div id="button-send-message">
              <ButtonStandard title="Save" onClick={this.submit}/>
            </div>
          </div>
          {pathname === '/my-profile' && (
            <div id="logout" className="logout">
              <ButtonStandard title="logout" onClick={this.submitLogout}/>
            </div>
          )}
          {(pathname === '/invited-complete-profile' || pathname === '/my-profile') && (
            <div id="deleteAccount" className="deleteAccount">
              <ButtonStandard title="Delete My Account " onClick={this.handleOpen}/>
            </div>
          )}
        </div>
        <React.Fragment>
          <Dialog
            open={this.state.dialog}
            onClose={this.handleClose}
            className="return-to-edit"
            maxWidth="sm"
            style={{
              backgroundColor: 'transparent',
              borderRadius: '10px',
            }}
          >
            <DialogContent className="container-warning-delete">
              <div className="warning-message">
                <div className="single-line">
                  <strong> WARNING:</strong> This action will remove you from all group and
                  organization communications and will delete your Evangelus account. This can not
                  be undone.
                  <strong> Are you sure you want to proceed?</strong>
                </div>
              </div>

              <button onClick={this.handleDeleteAcount} className="button-warning-decision ">
                <span className="text-warning">
                  Yes, unsubscribe me and delete my Evangelus account
                </span>
              </button>
              <button onClick={this.handleClose} className="button-warning-decision ">
                <span className="text-warning">No, I still want to receive communications</span>
              </button>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </section>
    );
  }
}

EditMember.propTypes = EditMemberPropTypes;

const mS = state => ({
  publicId: state.memberReducer.selectedMember.publicId,
  firstName: state.memberReducer.selectedMember.firstName,
  lastName: state.memberReducer.selectedMember.lastName,
  birthday: state.memberReducer.selectedMember.birthday,
  phoneNumber:
    typeof state.memberReducer.selectedMember.phoneNumber !== 'string' ? '' : state.memberReducer.selectedMember.phoneNumber,
  email: state.memberReducer.selectedMember.email,
  referenceAddress: state.memberReducer.selectedMember.referenceAddress,
  cityText: state.memberReducer.selectedMember.cityText,
  countryPublicId: state.memberReducer.selectedMember.countryPublicId,
  statePublicId: state.memberReducer.selectedMember.statePublicId,
  postalCode: state.memberReducer.selectedMember.postalCode,
  profilePictureUrl: state.memberReducer.selectedMember.profile_picture_url,
  memberGroups: state.memberReducer.selectedMember.groups,
  groups: state.groupsReducer.groups,
  myGroups: state.groupsReducer.globalGroups.myGroups,
  selectedGroup: state.groupsReducer.selectedGroup,
  selectedMember: state.memberReducer.selectedMember,
  currentMember: state.memberReducer,
  error: state.memberReducer.error,
  currentUser: state.userProfileReducer.member,
  publicIdUser: state.userProfileReducer.public_id,
  mainRole: state.userProfileReducer.main_role,
  organization: state.organizationReducer.organization,
  afterUpdate: state.rightDrawerShortReducer.afterUpdate,
  forceReload: state.avatarReducer.forceReload,
});

const mD = {
  enableRightDrawer,
  updateMember,
  editMember,
  updateGroupNotificationPreferences,
  editProfileUser,
  getAllGroupAsync,
  getOrgAsync,
  removeMemberFromGroup,
  updateMemberGroups,
  updateGroupsReducer,
  getGroupsAsync,
  snackBarStatus,
  updateDisplayChangePassword,
  deleteAccount,
  navigationControllerRedirect,
  getMemberGroups,
  getMemberFullInfo,
};
export default withRouter(connect(mS, mD)(withStyles(style)(EditMember)));
