import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ViewMemberPropTypes from '../../PropTypes/ViewMemberPropType';
import {
  displayEditMemberForm,
  getMemberProfile,
  sendInvitation,
  setLoading,
  updateDisplayChangePassword,
  updateMemberGroups,
  clearBounce,
} from '../../Actions/memberActions';
import { clearMembersList, getGroupMembers } from '../../Actions/membersActions';
import { changeTab } from '../../Actions/middleMenuActions';
import { getGroupsAsync, getOrgAsync, updateGroupsReducer } from '../../Actions/groupsActions';
import { enableRightDrawer } from '../../Actions/rightDrawerShortActions';
import snackBarStatus from '../../Actions/snackbarActions';
import EditMember from '../EditMember';
import EditPicture from '../EditPicture';
import { initDirectMessage, updateRecipientGroups, updateSelectedMember } from '../../Actions/messageActions';
import EvaTooltip from '../EvaTooltip';
import { openCustomModal } from '../../Actions/customModalActions';
import { NO_SEND_MSG_PATHS } from '../../Config/Constants';
import LoaderOverlay from '../LoaderOverlay';
import GroupsChannelsList from '../GroupsChannelsList';
import MemberInfoPad from '../MemberInfoPad';
import MessageModalManager, { MESSAGE_MODAL_MANAGER_VIEWS } from '../../Containers/MessageModalManager'; // TODO: separate styles
import '../EditMember/index.sass';

class ViewMember extends Component {
  state = {
    displayEdit: false,
    displayPicture: false,
  };

  // TODO: refactor
  handleEdit = e => {
    e.preventDefault();
    this.props.updateDisplayChangePassword(false);
    if (this.props.location.pathname === '/') {

      this.props.enableRightDrawer({
        payload: {
          enable: true,
          element: <EditMember/>,
          title: '',
        },
      });
    } else {
      this.setState({ displayEdit: true });
      this.props.displayEditMemberForm(true);
    }
  };

  refresh = () => {
    this.props.getOrgAsync().then(response => {
      this.props.updateGroupsReducer({ payload: { organizations: response } });
      this.props.getGroupsAsync();
    });
  };

  changeDisplay = ({ displayEdit, displayPicture }) => {
    const { selectedMember } = this.props;
    this.props.updateSelectedMember({ ...selectedMember });
    this.setState({ displayEdit, displayPicture });
  };

  switchEditable = changeDisplay => {
    if (this.state.displayPicture) {
      return (
        <EditPicture
          changeDisplay={changeDisplay}
          group={this.props.selectedGroup}
          member={this.props.selectedMember}
        />
      );
    }
    return <EditMember changeDisplay={changeDisplay}/>;
  };

  handleLink = () => {
    const { selectedGroup, selectedMember } = this.props;
    this.props.setLoading(true);
    this.props
      .sendInvitation(selectedGroup.public_id, selectedMember.publicId)
      .then(res => {
        if (res.status === 200) {
          this.props.snackBarStatus({
            payload: {
              enable: true,
              title: 'Message Sent',
              type: 'success',
            },
          });
          this.props.setLoading(false);
        }
      })
      .catch(() => {
        this.props.snackBarStatus({
          payload: {
            enable: true,
            title: 'Error, try again',
            type: 'error',
          },
        });
      });
  };

  refreshMembersList = (selectedGroup) => {
    if (selectedGroup && selectedGroup.public_id) {
      this.props.clearMembersList();
      this.props.getGroupMembers(selectedGroup.public_id, '', false, 1).then(members => {
        if (members.length === 0 && !searchTerm && !filters.status) {
          this.props.changeTab(<Groups/>, 1);
        }
      });
    }
  }

  handleClearBounce = () => {
    const { selectedMember, selectedGroup } = this.props;
    this.props.setLoading(true);
    this.props
      .clearBounce(selectedMember.publicId)
      .then(res => {
        if (res.status === 200) {
          this.props.snackBarStatus({
            payload: {
              enable: true,
              title: 'Bounce cleared successfully',
              type: 'success',
            },
          });
          this.refreshMembersList(selectedGroup);
          this.props.setLoading(false);
        }
      })
      .catch((error) => {
        this.props.snackBarStatus({
          payload: {
            enable: true,
            title: 'Error clearing bounce, try again',
            type: 'error',
          },
        });
      });
  };

  handleSendDirectMessageAction = () => {
    const { selectedMember, selectedGroup } = this.props;

    this.props.initDirectMessage(selectedMember.publicId, selectedGroup.public_id);

    this.props.openCustomModal({ content: <MessageModalManager view={MESSAGE_MODAL_MANAGER_VIEWS.EDITOR} /> });

    this.props.enableRightDrawer({
      payload: {
        enable: false,
        element: <div/>,
        title: '',
      },
    });
  };

  isInProperPath = () => {
    const { pathname } = this.props.location;
    return !NO_SEND_MSG_PATHS.includes(pathname);
  };

  isSelf = selectedMember => selectedMember.publicId === this.props.userMemberPublicId;

  renderMember() {
    const { selectedMember, membersDataUpdated, selectedMember: { groups } } = this.props;
    const isSelf = this.isSelf(selectedMember);
    const isInvitedCompletePage = this.props.location.pathname === '/invited-complete-profile';
    return (
      <React.Fragment>
        {this.state.displayEdit || this.state.displayPicture ? (
          <div>{this.switchEditable(this.changeDisplay)}</div>
        ) : (
          <section className="view-member-wrapper view-mode" id="view-member-form">

            <div className="edit-member-create-icon">
              <EvaTooltip title="Edit Profile">
                <button onClick={this.handleEdit}/>
              </EvaTooltip>
            </div>

            <MemberInfoPad
              isSelf={isSelf || !this.isInProperPath()}
              member={selectedMember}
              onDirectClick={this.handleSendDirectMessageAction}
              onShareClick={this.handleLink}
              onClearBounceClick={this.handleClearBounce}
              showBounceInfo={true}
            />

            <section>
              {!isInvitedCompletePage && (
                <div className="section-title">Groups</div>
              )}

              <GroupsChannelsList groups={membersDataUpdated || groups}/>
            </section>
          </section>
        )}
      </React.Fragment>
    );
  }


  render = () => this.props.loading ? <LoaderOverlay/> : this.renderMember();
}

ViewMember.propTypes = ViewMemberPropTypes;

const mS = state => ({
  groups: state.groupsReducer.groups,
  loading: state.memberReducer.loading,
  selectedMember: state.memberReducer.selectedMember,
  error: state.groupsReducer.error,
  selectedGroup: state.groupsReducer.selectedGroup,
  userMemberPublicId: state.userProfileReducer.member.public_id,
  publicId: state.memberReducer.publicId,
  organization: state.organizationReducer.organization,
});

const mD = {
  enableRightDrawer,
  getMemberProfile,
  initDirectMessage,
  snackBarStatus,
  sendInvitation,
  setLoading,
  updateSelectedMember,
  updateRecipientGroups,
  updateDisplayChangePassword,
  openCustomModal,
  displayEditMemberForm,
  getOrgAsync,
  updateMemberGroups,
  updateGroupsReducer,
  getGroupsAsync,
  clearBounce,
  clearMembersList,
  getGroupMembers,
  changeTab,
};
export default withRouter(connect(mS, mD)(ViewMember));
