import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
// Proptypes
import AuthPropTypes from '../PropTypes/AuthPropType';
// Components
import ChangePassword from '../Components/ChangePassword';
import ForgotPassword from '../Components/ForgotPassword';
import ForgotPasswordMessage from '../Components/ForgotPasswordMessage';
import Groups from '../Containers/Groups';
import Invitation from '../Containers/Invitation';
import LoginCheckSso from '../Components/LoginCheckSso';
import Logout from '../Components/Logout';
import Main from '../Containers/Main';
import MainLayout from '../Containers/MainLayout';
import MessagePublicPreview from '../Components/MessagePublicPreview';
import MessageFromThread from '../Components/MessageFromThread';
import Messages from '../Containers/Messages';
import MessageStatisticsPage from '../Containers/MessageStatisticsPage';
import MyProfile from '../Containers/MyProfile';
import DeleteAccount from '../Components/DeleteAccount';
import Statistics from '../Containers/Statistics';
import NotFound from '../Components/NotFound';
import ResetCode from '../Components/ResetCode';
import Templates from '../Containers/Templates';
import TwitterCallback from '../Components/TwitterCallback';
import UnauthorizedPage from '../Components/SessionAuthorization';
import CompleteProfile from '../Components/CompleteProfile';
import Unsubscribe from '../Containers/Unsubscribe';
import NotSubscribed from '../Containers/Unsubscribe/NotSubscribed';
import DialogDraftSent from '../Components/DialogDraftSent';
import SelectOrganization from '../Containers/SelectOrganization';
import InvitationSubmit from '../Containers/InvitationSubmit';
import InvitationOrgAdmin from '../Containers/InvitationOrgAdmin';
import InvitationGroupAdmin from '../Containers/InvitationGroupAdmin';
// Action creators
import snackBarStatus from '../Actions/snackbarActions';
import { updateLayoutData } from '../Actions/responsiveActions';
import { closeCustomModal } from '../Actions/customModalActions';
import { clearCompose } from '../Actions/composeActions';
import GuardedRoute from '../Components/GuardedRoute';
import { ROLES_ADMIN, ROLES_MEMBER } from './Constants';
import LoadingScreen from '../Components/LoadingScreen';
import { clearMessage, getTimeZone, updateIsDraftAlreadySentFlag } from '../Actions/messageActions';
import { navigationControllerRedirect } from '../Actions/navigationControllerActions';
import { getOrganizations, selectFirstAvailableOrg } from '../Actions/organizationActions';
import { checkSsoLoginIsAlive, clearLogin, getAuthDataFromStorage } from '../Actions/loginActions';
import { getGroupsDictionary } from '../Actions/groupsDictionaryActions';
import { getUserProfile } from '../Actions/userActions';
import { redirectToLogin } from '../Helpers/redirectToLogin';
import { isValuesFromArrayInArray } from '../Helpers/isValuesFromArrayInArray';
import { setupInterceptors } from '../Actions/authHttpActions';
import DesignerPage from '../Containers/DesignerPage';
import { setOptions } from '@mobiscroll/react';
import { defaultLanguageCode, translatedMessages } from '../Translations/intl/messages';

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

export const ROOT_ROUTE_ADMIN = '/';
export const ROOT_ROUTE_MEMBER = '/invited-complete-profile';

class Routes extends Component {

  isInterceptorsSet = false;

  constructor(props) {
    super(props);
    this.setupAppInterceptors();
    this.props.getTimeZone();
    this.props.getAuthDataFromStorage();
  }

  componentDidMount() {

    // Remove data from LS if navigate to complete-profile page.
    this.props.checkSsoLoginIsAlive();

    this.props.updateLayoutData();
    window.addEventListener('resize', this.props.updateLayoutData);
    window.addEventListener('orientationchange', this.props.updateLayoutData);
  }

  setupAppInterceptors() {
    if (!this.isInterceptorsSet) {
      setupInterceptors();
      this.isInterceptorsSet = true;
    }
  }

  async handleOrgSelected() {
    return this.props.getGroupsDictionary();
  }

  adminPageResolver() {
    this.props.getTimeZone();
    return new Promise((resolve) => {
      this.props.getOrganizations().then(orgs => {
        this.props.getUserProfile(this.props.organizationId).then(() => {
          if (orgs && orgs.length) {
            this.props.selectFirstAvailableOrg();
            this.handleOrgSelected().then(resolve);
          }
        });
      });
    });
  }

  memberPageResolver() {
    return this.props.getUserProfile();
  }

  selectOrgPageResolver() {
    this.props.getTimeZone();
    return this.props.getOrganizations();
  }

  invitationPageResolver() {
    const { token } = localStorage;
    if (token) {
      return this.memberOrAdminPageResolver();
    } else {
      return Promise.resolve();
    }
  }

  // Resolver for cases when we need to determine first what kind of user would be on the page
  memberOrAdminPageResolver() {
    return new Promise((resolve) => {
      this.props.getUserProfile().then(response => {
        if (response.status === 200) {
          const isAdmin = isValuesFromArrayInArray(response.data.data.member.roles, ROLES_ADMIN);
          let resolver; // use resolver depend on role
          if (isAdmin) {
            resolver = this.adminPageResolver.bind(this);
          } else {
            resolver = this.memberPageResolver.bind(this);
          }
          resolver().then(resolve);
        } else {
          resolve();
        }
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.props.updateLayoutData);
    window.removeEventListener('orientationchange', this.props.updateLayoutData);
  }

  handleDraftAlreadySentConfirm() {
    this.props.closeCustomModal();
    this.props.clearCompose();
    this.props.clearMessage();
    this.props.updateIsDraftAlreadySentFlag(false);

    let redirection;
    switch (this.props.currentTab) {
      case 0:
        redirection = '/groups';
        break;
      case 2:
        redirection = '/templates';
        break;
      default:
        redirection = '/messages';
    }
    this.props.navigationControllerRedirect(redirection);
  }

  render = () => {
    return (
      <IntlProvider
        messages={translatedMessages[this.props.locale]}
        locale={window.navigator.language}
        defaultLocale={defaultLanguageCode}
      >
        {this.props.isTokenCheckedOnInit
          ? (
            <Router {...this.props}>
              <Main>
                <MainLayout>
                  <Switch>
                    <GuardedRoute
                      exact
                      path={ROOT_ROUTE_ADMIN}
                      resolver={this.adminPageResolver.bind(this)}
                      component={Groups}
                      permittedForRoles={ROLES_ADMIN}
                      authRequired
                    />
                    <GuardedRoute
                      exact
                      path="/messages/:messageId/statistics"
                      resolver={this.adminPageResolver.bind(this)}
                      component={MessageStatisticsPage}
                      permittedForRoles={ROLES_ADMIN}
                      authRequired
                    />
                    <Route
                      exactS
                      path="/login"
                      render={() => {
                        redirectToLogin();
                        return null;
                      }}
                    />
                    <Route
                      exact
                      path="/login-check"
                      component={LoginCheckSso}
                    />
                    <Route exact path="/logout" component={Logout} />
                    <Route exact path="/request-password" component={ForgotPassword} />
                    <Route exact path="/reset-password" component={ResetCode} />
                    <Route exact path="/forgot-password/:token" component={ChangePassword} />
                    <GuardedRoute
                      exact
                      path="/organization/select-by-id/:orgId?"
                      resolver={this.selectOrgPageResolver.bind(this)}
                      render={() => <SelectOrganization onOrgSelected={this.handleOrgSelected.bind(this)} />}
                      permittedForRoles={ROLES_ADMIN}
                      authRequired
                    />

                    <Route exact path="/complete-profile/:groupId?/:token?" render={() => {
                      return <CompleteProfile />;
                    }} />

                    <Route exact path="/request-password-email" component={ForgotPasswordMessage} />

                    <GuardedRoute
                      exact
                      path={ROOT_ROUTE_MEMBER}
                      resolver={this.memberPageResolver.bind(this)}
                      component={MyProfile}
                      permittedForRoles={ROLES_MEMBER}
                      authRequired
                    />

                    <GuardedRoute
                      exact
                      path="/my-profile"
                      resolver={this.adminPageResolver.bind(this)}
                      render={() => <MyProfile isAdminView />}
                      permittedForRoles={ROLES_ADMIN}
                      authRequired
                    />

                    <Route exact path="/twitter-callback" component={TwitterCallback} />
                    <Route exact path="/unauthorized" component={UnauthorizedPage} />
                    <GuardedRoute
                      exact
                      path="/groups/:groupId?"
                      resolver={this.adminPageResolver.bind(this)}
                      component={Groups}
                      permittedForRoles={ROLES_ADMIN}
                      authRequired
                    />
                    <GuardedRoute
                      exact
                      path="/messages"
                      resolver={this.adminPageResolver.bind(this)}
                      component={Messages}
                      permittedForRoles={ROLES_ADMIN}
                      authRequired
                    />

                    <GuardedRoute
                      exact
                      path="/messages/new"
                      resolver={this.adminPageResolver.bind(this)}
                      render={(props) => <DesignerPage contentType="message" {...props} />}
                      permittedForRoles={ROLES_ADMIN}
                      authRequired
                    />

                    <GuardedRoute
                      exact
                      path="/messages/:messageId"
                      resolver={this.adminPageResolver.bind(this)}
                      component={Messages}
                      permittedForRoles={ROLES_ADMIN}
                      authRequired
                    />

                    <GuardedRoute
                      exact
                      path="/messages/edit/:publicId"
                      resolver={this.adminPageResolver.bind(this)}
                      render={(props) => <DesignerPage {...props} contentType="message" />}
                      permittedForRoles={ROLES_ADMIN}
                      authRequired
                    />

                    <GuardedRoute
                      exact
                      path="/templates/new"
                      resolver={this.adminPageResolver.bind(this)}
                      render={(props) => <DesignerPage contentType="template" {...props} />}
                      permittedForRoles={ROLES_ADMIN}
                      authRequired
                    />

                    <GuardedRoute
                      exact
                      path="/templates/edit/:publicId"
                      resolver={this.adminPageResolver.bind(this)}
                      render={(props) => <DesignerPage contentType="template" {...props} />}
                      permittedForRoles={ROLES_ADMIN}
                      authRequired
                    />

                    <GuardedRoute
                      exact
                      path="/templates"
                      resolver={this.adminPageResolver.bind(this)}
                      component={Templates}
                      permittedForRoles={ROLES_ADMIN}
                      authRequired
                    />
                    <GuardedRoute
                      path="/templates/:publicId"
                      resolver={this.adminPageResolver.bind(this)}
                      component={Templates}
                      permittedForRoles={ROLES_ADMIN}
                      authRequired
                    />

                    <Route exact path="/statistics" component={Statistics} />

                    <Route exact path="/message/:id" component={MessagePublicPreview} />

                    <GuardedRoute
                      exact
                      path="/message-redirect/:messageId"
                      resolver={this.adminPageResolver.bind(this)}
                      component={MessageFromThread}
                      permittedForRoles={ROLES_ADMIN}
                      authRequired
                    />

                    <GuardedRoute
                      exact
                      path="/admin-promotion/organization/:publicId/:invitationToken"
                      resolver={this.invitationPageResolver.bind(this)}
                      component={InvitationOrgAdmin}
                    />

                    <GuardedRoute
                      exact
                      path="/superadmin-promotion/organization/:publicId/:invitationToken"
                      resolver={this.invitationPageResolver.bind(this)}
                      render={(props) => <InvitationOrgAdmin superAdmin {...props} />}
                    />

                    <GuardedRoute
                      exact
                      path="/admin-promotion/group/:publicId/:invitationToken"
                      resolver={this.invitationPageResolver.bind(this)}
                      component={InvitationGroupAdmin}
                    />

                    <GuardedRoute
                      exact
                      path="/invitation/:type/:publicId"
                      resolver={this.invitationPageResolver.bind(this)}
                      component={Invitation}
                    />
                    <GuardedRoute
                      exact
                      path="/invitation/:type/:publicId/:invitationToken"
                      resolver={this.invitationPageResolver.bind(this)}
                      component={Invitation}
                    />
                    <GuardedRoute
                      exact
                      path="/invitation-submit/:type/:publicId"
                      resolver={this.memberOrAdminPageResolver.bind(this)}
                      component={InvitationSubmit}
                      authRequired
                    />
                    <Route
                      exact
                      path="/unsubscribe/group/:groupId/member/:memberId/:token"
                      component={Unsubscribe}
                    />
                    <Route exact path="/delete-account" component={DeleteAccount} />
                    <Route exact path="/not-found" component={NotFound} />
                    <Route exact path="/not-subscribed" component={NotSubscribed} />
                    <Route component={NotFound} />
                  </Switch>

                  <DialogDraftSent
                    isOpen={this.props.isDraftAlreadySent}
                    onConfirm={this.handleDraftAlreadySentConfirm.bind(this)}
                    onCancel={this.handleDraftAlreadySentConfirm.bind(this)}
                  />

                </MainLayout>
              </Main>
            </Router>
          )
          : <LoadingScreen />}
      </IntlProvider>
    );
  }
}

const mS = state => ({
  locale: state.localeReducer.locale,
  isTokenCheckedOnInit: state.loginReducer.isTokenCheckedOnInit,
  isDraftAlreadySent: state.messageReducer.isDraftAlreadySent,
  currentTab: state.mainTabsReducer.value,
  organizationId: state.organizationReducer.organization.public_id,
});

const mD = {
  getAuthDataFromStorage,
  checkSsoLoginIsAlive,
  updateLayoutData,
  closeCustomModal,
  updateIsDraftAlreadySentFlag,
  clearMessage,
  clearCompose,
  navigationControllerRedirect,
  getOrganizations,
  getTimeZone,
  selectFirstAvailableOrg,
  getGroupsDictionary,
  getUserProfile,
  clearLogin,
  snackBarStatus,
};

Routes.propTypes = AuthPropTypes;

export default connect(
  mS,
  mD,
)(Routes);
