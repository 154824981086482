import SocialShare from '../Api/SocialShare';

export const ACTIONS = {
  SET_STATUS_TWITTER_AUTHORIZATION: '[Social share] Set status twitter authorization',
  SET_TWITTER_ACCESS_TOKEN: '[Social share] Set twitter access token',
  SET_TWITTER_ACCOUNT_INFO: '[Social share] Set twitter account info',
  SET_FACEBOOK_ACCOUNT_INFO: '[Social share] Set facebook account info',
  REMOVE_TWITTER_ACCOUNT: '[Social share] Remove twitter account',
  REMOVE_FACEBOOK_ACCOUNT: '[Social share] Remove facebook account',
  SAVE_TWITTER_AUTHORIZATION_TOKEN: '[Social share] Save twitter authorization token',
  UPDATE_STATUS_TWITTER_AUTHORIZATION: '[Social share] Update status twitter authorization',
  SET_TWITTER_LOADING: '[Social share] Twitter loading',
  SET_FACEBOOK_LOADING: '[Social share] Facebook loading',
};

export const getTwitterAccessToken = groupPublicId => dispatch =>
  SocialShare.getTwitterAccessToken(groupPublicId).then(response => {
    if (response.data && response.status === 200) {
      dispatch({
        type: ACTIONS.SET_TWITTER_ACCESS_TOKEN,
        payload: response.data.data,
      });
    }

    return response.data.data;
  });

export const saveTwitterAuthorizationToken = params => dispatch =>
  SocialShare.saveTwitterAuthorizationToken(params).then(response => {
    if (response.data && response.status === 200) {
      dispatch({
        type: ACTIONS.SAVE_TWITTER_AUTHORIZATION_TOKEN,
        payload: response.data.data,
      });
    }

    return response.data.data;
  });

export const setTwitterAuthorizationProgressStatus = (params, status) => dispatch =>
  SocialShare.setTwitterAuthorizationProgressStatus(params, status).then(response => {
    if (response.data && response.status === 200) {
      dispatch({
        type: ACTIONS.UPDATE_STATUS_TWITTER_AUTHORIZATION,
        payload: response.data.data,
      });
    }

    return response.data.data;
  });

export const getTwitterAccountInfo = groupPublicId => dispatch =>
  SocialShare.getTwitterAccountInfo(groupPublicId).then(response => {
    if (response.data && response.status === 200) {
      dispatch({
        type: ACTIONS.SET_TWITTER_ACCOUNT_INFO,
        payload: response.data.data,
      });
    }

    return response.data.data;
  });

export const getFirstAliveTwitterAccountInfo = idArr => dispatch => {
  setTwitterLoading(true)(dispatch);
  Promise.all(idArr.map(id => SocialShare.getTwitterAccountInfo(id))).then(response => {
    const twIntegrations = response.map(r => r.data.data)
      .filter(data => !!data)
      .filter(data => data.status === 'active');

    dispatch({
      type: ACTIONS.SET_TWITTER_ACCOUNT_INFO,
      payload: twIntegrations.length > 0 ? twIntegrations[0] : null,
    });
    setTwitterLoading(false)(dispatch);

    return response;
  });
};

export const removeTwitterAccount = groupPublicId => dispatch =>
  SocialShare.removeTwitterAccount(groupPublicId).then(response => {
    if (response.data && response.status === 200) {
      dispatch({
        type: ACTIONS.REMOVE_TWITTER_ACCOUNT,
        payload: response.data.data,
      });
    }

    return response.data.data;
  });

export const setFacebookAccountInfo = data => dispatch => {
  dispatch({
    type: ACTIONS.SET_FACEBOOK_ACCOUNT_INFO,
    payload: data,
  });
};

export const setTwitterLoading = bool => dispatch =>
  dispatch({
    type: ACTIONS.SET_TWITTER_LOADING,
    payload: bool,
  });

export const setFacebookLoading = bool => dispatch =>
  dispatch({
    type: ACTIONS.SET_FACEBOOK_LOADING,
    payload: bool,
  });
