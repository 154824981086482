import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiltersPosedWrapper } from '../FilterPosedWrapper';
import { Datepicker } from '@mobiscroll/react';
import './index.sass';

class MessagesFilters extends Component {
  handleTypeChange = (name) => {
    const { filters, onFiltersChange, onSelectionErase } = this.props;
    const isActive = filters.type === name;
    const updFilters = {
      ...filters,
      type: isActive ? null : name,
    };

    onSelectionErase?.();
    onFiltersChange(updFilters);
  }

  handleDateChange = (event) => {
    const { filters, onFiltersChange } = this.props;
    const updFilters = {
      ...filters,
      date: {
        from: event.value[0],
        to: event.value[1],
      },
    };
    onFiltersChange(updFilters);
  }

  handleFiltersGroupReset = (filterGroupName) => {
    const { filters, onFiltersChange } = this.props;
    const updFilters = {
      ...filters,
    };
    switch (filterGroupName) {
      case 'type':
        updFilters.type = null;
        break;

      case 'date':
        updFilters.date = {
          from: null,
          to: null,
        };
        break;
    }

    onFiltersChange(updFilters);
  }

  isTypeFilterEnabled = name => this.props.filters.type === name;

  activeClassGenerator = name => this.isTypeFilterEnabled(name) ? 'active' : '';

  render = () => (
    <FiltersPosedWrapper className="filters-pad group-filters-pad" pose={this.props.isOpen ? 'open' : 'closed'}>

      <div className="filters-inner-wrapper">

        <section className="filters-group">
          <div className="filters-group-title">Date Range</div>
          <button className="btn-all" onClick={() => {
            this.handleFiltersGroupReset('date');
          }}>
            All
          </button>

          <div className="filters-wrapper">
            <Datepicker
              select="range"
              onChange={this.handleDateChange}
              returnFormat="iso8601"
              max={new Date()}
              value={this.props.filters.date.from || this.props.filters.date.from
                ? [this.props.filters.date.from, this.props.filters.date.to] : null}
              placeholder="MM/DD/YY - MM/DD/YY"
              buttons={['set', 'cancel']}
            />
          </div>
        </section>

        <section className="filters-group">
          <div className="filters-group-title">Message Type</div>
          <button className="btn-all" onClick={() => {
            this.handleFiltersGroupReset('type');
          }}>
            All
          </button>

          <div className="filters-wrapper">
            <button
              className={`filter-button ${this.activeClassGenerator('draft')}`}
              onClick={() => this.handleTypeChange('draft')}
            >
              Draft
              <i className="icon-filter-hidden"/>
            </button>
            <button
              className={`filter-button ${this.activeClassGenerator('archived')}`}
              onClick={() => this.handleTypeChange('archived')}
            >
              Archived
            </button>
            <button
              className={`filter-button ${this.activeClassGenerator('scheduled')}`}
              onClick={() => this.handleTypeChange('scheduled')}
            >
              Scheduled
            </button>
            <button
              className={`filter-button ${this.activeClassGenerator('pending')}`}
              onClick={() => this.handleTypeChange('pending')}
            >
              Pending Replies
            </button>
            <button
              className={`filter-button ${this.activeClassGenerator('waiting_import')}`}
              onClick={() => this.handleTypeChange('waiting_import')}
            >
              Camino Importing
            </button>
          </div>
        </section>

      </div>
    </FiltersPosedWrapper>
  )

}

MessagesFilters.propTypes = {
  filters: PropTypes.instanceOf(Object).isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  onSelectionErase: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};

export default MessagesFilters;

