/* eslint-disable no-param-reassign */
import Message from '../Api/Message';
import snackBarStatus from './snackbarActions';
import { MPACutter } from '../Helpers/Message/Cutter/mpa-cutter';
import FbCutter from '../Helpers/Message/Cutter/fb-cutter';
import { camelCaseObjects } from '../Helpers/Utility';
import { addTimezoneToDate } from '../Helpers/date';
import TWCutter from '../Helpers/Message/Cutter/twitter-cutter';
import SMSCutter from '../Helpers/Message/Cutter/sms-cutter';
import { getPreviewPrefix } from '../Helpers/Message/getPreviewPrefix';
import { getTextFromHtml } from '../Helpers/Message/getTextFromHtml';
import { getImagesSrcFromHtml } from '../Helpers/Message/getImagesSrcFromHtml';
import { getSelectedLongestGroupName } from '../Helpers/Message/getSelectedLongestGroupName';
import { getFirstAliveTwitterAccountInfo, setFacebookAccountInfo } from './socialShareActions';
import { cancelScheduledMessageInList, refreshMessages, selectMessage, updateMessageInList } from './messagesActions';
import { getOrganization, selectOrganization } from './organizationActions';
import { composerToDesign } from '../Helpers/composerToDesign';
import { emailRegex } from '../Helpers/regex';
import moment from 'moment';
import { debounce } from 'lodash';
import { DRAFT_AUTO_SAVE_DELAY, MESSAGE_DESIGNER_TYPE, REQUEST_CANCELLED_MESSAGE } from '../Config/Constants';
import { getRecipientGroupsFromDictionary } from '../Helpers/getRecipientGroupsFromDictionary';
import { isDeprecatedContent } from '../Helpers/isDeprecatedContent';
import { SUPPORT_PHONE_NUMBER } from '../Config/env';
import Member from '../Api/Member';
import { getMemberNotificationPreference } from '../Helpers/getMemberNotificationPreference';

export const CONSTANTS = {
  RECIPIENT_TYPE_MEMBER: 'member',
  RECIPIENT_TYPE_GROUP: 'group',
  PREFERENCE_EMAIL: 'email',
  PREFERENCE_SMS: 'sms',
};

export const ACTIONS = {
  APPLY_TEMPLATE: '[Message] Apply template',
  CHANGE_TAB: '[Message] Change tab',
  CLEAR_VALUES_MESSAGE: '[Message] Clear values message',
  FORMAT_TO_DESIGNER: '[Message] Format to designer',
  INIT_REPLY_MESSAGE: '[Message] Initialize reply message',
  SET_EDITOR_TYPE: '[Message] Set editor type',
  SET_MESSAGE: '[Message] Set message',
  SET_MESSAGE_PREVIEWS: '[Message] Set previews',
  SET_COMPOSE_OPTION: '[Message] Set compose option', // TODO: review
  GET_TIME_ZONE: '[Message] Get time zone', // TODO: move out of current reducer
  LOADING_MESSAGE: '[Message] Loading message',
  LOADING_MESSAGE_SENDING: '[Message] Loading message send',
  OPEN_MENU: '[Message] Open menu', // TODO: remove?
  SET_PUBLIC_ID: '[Message] Set publicId from draft',
  RECIPIENT_GROUPS_UPDATE: '[Message] Set recipient groups',
  SELECTED_MEMBER: '[Message] Set selected member',
  SENT_MESSAGE: '[Message] Sent message',
  STATUS_WINDOW_MESSAGE: '[Message] Status windows message',
  UPDATE_DIALOG: '[Message] Update dialog',
  UPDATE_DRAFT_MESSAGE: '[Message] Update draft message',
  SET_REPLY_TO: '[Message] Set reply to value',
  GET_MESSAGE_STATISTICS: '[Message] GET messages statistics',
  UPDATE_MESSAGE_CONTENT: '[Message] Update message content values',
  UPDATE_IS_DRAFT_SAVING: '[Message] Update draft saving status',
  HANDLE_DIALOG_STATUS: '[Message] Handle cancel scheduled message dialog status',
  CHANGE_CONFIRM_DIALOG_STATUS: '[Message] Change confirm dialog status',
  GET_URL_FROM_BASE: '[Message] Get url from base',
  UPDATE_SELECTED_MEMBER_PROPS: '[Message] Update selected member',
  UPDATE_MESSAGE_SETTINGS: '[Message] Update message settings',
  UPDATE_STATUS_MESSAGE: '[Message] Update status message',
  UPDATE_EDITOR_CONTENT: '[Message] Update editor content',
  ERASE_EDITOR_CONTENT: '[Message] Erase editor content',
  UPDATE_SMS_CONTENT: '[Message] Update sms content',
  UPDATE_MPA_CONTENT: '[Message] Update MPA content',
  UPDATE_TWITTER_CONTENT: '[Message] Update Twitter content',
  UPDATE_FACEBOOK_CONTENT: '[Message] Update Facebook content',
  UPDATE_WEB_CONTENT: '[Message] Update WEB content',
  UPDATE_SEND_BY_SMS: '[Message] Update send by Sms',
  UPDATE_SEND_BY_EMAIL: '[Message] Update send by Email',
  UPDATE_SEND_BY_MPA: '[Message] Update send by MPA',
  UPDATE_SEND_BY_TWITTER: '[Message] Update send by Twitter',
  UPDATE_SEND_BY_FACEBOOK: '[Message] Update send by Facebook',
  UPDATE_SEND_BY_WEB: '[Message] Update send by Web',
  UPDATE_SEND_BY: '[Message] Update send by (flags)',
  UPDATE_ALLOWED_CHANNELS: '[Message] Update allowed channels',
  SET_CONTENT_IMAGES: '[Message] Set content images',
  SELECT_PREVIEW_IMAGE: '[Message] Select preview image',
  CROPPING_IMAGE: '[Message] cropping_image',
  UPDATE_IS_TEMPLATE: '[Message] Update isTemplate flag',
  UPDATE_MESSAGE_SUBJECT: '[Message] Update message subject',
  UPDATE_MESSAGE_SENDER_NAME: '[Mesasge] Update message sender name',
  INIT_DRAFT_MESSAGE: '[Message] Init draft message',
  UPDATE_DRAFT_ALREADY_SENT: '[Message] Update draft message already sent state',
  UPDATE_RECIPIENT_TYPE: '[Message] Update recipient type',
  UPDATE_ALL_MEMBERS_SELECTED: '[Message] Update all members selected',
  VALIDATE: '[Message] Validate',
  VALIDATION_RESET: '[Message] Validation reset',
  SET_RESEND_UNOPENED: '[Message] Set resend unopened',
  RESET_SCHEDULED_STATUS: '[Message] Reset scheduled status',
  UPDATE_FILES: '[Message] Update files',
  SET_LOCKED_BY_MEMBER: '[Message] Set locked by member',
};

const formatToListMessage = message => {
  const {
    publicId,
    subject,
    groups,
    createdAt,
    updatedAt,
    status,
    sentByMember,
    messageContent: { type, editorContent: { text } },
  } = camelCaseObjects(message);
  return {
    publicId,
    subject,
    createdAt,
    updatedAt,
    type,
    status,
    textContent: text,
    groups: groups.map(g => ({ publicId: g.group.publicId, name: g.group.name, shortName: g.group.shortName })),
    member: sentByMember ? {
      publicId: sentByMember.publicId,
      profilePictureUrl: sentByMember.profilePictureUrl,
    } : null,
  };
};

const refreshRecipientType = () => (dispatch, getState) => {
  const { messageReducer: { selectedMember } } = getState();

  dispatch({
    type: ACTIONS.UPDATE_RECIPIENT_TYPE,
    payload: selectedMember ? CONSTANTS.RECIPIENT_TYPE_MEMBER : CONSTANTS.RECIPIENT_TYPE_GROUP,
  });
};

export const setLockedByMember = (member) => (dispatch) => {
  dispatch({ type: ACTIONS.SET_LOCKED_BY_MEMBER, payload: member });
};

export const applySelectedTemplate = isClear => (dispatch, getState) => {
  const {
    templateReducer: { template: { messageContent, files } },
  } = getState();

  if (isClear) {
    clearMessage()(dispatch, getState);
  }

  dispatch({ type: ACTIONS.APPLY_TEMPLATE, payload: { files, messageContent } });

  notifyIfDeprecated('template')(dispatch, getState);
};

export const updateIsTemplateFlag = bool => dispatch => {
  dispatch({ type: ACTIONS.UPDATE_IS_TEMPLATE, payload: bool });
};

export const updateIsDraftAlreadySentFlag = bool => dispatch => {
  dispatch({ type: ACTIONS.UPDATE_DRAFT_ALREADY_SENT, payload: bool });
};

export const initReplyMessage = isNestedThreadReply => (dispatch, getState) => {
  const {
    messagesReducer: { selectedMessage },
    threadReducer: { selectedThread },
  } = getState();

  const {
    publicId, recipientGroups,
    sendByEmail, sendByFacebook, sendByMpa, sendBySms, sendByTwitter, sendByWeb,
  } = selectedMessage;

  const data = {
    replyToMessage: isNestedThreadReply ? selectedThread.publicId : publicId,
    recipientGroups,
    sendByEmail,
    sendByFacebook,
    sendByMpa,
    sendBySms,
    sendByTwitter,
    sendByWeb,
  };

  dispatch({
    type: ACTIONS.INIT_REPLY_MESSAGE,
    payload: data,
  });

  dispatch({
    type: ACTIONS.SELECTED_MEMBER,
    payload: isNestedThreadReply ? selectedThread.sentByMember : selectedMessage.sentByMember,
  });

  setPreviewContent()(dispatch, getState);
};

export const notifyIfDeprecated = (type = 'template/message') => (dispatch, getState) => {
  const {
    messageReducer: { messageContent: { editorContent: { html } } },
  } = getState();
  if (isDeprecatedContent(html)) {
    snackBarStatus({
      payload: {
        title: `This ${type} was created using an old version of the message Designer, and editing capabilities are limited to HTML.
    To optimize the quality of your message/template, we suggest creating a new message/template with the improved current version of the Designer.
    If you would like assistance updating your old message/template for use with the new Designer,
    please reach out to our support team at ${SUPPORT_PHONE_NUMBER}.
    We appreciate your understanding during this transition.`,
        type: 'success',
        enable: true,
      },
    })(dispatch);
  }
};

export const setMessage = message => (dispatch, getState) => {
  const {
    organizationReducer: { organization: { lite: isLiteMode } },
  } = getState();

  const updObj = {
    publicId: message.publicId,
    sender_name: message.senderName,
    messageContent: {
      ...message.messageContent,
      editorContent: {
        ...message.messageContent.editorContent,
        text: message.messageContent.editorContent.text === null ? '' : message.messageContent.editorContent.text, // TODO: set empty string on the server instead of null
      },
    },
    files: message.files || [],
  };

  if (message.subject) {
    updObj.subject = message.subject;
  }

  dispatch({ type: ACTIONS.SET_MESSAGE, payload: updObj });

  if (message.recipientGroups) {
    updateRecipientGroups(message.recipientGroups)(dispatch, getState);
  }

  if (isLiteMode) {
    initLiteModeMessage(dispatch);
  }

  setContentImages()(dispatch, getState);

  refreshRecipientType()(dispatch, getState);
};

const setContentImages = () => (dispatch, getState) => {
  const { messageReducer: { messageContent: { editorContent: { html } } } } = getState();
  dispatch({
    type: ACTIONS.SET_CONTENT_IMAGES,
    payload: getImagesSrcFromHtml(html),
  });
  setPreviewContent()(dispatch, getState);
};

export const selectPreviewImage = url => (dispatch, getState) => {
  const { messageReducer: { contentImages, previewImage } } = getState();
  if (url !== previewImage && contentImages.includes(url)) {
    dispatch({
      type: ACTIONS.SELECT_PREVIEW_IMAGE,
      payload: url,
    });
  }
};

export const updateFiles = (files) => (dispatch, getState) => {
  const { messageReducer: { isTemplate, recipientType } } = getState();

  dispatch({
    type: ACTIONS.UPDATE_FILES,
    payload: files,
  });

  if (!isTemplate && recipientType !== CONSTANTS.RECIPIENT_TYPE_MEMBER) {
    saveDraftMessage()(dispatch, getState);
  }
};

export const updateSendBySms = bool => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_SEND_BY_SMS,
    payload: bool,
  });
};

export const updateSendByEmail = bool => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_SEND_BY_EMAIL,
    payload: bool,
  });
};

export const updateSendByMPA = bool => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_SEND_BY_MPA,
    payload: bool,
  });
};

export const updateSendByTwitter = bool => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_SEND_BY_TWITTER,
    payload: bool,
  });
};

export const updateSendByFacebook = bool => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_SEND_BY_FACEBOOK,
    payload: bool,
  });
};

export const updateSendByWeb = bool => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_SEND_BY_WEB,
    payload: bool,
  });
};

export const updateSendByFlags = obj => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_SEND_BY,
    payload: obj,
  });
};

const updateAllowedChannels = obj => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_ALLOWED_CHANNELS,
    payload: obj,
  });
};

export const updateChannelsToResendUnopened = () => dispatch =>
  updateAllowedChannels({
    sms: false,
    email: true,
    mpa: false,
    facebook: false,
    twitter: false,
    web: false,
  })(dispatch);

export const updateEditorContent = (html, design = null, preventAutoSave = false) => (dispatch, getState) => {
  const { messageReducer: { isTemplate, recipientType } } = getState();

  dispatch({ type: ACTIONS.UPDATE_EDITOR_CONTENT, payload: { design, html, text: getTextFromHtml(html) } });

  setContentImages()(dispatch, getState);
  setPreviewContent()(dispatch, getState);

  if (!isTemplate && !preventAutoSave && recipientType !== CONSTANTS.RECIPIENT_TYPE_MEMBER) {
    saveDraftMessageWithDebounce()(dispatch, getState);
  }
};

export const addImageInComposer = imageSrc => (dispatch, getState) => {
  const { messageReducer: { messageContent: { editorContent: { html } } } } = getState();
  updateEditorContent(`${html}<img src='${imageSrc}'style="max-width: 100%" />`)(dispatch, getState);
};

export const eraseEditorContent = () => dispatch => dispatch({ type: ACTIONS.ERASE_EDITOR_CONTENT });

export const updateSmsPreviewContent = content => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_SMS_CONTENT,
    payload: content,
  });
};

export const updateMPAPreviewContent = content => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_MPA_CONTENT,
    payload: content,
  });
};

export const updateTwitterPreviewContent = content => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_TWITTER_CONTENT,
    payload: content,
  });
};

export const updateFacebookPreviewContent = content => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_FACEBOOK_CONTENT,
    payload: content,
  });
};

export const updateWebPreviewContent = content => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_WEB_CONTENT,
    payload: content,
  });
};

export const setPreviewContent = () => (dispatch, getState) => {
  const {
    groupsDictionaryReducer: { list },
    organizationReducer: { organization },
    messageReducer: {
      messageContent: {
        mainPicture,
        editorContent: { html, text },
      },
      isAllMemberGroupSelected,
      subject,
      recipientGroups,
    },
  } = getState();

  const organizationName = organization && organization.short_name ? organization.short_name : 'Some Organization';
  const groupName = isAllMemberGroupSelected ? '' : getSelectedLongestGroupName(list, recipientGroups);
  const noLinksText = getTextFromHtml(html, true);
  const isContainLinks = noLinksText.length !== text.length;
  const prefix = getPreviewPrefix(organizationName, groupName);
  const allImagesSrc = getImagesSrcFromHtml(html);
  const isMultiImg = allImagesSrc.length > 1;
  const image = allImagesSrc.length > 0 ? allImagesSrc[0] : null;
  const mpaSubject = isAllMemberGroupSelected && subject ? `${subject}. ` : '';

  // apply adapters
  const payload = {
    emailContent: {
      text: html,
      isEdited: false,
    },
    facebookContent: {
      image: mainPicture,
      text: FbCutter(noLinksText, isMultiImg, isContainLinks),
      isEdited: false,
    },
    mpaContent: {
      image: mainPicture,
      prefix: !isAllMemberGroupSelected ? prefix : '',
      text: MPACutter(`${mpaSubject}${text}`, !isAllMemberGroupSelected ? prefix.length : 0, !!mainPicture),
      isEdited: false,
    },
    smsContent: {
      prefix: prefix,
      text: SMSCutter(text, prefix.length, subject, organizationName, groupName, !!mainPicture),
      isEdited: false,
    },
    twitterContent: {
      image: mainPicture,
      text: TWCutter(noLinksText, isMultiImg, isContainLinks),
      isEdited: false,
    },
    webContent: {
      text: html,
      isEdited: false,
    },
  };

  dispatch({ type: ACTIONS.SET_MESSAGE_PREVIEWS, payload });
  selectPreviewImage(!mainPicture || !allImagesSrc.includes(mainPicture) ? image : mainPicture)(dispatch, getState);
};

export const updateMessageSubject = value => (dispatch, getState) => {
  const { messageReducer: { isTemplate } } = getState();

  dispatch({
    type: ACTIONS.UPDATE_MESSAGE_SUBJECT,
    payload: value,
  });

  setPreviewContent()(dispatch, getState);

  if (!isTemplate) {
    saveDraftMessageWithDebounce()(dispatch, getState);
  }
};

export const updateMessageSenderName = value => (dispatch, getState) => {
  const { messageReducer: { isTemplate } } = getState();

  dispatch({
    type: ACTIONS.UPDATE_MESSAGE_SENDER_NAME,
    payload: value,
  });

  if (!isTemplate) {
    saveDraftMessageWithDebounce()(dispatch, getState);
  }
};

export const setReplyTo = publicId => dispatch => {
  dispatch({
    type: ACTIONS.SET_REPLY_TO,
    payload: publicId,
  });
};

export const updateMessageContent = valuesObj => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_MESSAGE_CONTENT,
    payload: valuesObj,
  });
};

export const updateMessageSettings = settings => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_MESSAGE_SETTINGS,
    payload: settings,
  });
};

export const getMessagesStatistics = (publicId, type) => async dispatch => {
  try {
    const response = await Message.getMessagesStatistics(publicId, type);
    const data = response.data.data;
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.GET_MESSAGE_STATISTICS,
        payload: data,
      });
    }
    return data;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

const initLiteModeMessage = dispatch => {
  updateAllowedChannels({ sms: false })(dispatch);
  updateSendBySms(false)(dispatch);
};

export const clearMessage = () => (dispatch, getState) => {
  const { organizationReducer: { organization: { lite: isLiteMode } } } = getState();

  dispatch({
    type: ACTIONS.CLEAR_VALUES_MESSAGE,
  });

  if (isLiteMode) {
    initLiteModeMessage(dispatch);
  }
};

export const updateDraftMessage = (providedPublicId = null) => async (dispatch, getState) => {
  const {
    messageReducer,
    organizationReducer: { organization: { public_id: organizationPublicId } },
  } = getState();

  const data = formatDraftRequestData(messageReducer, organizationPublicId,
    messageReducer.publicId || providedPublicId);

  try {
    const response = await Message.updateDraftMessage(data);

    dispatch({
      type: ACTIONS.UPDATE_STATUS_MESSAGE,
      payload: true,
    });

    return response;
  } catch (err) {

    dispatch({
      type: ACTIONS.UPDATE_STATUS_MESSAGE,
      payload: false,
    });

    if (err.code === 409) {
      updateIsDraftAlreadySentFlag(true)(dispatch);
    } else {
      snackBarStatus({
        payload: {
          title: err.message,
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
    throw err;
  }
};

const updateIsDraftSaving = bool => dispatch => dispatch({
  type: ACTIONS.UPDATE_IS_DRAFT_SAVING,
  payload: bool,
});

export const saveDraftMessage = (isNotify = false) => async (dispatch, getState) => {
  const {
    messageReducer, messageReducer: { isDraftSaving, isTemplate },
    organizationReducer: { organization: { public_id: organizationPublicId } },
    templateReducer: { template: { publicId: templatePublicId } },
  } = getState();

  if (isDraftSaving) {
    return Promise.resolve();
  }

  const data = formatDraftRequestData(messageReducer, organizationPublicId, templatePublicId);

  try {

    updateIsDraftSaving(true)(dispatch);

    const response = data.publicId ? await Message.updateDraftMessage(data) : await Message.createDraftMessage(data);

    updateIsDraftSaving(false)(dispatch);

    if (response.status === 200) {
      updateMessageInList(formatToListMessage(response.data.data))(dispatch);

      // Do not update message publicId in 'template' mode.
      if (!isTemplate) {
        dispatch({
          type: ACTIONS.SET_PUBLIC_ID,
          payload: response.data.data.public_id,
        });
      }

      // Change page url if message publicId obtained
      if (!data.publicId && data.messageContent.type === MESSAGE_DESIGNER_TYPE && !isTemplate) {
        window.history.replaceState(null, null, `/messages/edit/${response.data.data.public_id}`);
      }
    }

    dispatch({
      type: ACTIONS.UPDATE_STATUS_MESSAGE,
      payload: true,
    });

    if (isNotify) {
      snackBarStatus({
        payload: {
          title: 'The draft message has been successfully updated.',
          type: 'success',
          enable: true,
        },
      })(dispatch);
    }

    return response;
  } catch (err) {
    dispatch({
      type: ACTIONS.UPDATE_STATUS_MESSAGE,
      payload: false,
    });

    if (err.code === 409) {
      updateIsDraftAlreadySentFlag(true)(dispatch);
    } else {
      snackBarStatus({
        payload: {
          title: (err.data && err.data.message) || err.message || 'Unknown Error',
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }

    throw err;
  }
};

export const saveDraftDebounce = debounce((dispatch, getState) => {
  saveDraftMessage()(dispatch, getState);
}, DRAFT_AUTO_SAVE_DELAY);

export const saveDraftMessageWithDebounce = () => (dispatch, getState) => {
  const { messageReducer: { isDraftAlreadySent } } = getState();

  if (!isDraftAlreadySent) {
    saveDraftDebounce(dispatch, getState);
  }
};

const formatRequestData = messageReducer => {
  const {
    subject,
    sender_name,
    sendByEmail,
    sendBySms,
    sendByWeb,
    recipientGroups,
    sendByFacebook,
    sendByTwitter,
    sendByMpa,
    messageSettings,
    recipientType,
    replyToMessage,
    selectedMember,
    messageContent,
    publicId,
    files,
  } = messageReducer;

  const data = {
    publicId,
    files: files.filter(f => !!f) || [],
    messageContent,
    to_groups: recipientGroups.map(publicId => ({ public_id: publicId })),
    recipientGroups,
    // TODO: unify
    members: recipientType === CONSTANTS.RECIPIENT_TYPE_MEMBER && selectedMember 
      ? [{ public_id: selectedMember.publicId }] 
      : null,
    to_members: recipientType === CONSTANTS.RECIPIENT_TYPE_MEMBER && selectedMember 
      ? [{ public_id: selectedMember.publicId }] 
      : null,
    subject,
    sender_name,
    deliveryTime: null,
    thread: true,
    replyToMessage,
    send_by_email: sendByEmail,
    send_by_sms: sendBySms,
    send_by_facebook: sendByFacebook,
    send_by_twitter: sendByTwitter,
    send_by_mpa: sendByMpa,
    send_by_web: sendByWeb,
    message_settings: {
      includeRecipientName: messageSettings ? messageSettings.includeRecipientName : false,
      previewText: messageSettings.previewText,
      emailForward: messageSettings.emailForward,
    },
  };

  if (messageSettings.deliveryTime && messageSettings.timeZone) {
    data.message_settings = {
      ...data.message_settings,
      isScheduledMessage: messageSettings.isScheduledMessage,
      timeZone: messageSettings.timeZone,
      deliveryTime: addTimezoneToDate(messageSettings.deliveryTime, messageSettings.timeZone),
    };
  }

  return data;
};

const formatDraftRequestData = (messageReducer, organizationId, templatePublicId) => ({
  ...formatRequestData(messageReducer),
  subject: messageReducer.subject ? messageReducer.subject : 'Draft Message',
  organization_public_id: organizationId,
  template_public_id: templatePublicId,
});

export const deleteDraftMessage = publicId => async dispatch => {
  try {
    const response = await Message.deleteDraftMessage({ publicId });
    let removeResponse = [];
    if (response.status === 200) {
      removeResponse = response.data;
    }
    return removeResponse;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};
/* eslint-disable*/
export const sendDraftTest = () => async (dispatch, getState) => {
  try {
    const {
      messageReducer,
    } = getState();

    const data = {
      ...formatRequestData(messageReducer),
      status: 'draft-test',
    };

    isMessageSending(true)(dispatch);

    const response = await Message.sendMessage(data);

    snackBarStatus({
      payload: {
        enable: true,
        title: 'Test Email Sent',
        type: 'success',
      },
    })(dispatch);

    isMessageSending(false)(dispatch);

    return response;
  } catch (error) {

    isMessageSending(false)(dispatch);

    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }

}
/* eslint-enable */

/* eslint-disable */
export const sendMessage = (extendedObj = {}) => async (dispatch, getState) => {
  try {

    const {
      messageReducer,
      messageReducer: { isResendUnopened },
      messagesReducer: { selectedMessage },
    } = getState();

    const data = {
      ...formatRequestData(messageReducer),
      ...extendedObj,
    }

    const resendPublicId = isResendUnopened ? selectedMessage.publicId : null;

    if (resendPublicId) {
      data.recipientGroups = selectedMessage.recipientGroups;
    }

    isMessageSending(true)(dispatch);

    const response = await Message.sendMessage(data, resendPublicId);

    isMessageSending(false)(dispatch);

    if(!response.data.data.isImportComplete) {
      snackBarStatus({
        payload: {
          title: 'Message will be sent when Member Import is completed',
          type: 'success',
          enable: true,
        },
      })(dispatch);
    }
    await refreshMessages()(dispatch, getState);

    return response;
  } catch (error) {
    console.error(error);

    isMessageSending(false)(dispatch);

    if (error.code === 409) {
      updateIsDraftAlreadySentFlag(true)(dispatch);
    } else {
      snackBarStatus({
        payload: {
          title: error.message,
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
    return error;
  }
}
/* eslint-enable */

/* eslint-disable */
export const sendDirectMessage = (groupPublicId, isNestedThreadReply) => async (dispatch, getState) => {
  try {
    const { messageReducer } = getState();

    const data = {
      ...formatRequestData(messageReducer),
      thread: !isNestedThreadReply,
    }

    isMessageSending(true)(dispatch);

    const response = await Message.sendDirectMessage(groupPublicId, data);

    isMessageSending(false)(dispatch);
    return response;
  } catch (error) {
    console.error(error);

    isMessageSending(false)(dispatch);

    if (response.status === 200) {
      dispatch({ type: ACTIONS.CLEAR_SELECTED_TEMPLATE });
    }

    snackBarStatus({
      payload: {
        title: error && error.data ? error.message : 'Error',
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
}
/* eslint-enable */

export const sendThreadMessage = isNestedThreadReply => async (dispatch, getState) => {
  try {
    const {
      messageReducer,
    } = getState();

    const data = {
      ...formatRequestData(messageReducer),
      thread: !isNestedThreadReply,
    };

    isMessageSending(true)(dispatch);

    const response = await Message.sendMessage(data);

    isMessageSending(false)(dispatch);

    return response;
  } catch (error) {
    console.error(error);

    isMessageSending(false)(dispatch);

    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const statusWindowsMessage = values => dispatch => {
  dispatch({
    type: ACTIONS.STATUS_WINDOW_MESSAGE,
    payload: values,
  });
};

const refreshGroupsChannels = () => (dispatch, getState) => {
  const {
    messageReducer: {recipientGroups, recipientType, selectedMember},
    groupsDictionaryReducer: { list },
  } = getState();

  const recipientGroupsFromDictionary = getRecipientGroupsFromDictionary(list, recipientGroups);

  const groupsWithTwitterIds = recipientGroupsFromDictionary
    .filter(g => g.group.twitter_integrations && g.group.twitter_integrations.length > 0);

  if (groupsWithTwitterIds.length > 0) {
    getFirstAliveTwitterAccountInfo(groupsWithTwitterIds.map(g => g.group.public_id))(dispatch);
  }

  const groupsWithFacebookIds = recipientGroupsFromDictionary
    .filter(g => g.group.post_facebook && g.group.facebook_pages.length > 0);

  const fbPages = groupsWithFacebookIds.map(g => g.group.facebook_pages).flat().filter(p => p.status === 'active');

  setFacebookAccountInfo(fbPages.length ? fbPages[0] : null)(dispatch);

  const isDirectMessage = recipientType === CONSTANTS.RECIPIENT_TYPE_MEMBER;

  let channels = {
    facebook: isDirectMessage ? false : recipientGroupsFromDictionary
      .some(g => g.group.post_facebook && g.group.facebook_pages.some(fb => fb.status === 'active')),
    twitter: isDirectMessage ? false : recipientGroupsFromDictionary.some(g => g.group.post_twitter),
    mpa: isDirectMessage ? false : recipientGroupsFromDictionary.some(g => g.group.send_to_my_parish_app),
    web: isDirectMessage ? false : recipientGroupsFromDictionary.some(g => g.group.post_website),
  };

  // TODO: provide correct structure on the server
  if (isDirectMessage && selectedMember) {
    // const { isNotifyByEmail, isNotifyBySms } = getMemberNotificationPreference(selectedMember.groups, recipientGroups[0]);
    //
    // channels = {
    //   ...channels,
    //   email: isNotifyByEmail,
    //   sms: isNotifyBySms,
    // }

    channels = {
      ...channels,
      email: true,
      sms: true,
    };
  }

  updateAllowedChannels(channels)(dispatch);
};

export const updateRecipientGroups = (recipientGroupsIds, isSaveToDraft) => (dispatch, getState) => {
  const { groupsDictionaryReducer: { allMembersPublicId }, messageReducer: { isTemplate } } = getState();

  dispatch({
    type: ACTIONS.RECIPIENT_GROUPS_UPDATE,
    payload: recipientGroupsIds,
  });

  dispatch({
    type: ACTIONS.UPDATE_ALL_MEMBERS_SELECTED,
    payload: recipientGroupsIds.includes(allMembersPublicId),
  });

  refreshGroupsChannels()(dispatch, getState);
  setPreviewContent()(dispatch, getState);

  if (!isTemplate && isSaveToDraft) {
    saveDraftMessageWithDebounce()(dispatch, getState);
  }
};

export const updateSelectedMember = member => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.SELECTED_MEMBER,
    payload: member,
  });
  refreshGroupsChannels()(dispatch, getState);
};

// TODO: remove?
export const updateAutocompleteMenu = value => dispatch => {
  dispatch({
    type: ACTIONS.OPEN_MENU,
    payload: value,
  });
};

export const changeTab = value => dispatch => {
  dispatch({
    type: ACTIONS.CHANGE_TAB,
    payload: value,
  });
};

export const getTimeZone = () => async dispatch => {
  try {
    dispatch({ type: ACTIONS.GET_TIME_ZONE });

    const storedList = localStorage.getItem('timeZoneList');
    if (storedList) {
      return JSON.parse(storedList);
    } else {
      const {
        status,
        data: { data },
      } = await Message.getTimeZone();
      if (status === 200) {
        localStorage.setItem('timeZoneList', JSON.stringify(data));
      }
      return data;
    }
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

/**
 * Changes the display status of the dialog to cancel the scheduled message.
 * @param {boolean} cancelScheduleDialogStatus
 */
export function handleDialogStatus(cancelScheduleDialogStatus) {
  return function (dispatch) {
    dispatch({
      type: ACTIONS.HANDLE_DIALOG_STATUS,
      payload: cancelScheduleDialogStatus,
    });
  };
}

/**
 * Executes the action to cancel the scheduled message. If the action was successfull,
 * it will return the new message object. If the status is 400 it will return false.
 * If the status is neither of them it will consider it an error and throw an error.
 * @param {Object} messageObject All info related to message. Without the elastic search
 * overhead.
 * @param {boolean} fail (Temporary for testing)
 */

export const cancelScheduledMessage = messagePublicId => async (dispatch, getState) => {
  try {
    const { organizationReducer: { organization: { public_id: orgId } } } = getState();
    const { status, data } = await Message.cancelScheduledMessage(messagePublicId, orgId);

    if (status === 200) {
      cancelScheduledMessageInList(messagePublicId)(dispatch);
      return data.data;
    } else {
      return null;
    }

  } catch (e) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
  }
};

/**
 * Performs a request to the API and if HTTP status code is 200 OK
 * it returns true and if returns 400 it will return false.
 * If the response status is neither of them the function will
 * throw.
 * @param {Object} messageObject
 */

export const canCancelScheduledMessage = messagePublicId => async (dispatch, getState) => {
  try {
    const { organizationReducer: { organization: { public_id: orgId } } } = getState();
    const { status } = await Message.canCancelScheduledMessage(messagePublicId, orgId);

    return status === 200;
  } catch (e) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
  }
};

/**
 * Changes the display status of the confirmation dialog
 * This is required to avoid status dialog overlapping. (Design)
 * @param {Boolean} status
 */
export function changeConfirmCancelStatus(status) {
  return function (dispatch) {
    return dispatch({
      type: ACTIONS.CHANGE_CONFIRM_DIALOG_STATUS,
      payload: status,
    });
  };
}

/**
 * Gets the url of an image from a base64
 */
export const getUrlFromBase = base64 => async dispatch => {
  try {
    dispatch({
      type: ACTIONS.CROPPING_IMAGE,
      payload: true,
    });
    const response = await Message.getImageAsync(base64);
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.GET_URL_FROM_BASE,
        payload: response.data.data.cropped_img,
      });
      dispatch({
        type: ACTIONS.CROPPING_IMAGE,
        payload: false,
      });
    }
    return response.data.data.cropped_img;
  } catch (error) {
    console.error(error);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
  }
};

const ensureCorrectOrganization = messageOrgPublicId => async (dispatch, getState) => {
  const { organizationReducer: { organization: { public_id: currentOrgId } } } = getState();
  if (messageOrgPublicId !== currentOrgId) {
    const organization = await getOrganization(messageOrgPublicId)(dispatch);
    selectOrganization(organization)(dispatch);
    window.location.reload();
  }
};

export const getMessage = publicId => async (dispatch, getState) => {
  try {
    isMessageLoading(true)(dispatch);
    const response = await Message.getMessage(publicId);

    if (response.status === 200) {

      const message = camelCaseObjects(response.data.data);

      ensureCorrectOrganization(message.organization.publicId)(dispatch, getState);

      // Prevent changing case for a design object.
      message.messageContent.editorContent.design = { ...response.data.data.messageContent.editorContent.design };

      selectMessage(message)(dispatch);

      updateRecipientGroups(message.recipientGroups)(dispatch, getState);  // TODO: remove

      // TODO: refactoring
      if (message.sendToMembers && message.sendToMembers[0]) {

        const sendToMember = message.sendToMembers[0].member;

        updateSelectedMember(sendToMember)(dispatch, getState);
      } else {
        updateSelectedMember(null)(dispatch, getState);
      }

      refreshRecipientType()(dispatch, getState);

    }
    isMessageLoading(false)(dispatch);
    return response.data;
  } catch (error) {
    if (error.message !== REQUEST_CANCELLED_MESSAGE) {
      snackBarStatus({
        payload: {
          title: 'Message not found',
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }

    isMessageLoading(false)(dispatch);


    return error;
  }
};

// TODO: remove after refactoring.
export const updateSelectedMemberProps = value => dispatch => {
  dispatch({ type: ACTIONS.UPDATE_SELECTED_MEMBER_PROPS, payload: value });
};

export const initForwardedMessage = sourceMessage => (dispatch, getState) => {
  const { organizationReducer: { organization: { lite: isLiteMode } } } = getState();

  const { messageContent, subject, files } = sourceMessage;

  setMessage({ messageContent, subject, files })(dispatch, getState);

  if (isLiteMode) {
    initLiteModeMessage(dispatch);
  }

  saveDraftMessage()(dispatch, getState);
};

export const initResendUnopenedMessage = sourceMessage => (dispatch, getState) => {
  const { messageContent, recipientGroups, subject, sendToMembers, sentByMember, files } = sourceMessage;

  setMessage({ messageContent, recipientGroups, subject, sendToMembers, sentByMember, files })(dispatch, getState);

  dispatch({
    type: ACTIONS.SET_RESEND_UNOPENED,
    payload: true,
  });

  updateChannelsToResendUnopened()(dispatch);
};

export const initDraftMessage = message => (dispatch, getState) =>
  setMessage({ ...message, replyToMessage: null })(dispatch, getState);

// TODO: use member id instead
export const initDirectMessage = (userPublicId, groupPublicId) => async (dispatch, getState) => {
  const { organizationReducer: { organization: { public_id: organizationPublicId } } } = getState();

  const response = await Member.getProfileInformation(userPublicId, organizationPublicId);

  if (response.data.code === 200) {
    const { 
      firstName, groups, lastName,
      phoneNumber, profilePictureUrl, publicId,
    } = camelCaseObjects(response.data.data);
    
    updateRecipientGroups([groupPublicId])(dispatch, getState);
    updateSelectedMember({ firstName, groups, lastName, phoneNumber, profilePictureUrl, publicId })(dispatch, getState);
    refreshRecipientType()(dispatch, getState);

    const { isNotifyByEmail, isNotifyBySms } = getMemberNotificationPreference(groups, groupPublicId);

    updateAllowedChannels({ email: isNotifyByEmail, sms: isNotifyBySms })(dispatch);
  }
};

// Means message already in the store and we don't need to set it again
export const isMessageSending = bool => dispatch => {
  dispatch({ type: ACTIONS.LOADING_MESSAGE_SENDING, payload: bool });
};

// Means message already in the store and we don't need to set it again
export const isMessageLoading = bool => dispatch => {
  dispatch({ type: ACTIONS.LOADING_MESSAGE, payload: bool });
};

export const setEditorType = type => dispatch => {
  dispatch({ type: ACTIONS.SET_EDITOR_TYPE, payload: type });
};

export const formatMessageToDesigner = () => (dispatch, getState) => {
  const {
    messageReducer: { messageContent: { editorContent: { html } } },
  } = getState();

  dispatch({ type: ACTIONS.FORMAT_TO_DESIGNER, payload: composerToDesign(html) });
};

export const validateMessage = () => (dispatch, getState) => {
  const {
    messageReducer: {
      isResendUnopened,
      messageContent: { mainPicture, editorContent: { text } },
      messageSettings: { deliveryTime, emailForward, isScheduledMessage, timeZone },
      recipientGroups,
      selectedMember,
      sendByEmail, sendByFacebook, sendByMpa, sendBySms, sendByTwitter, sendByWeb,
      subject,
      // sender_name,
    },
  } = getState();

  const errorMessages = [];
  const validationErrors = {};

  if (!selectedMember && (!isResendUnopened && recipientGroups.length === 0)) {
    errorMessages.push('A group is required.');
    validationErrors.recipient = true;
  }

  if (subject === '') {
    errorMessages.push('A subject is required.');
    validationErrors.subject = true;
  }

  if (subject === 'Draft Message') {
    errorMessages.push('Draft Message is not valid. Please enter a new subject.');
    validationErrors.subject = true;
  }

  if ((!text || text.trim() === '') && !mainPicture) {
    errorMessages.push('Message content is required.');
    validationErrors.content = true;
  }

  if (emailForward && !emailRegex.test(emailForward)) {
    errorMessages.push('Forward replies email is not valid.');
    validationErrors.emailForward = true;
  }

  if (isScheduledMessage && !timeZone) {
    errorMessages.push('You must select the time zone of your preference');
  }

  if (isScheduledMessage && !deliveryTime) {
    errorMessages.push('You must select a scheduling date');
  }

  if (!sendByEmail && !sendByFacebook && !sendByMpa && !sendBySms && !sendByTwitter && !sendByWeb) {
    errorMessages.push('No communication channels selected.');
    validationErrors.channels = true;
  }

  if (isScheduledMessage && moment(deliveryTime).isBefore(moment(), 'day')) {
    errorMessages.push('The Schedule date cant be before the current date');
  }

  if (errorMessages.length > 0) {
    snackBarStatus({
      payload: {
        title: errorMessages,
        enable: true,
        type: 'formError',
      },
    })(dispatch);
  }

  dispatch({ type: ACTIONS.VALIDATE, payload: validationErrors });

  return errorMessages.length === 0;
};

export const resetMessageValidation = () => dispatch => {
  dispatch({ type: ACTIONS.VALIDATION_RESET });
};

export const resetScheduledStatus = () => dispatch => {
  dispatch({ type: ACTIONS.RESET_SCHEDULED_STATUS });
};

export const releaseMessageLockedMember = () => async (dispatch, getState) => {
  try {
    const { messagesReducer: { selectedMessage } } = getState();
    if (selectedMessage && selectedMessage.publicId) {
      const response = await Message.releaseMessageLockedMember(selectedMessage.publicId);

      return response;
    }    
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};
