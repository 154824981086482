import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import Input from '../../Components/Input';
import ImageGalleryList from '../../Components/imageGalleryList';
import {
  createFolder,
  displayGallery,
  getFolderImage,
  getFoldersContent,
  moveImagesToFolder,
  removeImage,
  updateGallery,
  updateSelectedFolder,
  updateSelectedImage,
} from '../../Actions/imageGalleryActions';
import ButtonStandard from '../../Components/Button';
import GallerySort from '../../Components/GallerySort';
import styles from './styles';
import EvaTooltip from '../../Components/EvaTooltip';
import './index.sass';
import { debounce } from '../../Helpers/debounce';
import { SEARCH_IN_GALLERY_DELAY } from '../../Config/Constants';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';
import { Select } from '@mobiscroll/react';

class ImageGallery extends Component {
  state = {
    imageSelected: null,
    displayCreateFolder: false,
    folderName: 'My Images',
    sortOrder: 'asc',
    moveSelectedFolder: '',
    isMoveFolder: false,
    isGlobalFolder: false,
    showConfirm: false,
    imagesRemoved: false,
    deletedCount: 0,
    fullImagesList: [],
    searchTerm: '',
  };

  constructor(props) {
    super(props);

    this.callSearchWithDebounce = debounce(this.handleSearch, SEARCH_IN_GALLERY_DELAY);
  }

  componentDidMount() {
    const { folder, selectedFolder, galleryImages } = this.props;
    if (folder && folder.length > 0 && selectedFolder === '') {
      folder.forEach(folderCurrent => {
        if (folderCurrent.name === 'My Images') {
          this.props.updateSelectedFolder(folderCurrent.publicId);
          this.props.getFolderImage(folderCurrent.publicId, 1);
        }
      });
    }
    this.uncheckAllImages();
    if (galleryImages) {
      this.setState({ fullImagesList: galleryImages });
    }
  }

  componentDidUpdate(previousProps, previousState) {
    const { folder, selectedFolder, galleryImages, selectedImage } = this.props;
    const { fullImagesList } = this.state;

    if (previousProps.selectedFolder !== selectedFolder) {
      this.setState({ searchTerm: '' });
    }

    if (previousProps.folder !== this.props.folder) {
      if (folder && folder.length > 0 && selectedFolder === '') {
        folder.forEach(folderCurrent => {
          if (
            folderCurrent.name === 'My Images' &&
            previousState.selectedFolder !== folderCurrent.publicId &&
            previousState.selectedFolder !== null
          ) {
            this.props.updateSelectedFolder(folderCurrent.publicId, folderCurrent.global);
            this.props.getFolderImage(folderCurrent.publicId);
          }
        });
      }
    }
    if (previousProps.galleryImages !== galleryImages) {
      let updatedFullImagesList = [...fullImagesList];
      galleryImages.forEach(item => {
        const image = fullImagesList.find(img => img.publicId === item.publicId);
        if (!image) {
          updatedFullImagesList = updatedFullImagesList.concat(item);
        }
      });

      this.setState({ fullImagesList: updatedFullImagesList });

      galleryImages.map(item =>
        selectedImage.find(img => img === item.publicId) ?
          {
            ...item,
            check: true,
          } : item);

      this.props.updateGallery(galleryImages);
      const imageToInsert = this.state.fullImagesList.find(img => img.publicId === selectedImage[0]);
      if (imageToInsert) {
        this.handleSelect(imageToInsert.publicId);
      } else {
        this.handleSelect(null);
      }
    }
  }

  componentWillUnmount() {
    const { selectedFolder } = this.props;
    this.props.updateSelectedImage([]);
    this.props.getFolderImage(selectedFolder, 1);
  }

  /* eslint-disable */
  getFolderOptions = foldersArray =>
    Array.isArray(foldersArray)
      ? foldersArray.map(folder => ({ text: folder.name, value: folder.publicId }))
      : [];
  /* eslint-enable */

  handleChange = ({ value }) => {
    const { folder } = this.props;

    const currentFolder = folder.find(item => item.publicId === value);

    if (currentFolder) {
      if (currentFolder.global) {
        this.setState({ isGlobalFolder: true });
      } else {
        this.setState({ isGlobalFolder: false });
      }
      this.props.getFolderImage(value, 1);
      this.props.updateSelectedFolder(value, currentFolder.global);
      this.props.updateSelectedImage([]);
    }
  };

  handleFolder = ({ value }) => this.setState({ moveSelectedFolder: value });

  handleSelect = publicId => this.setState({ imageSelected: publicId });

  handleFolderName = e => {
    e.preventDefault();
    this.setState({ folderName: e.target.value });
  };

  createFolder = () => {
    const { displayCreateFolder } = this.state;
    this.setState({ displayCreateFolder: !displayCreateFolder });
  };

  handleGetPages = displacement => {
    const { selectedFolder } = this.props;
    const { nextPage, previousPage } = this.props;
    if (displacement === 'right' && nextPage) {
      this.props.getFolderImage(selectedFolder, nextPage);
    }
    if (displacement === 'left' && previousPage) {
      this.props.getFolderImage(selectedFolder, previousPage);
    }
  };

  handleDotClick = pageNumber => {
    const { selectedFolder, currentPage } = this.props;
    if (currentPage !== pageNumber) {
      this.props.getFolderImage(selectedFolder, pageNumber);
    }
  };

  uploadImage = () => {
    const { folderName, displayCreateFolder } = this.state;
    const { selectedFolder } = this.props;
    const folderValidation = displayCreateFolder ? 'newFolder' : selectedFolder;
    if (folderName !== '') {
      this.props.createFolder(folderName, folderValidation).then(createFolderResponse => {
        if (createFolderResponse === 200) {
          this.props.getFoldersContent();
          this.setState({ displayCreateFolder: false });
        }
      });
    }
    this.setState({
      folderName: '',
    });
  };

  addImage = () => {
    const {
      messageContent: { editorContent: { html } },
      isBackButton,
      selectedImage,
      selectedFolder,
      onChange,
    } = this.props;

    let { fullImagesList } = this.state;
    const selectedImageId = selectedImage[0];
    const imageToInsert = fullImagesList.find(img => img.publicId === selectedImageId);
    this.handleSelect(imageToInsert.publicId);

    const selectedImageUrl = imageToInsert.source.replace(/ /g, '%20');
    this.props.displayGallery(false);
    this.props.updateSelectedImage([]);
    this.props.getFolderImage(selectedFolder, 1);
    onChange(selectedImageUrl);
  };

  handleSort = sort => {
    const { selectedFolder } = this.props;
    let sortImage = '';
    let recentImage = '';
    if (sort !== 'sort') {
      if (sort === 'asc' || sort === 'desc') {
        sortImage = sort;
      } else if (sort === 'newest') {
        recentImage = 'updatedAt';
        sortImage = 'desc';
      } else if (sort === 'oldest') {
        recentImage = 'updatedAt';
        sortImage = 'asc';
      }
      this.setState({ sortOrder: sort });
      this.props.getFolderImage(selectedFolder, 1, sortImage, '', recentImage);
    }
  };

  uncheckAllImages = () => this.props.updateSelectedImage([]);

  handleCheck = (image, key) => {
    const { selectedImage, galleryImages } = this.props;
    const imageExist = selectedImage.find(img => img === image);
    if (imageExist) {
      const newImages = selectedImage.filter(img => img !== image);
      galleryImages[key].check = false;
      if (newImages.length > 1) {
        const imageToinsert = galleryImages.find(img => img.publicId === newImages[0]);
        if (imageToinsert) {
          this.handleSelect(imageToinsert.publicId);
        } else {
          this.handleSelect(null);
        }
      } else {
        this.handleSelect(null);
      }
      this.props.updateSelectedImage(newImages);
    } else {
      const img = galleryImages[key];
      galleryImages[key].check = true;
      selectedImage.push(image);
      this.props.updateSelectedImage(selectedImage);
      this.handleSelect(img.publicId);
    }
    this.props.updateGallery(galleryImages);
    setTimeout(() => {
      if (this.props.selectedImage.length === 0) {
        this.setState({ isMoveFolder: false });
      }
    }, 100);
  };

  handleRemove = () => {
    const { selectedImage, selectedFolder } = this.props;
    this.props.removeImage(selectedImage).then(res => {
      if (res.status === 200) {
        this.props.getFolderImage(selectedFolder, 1);
        this.setState({
          showConfirm: false,
          imagesRemoved: true,
          deletedCount: res.data.data.deletedImagesSuccessfullyCount,
        });
        this.props.updateSelectedImage([]);
      }
    });
  };

  changeMoveFolder = () => {
    const { isMoveFolder } = this.state;
    if (isMoveFolder) {
      this.setState({ isMoveFolder: false });
    } else {
      this.setState({ isMoveFolder: true });
    }
  };

  handleMove = () => {
    const { selectedImage, selectedFolder } = this.props;
    const { moveSelectedFolder } = this.state;
    const self = this;
    if (moveSelectedFolder === '') {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'Select destination folder',
          type: 'error',
        },
      });
    } else {
      this.props.moveImagesToFolder(selectedFolder, selectedImage, moveSelectedFolder).then(res => {
        if (res.status === 200) {
          this.props.getFolderImage(selectedFolder, 1);
          this.props.updateSelectedImage([]);
          self.setState({ isMoveFolder: false });
        }
      });
    }
  };

  handleSearch = term => {
    const { selectedFolder } = this.props;
    this.props.getFolderImage(selectedFolder, 1, '', term);
  };

  handleConfirm = condition => {
    if (condition) {
      this.handleRemove();
    } else {
      this.setState({ showConfirm: false });
    }
  };

  handleMessageRemove = showConfirm => {
    this.setState({
      isMoveFolder: false,
      showConfirm,
      imagesRemoved: false,
    });
  };

  handleClose = () => {
    this.props.updateSelectedImage([]);
    this.setState({ imagesRemoved: false, deletedCount: 0 });
  };

  renderPaginationDots = () => {
    const { totalPages, currentPage } = this.props;
    const dotsPerGroup = 5;
    const currentGroup = Math.floor((currentPage - 1) / dotsPerGroup);
    const startPage = currentGroup * dotsPerGroup + 1;
    const endPage = Math.min(startPage + dotsPerGroup - 1, totalPages);
    const dots = [];
  
    for (let i = startPage; i <= endPage; i++) {
      dots.push(
        <button
          className={`pagination-dot ${currentPage === i ? 'active' : ''}`}
          key={'dot-' + i}
          onClick={() => this.handleDotClick(i)}
        />
      );
    }
  
    return dots;
  }

  /* eslint-disable */
  render() {
    const {
      folder,
      galleryImages,
      selectedImage,
      isAdmin,
      selectedFolder,
      currentPage,
      globalFolder,
      classes,
    } = this.props;
    const {
      displayCreateFolder,
      folderName,
      moveSelectedFolder,
      isMoveFolder,
      showConfirm,
      imagesRemoved,
      deletedCount,
      searchTerm,
    } = this.state;
    const hasImages = galleryImages && galleryImages.length > 0;
    let statusButton = false;
    if (selectedImage.length == 1) {
      if (isMoveFolder) {
        statusButton = false;
      } else {
        statusButton = true;
      }
    } else {
      statusButton = false;
    }

    const folderOptions = Array.isArray(folder) ? this.getFolderOptions(folder) : [];

    return (
      <Grid container id="image-gallery-container">
        {imagesRemoved && (
          <Grid container spacing={0} className="message-container">
            <Grid item xs={11} className="title">
              {`${deletedCount} image${
                deletedCount > 1 ? '(s)' : ''
              } have been successfully removed from everything. `}
            </Grid>
            <Grid item xs={1} className="icon-container" onClick={() => this.handleClose()}>
              <i className="close"/>
            </Grid>
          </Grid>
        )}
        {showConfirm && (
          <Grid container spacing={0} className="confirm-message-container">
            <Grid item xs={10} className="title">
              Are you sure you want to remove these images from your gallery and ALL MESSAGES?
              The image will no longer be available to view by anyone.
            </Grid>
            <Grid item xs={1} className="yes" onClick={() => this.handleConfirm(true)}>
              YES
            </Grid>
            <Grid item xs={1} className="no" onClick={() => this.handleConfirm(false)}>
              NO
            </Grid>
          </Grid>
        )}
        <Grid container className="image-folder-picker">
          <Grid item xs={11} className={classes.customSelect}>
            {!displayCreateFolder && (
              <Select
                data={folderOptions}
                value={selectedFolder}
                onChange={this.handleChange}
                responsive={mbscResponsiveOptions}
                placeholder="Select folder"
                filterPlaceholderText="Search"
                buttons={['cancel']}
              />
            )}
          </Grid>
          <Grid item xs={1} className="new-folder-container">
            <EvaTooltip title="New Folder">
              <div className="new-folder-container" onClick={this.createFolder}>
                <i className={`${displayCreateFolder ? 'new-folder-active' : ''} new-folder`}/>
              </div>
            </EvaTooltip>
          </Grid>
        </Grid>
        {displayCreateFolder && (
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Input
              type="text"
              name="newFolder"
              value={folderName}
              placeholder="New Folder"
              onChange={this.handleFolderName}
            />
            <button
              type="button"
              className="styless-button button-eva button-link-gallery"
              onClick={this.uploadImage}
            >
              Save
            </button>
          </Grid>
        )}
        {selectedFolder && !displayCreateFolder && (
          <React.Fragment>
            <GallerySort
              selectedImage={selectedImage}
              searchTerm={searchTerm}
              onSort={this.handleSort}
              onMessageRemove={this.handleMessageRemove}
              handleFolder={this.handleFolder}
              selectedFolder={selectedFolder}
              folder={folder}
              moveSelectedFolder={moveSelectedFolder}
              isMoveFolder={isMoveFolder}
              changeMoveFolder={this.changeMoveFolder}
              handleMove={this.handleMove}
              handleSearch={term => {this.setState({ searchTerm: term }, this.callSearchWithDebounce(term))}}
              isGlobalFolder={globalFolder}
              isAdmin={isAdmin}
            />
            {hasImages && (
              <ImageGalleryList images={galleryImages} handleCheck={this.handleCheck}/>
            )}
          </React.Fragment>
        )}
        {!displayCreateFolder &&
        galleryImages &&
        galleryImages.length > 0 && (
          <Grid item xs={12} className="images-gallery-show" id="images-gallery-show">
            {(galleryImages.length === 10 || currentPage > 1) && (
              <div className="image-arrows">
                <IconButton onClick={() => this.handleGetPages('left')}>
                  <KeyboardArrowLeft/>
                </IconButton>

                {this.renderPaginationDots()}

                <IconButton onClick={() => this.handleGetPages('right')}>
                  <KeyboardArrowRight/>
                </IconButton>
              </div>
            )}
            <div id="button-send-message">
              <ButtonStandard
                onClick={this.addImage}
                title="Insert image"
                className={`${!statusButton ? 'disable' : 'enable'}`}
                disabled={!statusButton}
              />
            </div>
          </Grid>
        )}
      </Grid>
    );
  }

  /* eslint-enable */
}

const mD = {
  getFolderImage,
  displayGallery,
  createFolder,
  getFoldersContent,
  updateSelectedImage,
  updateGallery,
  removeImage,
  moveImagesToFolder,
  updateSelectedFolder,
};

const mS = state => ({
  messageContent: state.messageReducer.messageContent,

  folder: state.imageGalleryReducer.folder,
  galleryImages: state.imageGalleryReducer.galleryImages,
  nextPage: state.imageGalleryReducer.nextPage,
  previousPage: state.imageGalleryReducer.previousPage,
  totalPages: state.imageGalleryReducer.totalPages,
  currentPage: state.imageGalleryReducer.currentPage,
  selectedImage: state.imageGalleryReducer.selectedImage,
  selectedFolder: state.imageGalleryReducer.selectedFolder,
  globalFolder: state.imageGalleryReducer.globalFolder,

  isAdmin: state.userProfileReducer.isAdmin,
});

export default withStyles(styles)(
  connect(
    mS,
    mD,
  )(ImageGallery),
);
