import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updatePassword, resetPassword, clearPassword } from '../../Actions/passwordActions';
import ButtonStandard from '../Button';
import ErrorMessage from '../ErrorMessage';
import Helpers from '../../Helpers';
import Input from '../Input';
import ChangePasswordPropTypes from '../../PropTypes/ChangePasswordPropType';
import './index.sass';

class ChangePassword extends Component {
  state = {
    uppercase: false,
    lowercase: false,
    length: false,
    specialCharacter: false,
    number: false,
    focus: '',
  };

  handleChange = (e, target) => {
    const { value } = e.target;
    const keys = Object.keys(this.state);
    keys.forEach(term => {
      const newState = {};
      newState[term] = Helpers.PasswordCheck(term, value);
      this.setState({ ...newState });
    });
    const payload = {};
    payload[target] = value;
    this.props.updatePassword({ payload });
  };

  requestedParams = target => {
    if (this.state[target]) return 'active';
    return null;
  };

  submit = async e => {
    e.preventDefault();
    const { first, second, history, phone } = this.props;
    const { token } = this.props.match.params;
    if (Helpers.ValidatePassword({ ...this.state, first, second })) {
      const payload = {
        first,
        second,
      };
      this.props.resetPassword(token, payload, phone).then(response => {
        if (response) {
          history.push('/login');
        }
      });
    } else {
      this.props.updatePassword({
        payload: {
          error: 'Passwords doesn\'t have required character or doesn\'t match',
        },
      });
    }
  };

  handleFocus = () => {
    const focus = 'input-eva-focused';
    this.setState({ focus });
  };

  render() {
    const { focus } = this.state;
    return (
      <div id="change-password" className="container-center change-password">
        <div className="login__title">
          <p className="logo-evangelus">EVANGELUS</p>
        </div>
        <h1 className="title-forgot-password">Change Password</h1>
        <p className="content-forgot-password">Please enter a new password.</p>
        {this.props.error ? (
          <ErrorMessage error={this.props.error} hideMessage={this.props.clearPassword} />
        ) : null}

        <form className="login__form">
          <div onFocus={() => this.handleFocus()}>
            <Input
              type="password"
              title="new password"
              icon="password"
              focused={focus}
              placeholder="New Password"
              value={this.props.first}
              onChange={e => this.handleChange(e, 'firstPassword')}
            />
            <Input
              type="password"
              title="confirm password"
              icon="password"
              focused={focus}
              placeholder="Confirm Password"
              value={this.props.second}
              onChange={e => this.handleChange(e, 'secondPassword')}
            />
          </div>
          <div className="password-requires">
            <span className="title-medium">Your password requires at least:</span>
            <ul className="list-password-requires">
              <li className={this.requestedParams('lowercase')}>One lowercase letter</li>
              <li className={this.requestedParams('uppercase')}>One Uppercase letter</li>
              <li className={this.requestedParams('number')}>One number</li>
              <li className={this.requestedParams('specialCharacter')}>One special character</li>
              <li className={this.requestedParams('length')}>Minimum 8 character length</li>
            </ul>
          </div>
          <div className="button-container button-container-change-pass">
            <ButtonStandard title="Confirm" subtitle="and login" onClick={this.submit} />
          </div>
        </form>
      </div>
    );
  }
}

ChangePassword.propTypes = ChangePasswordPropTypes;

const mS = state => ({
  first: state.passwordReducer.firstPassword,
  second: state.passwordReducer.secondPassword,
  error: state.passwordReducer.error,
  phone: state.passwordReducer.phone,
});

const mD = {
  updatePassword,
  resetPassword,
  clearPassword,
};
export default connect(
  mS,
  mD,
)(ChangePassword);
