import Groups from '../Api/Groups';
import Facebook from '../Api/Facebook';
import snackBarStatus from './snackbarActions';
import { ACTIONS as memberActions } from './memberActions';

import { addGroupToDictionary, updateGroupFromDictionary } from './groupsDictionaryActions';

export const ACTIONS = {
  ADD: '[Groups] Add',
  ADD_SUBGROUP: '[Groups] Add subgroup',
  CLEARFORM: '[Groups] Clear form',
  CLEAR_ACTUAL_GROUPS: '[Groups] Clear actual groups',
  CLEAR_FORM_GROUP: '[Groups] Clear form group',
  CLEAR_SEARCHED_GROUPS: '[Groups] Clear searched groups',
  CREATE_SETTINGS_GROUP: '[Groups] Create settings group',
  ERROR: '[Groups] Error',
  GET_GROUPS: '[Groups] Get groups',
  LOADING_GROUPS: '[Groups] Loading groups',
  REMOVE_MEMBER: '[Groups] Remove member',
  SEARCH_MESSAGES: '[Groups] Search',
  SELECT: '[Groups] Select',
  SET_HTTP_CODE: '[Groups] Set http code',
  SHOW_MEMBERS_SEARCH_SETTINGS: '[Groups] Show search member settings',
  SHOW_MEMBERS_SETTINGS_SEARCH: '[Groups] Show search member settings',
  SUCCESS: '[Groups] Success',
  UPDATE: '[Groups] Update',
  LOAD_NEW_GROUPS: '[Groups] Load new groups',
  LOAD_MORE_GROUPS: '[Groups] Load more groups',
  UPDATE_ALL: '[Groups] Add all',
  UPDATE_GROUP_FROM_LIST: '[Groups] Update group from list',
  UPDATE_PAGINATED: '[Groups] Get paginated',
  INIT_SELECTED_GROUP: '[Groups] Init selected group',
  UPDATE_SELECTED_GROUP: '[Groups] Update selected group',
  UPDATE_MEMBER_IN_GROUP: '[Groups] Update member in group',
  UPDATE_MEMBER_IN_SUBGROUP: '[Groups] Update member in subgroup',
  UPDATE_SUBGROUP_AND_GROUP: '[Groups] Update subgroup and group',
  UPDATE_GROUP_STATUS: '[Group] Update group status',
  UPDATE_GROUP_MEMBER_PREFERENCE: '[Group] Update group member preference',
  MIDDLE_MENU_STATUS: '[Groups] Middle menu status',
  SEND_KEYWORD: '[Groups] Send keyword',
  SET_KEYWORD_VALIDATE: '[Groups] Set keyword validate',
  SET_KEYWORD_ERROR: '[Groups] Set keyword error',
  KEYWORD_STATUS_REQUEST: '[Groups] Keyword status request',
  SET_AVAILABLE_KEYWORD: '[Groups] Set available keyword',
  MIDDLE_MENU_LEFT: '[Groups] Middle menu left',
  GET_ACTIVITY_GROUPS: '[Groups] Get activity groups',
  // GET_STATISTICS: '[Groups] Get statistics',
  GET_STATISTICS_METRICS: '[Groups] Get statistics metrics',
  CHANGE_STATUS: '[Groups] Change status',
  REMOVE_MEMBER_ORGANIZATION: '[Groups] Remove member organization',
  REMOVE_GROUP: '[Groups] Remove group',
  CLEAR_SELECTED_GROUP: '[Groups] Clear selected group',
  LOADING_NEW_GROUPS: '[Groups] New groups loading',
  LOADING_MORE_GROUPS: '[Groups] More groups loading',
  UPDATE_NOTIFICATION_PREFERENCES: '[Groups] Update notification preferences',
  UPDATE_ORG_NOTIFICATION_PREFERENCES: '[Groups] Update organization notification preferences',
  REMOVE_SELECTED_GROUP_MEMBERS: '[Groups] Remove selected group members quantity and previews',
  REMOVE_ALL_GROUP_MEMBERS: '[Groups] Remove all group members quantity and previews',
  UPDATE_SELECTED_GROUP_FB_PAGES: '[Groups] Update selected group facebook pages',
  REMOVE_FB_CONNECTIONS: '[Groups] Remove facebook connections for all groups',
  INCREASE_MEMBERS_COUNTERS: '[Groups] Update members counters (after adding via browse existing)',
  LOADING_SETTINGS: '[Group] Loading settings',
};

const token = localStorage.getItem('token');

export const addGroup = (value, type, groups, subGroups) => dispatch => {
  if (type === 'group' && !groups) {
    dispatch({
      type: ACTIONS.ADD,
      payload: { ...value, members_quantity: 1, subgroups_quantity: 0 },
    });
  } else if (subGroups) {
    dispatch({
      type: ACTIONS.UPDATE_SUBGROUP_AND_GROUP,
      payload: {
        ...value,
        subgroups_quantity: groups.subgroups_quantity + 1,
      },
    });
  } else {
    dispatch({
      type: ACTIONS.ADD_SUBGROUP,
      payload: { ...value, members_quantity: 1 },
    });
  }
};

export const clearFormGroup = () => dispatch => dispatch({ type: ACTIONS.CLEAR_FORM_GROUP });

export const clearGroups = () => dispatch => dispatch({ type: ACTIONS.CLEARFORM });

export const clearMessage = () => dispatch => dispatch({ type: ACTIONS.SUCCESS, payload: '' });

export const clearSearchedGroups = () => dispatch => dispatch({ type: ACTIONS.CLEAR_SEARCHED_GROUPS });

export const loadingSettings = bool => dispatch => {
  dispatch({ type: ACTIONS.LOADING_SETTINGS, payload: bool });
};

/**
 * @param  {object} value - Group object
 */
export const createGroupAsync = value => async (dispatch, getState) => {
  const { organizationReducer: { organization: { public_id: orgId } } } = getState();

  try {
    const { data, status } = await Groups.create({ ...value, token }, orgId);
    let createGroupResponse = [];
    if (status === 200) {
      createGroupResponse = data.data;
      dispatch(addGroup(createGroupResponse, value.type, value.groups, value.subGroups));
    }
    const {
      public_id, name, short_name, senderName, facebook_pages, twitter_integrations,
      post_twitter, post_website, post_facebook, send_to_my_parish_app, connect_to_my_parish_app,
      organization: { public_id: orgPublicId, id },
    } = createGroupResponse;
    addGroupToDictionary({
      publicId: public_id,
      name,
      value: public_id,
      label: name,
      group: {
        organization_general_group: false,
        post_twitter,
        post_website,
        post_facebook,
        send_to_my_parish_app,
        connect_to_my_parish_app,
        public_id,
        name,
        short_name,
        facebook_pages,
        twitter_integrations,
        group_parent: null,
        organization: {
          id,
          public_id: orgPublicId,
        },
        groupParent: null,
        organizationGeneralGroup: false,
      },
    })(dispatch);
    return createGroupResponse;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

const updateAllGroups = values => dispatch => {
  dispatch({ type: ACTIONS.UPDATE_ALL, ...values });
};

export const getAllGroupAsync = () => async dispatch => {
  try {
    const response = await Groups.getAll();
    if (response.status === 200) {
      const myGroups = response.data.data.my_groups;
      const dioceseGroups = response.data.data.available_groups.diocese_groups;
      const parishGroups = response.data.data.available_groups.parish_groups;
      dispatch(
        updateAllGroups({
          payload: { myGroups, dioceseGroups, parishGroups },
        }),
      );
    }
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
  }
};

export const getMyGroupAsync = () => async dispatch => {
  try {
    const response = await Groups.getAllMyProfile();
    if (response.status === 200) {
      const myGroups = response.data.data.my_groups;
      const availableGroups = response.data.data.available_groups;
      dispatch(
        updateAllGroups({
          payload: { myGroups, availableGroups },
        }),
      );
    }
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
  }
};

/**
 * @param  {number} page - N° Page
 */
export const getGroupsAsync = (page) => async (dispatch, getState) => {
  const { organizationReducer: { organization: { public_id: orgId } } } = getState();

  try {
    dispatch({ type: ACTIONS.LOADING_GROUPS, payload: { loadingGroups: true } });
    const response = await Groups.get(orgId, page);

    dispatch({ type: ACTIONS.LOADING_GROUPS, payload: { loadingGroups: false } });

    if (response.status === 200) {
      const {
        data: {
          data: { data, nextPage, previousPage, totalPages, currentPage },
        },
      } = response;
      const groupsList = data;
      const pagination = {
        currentPage,
        nextPage,
        previousPage,
        totalPages,
      };
      dispatch(
        updateGroupsReducer({
          payload: { groups: groupsList, pagination },
        }),
      );
    }
    return response;
  } catch (error) {
    dispatch({ type: ACTIONS.LOADING_GROUPS, payload: { loadingGroups: false } });

    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

/**
 * @param  {object} value - Group object
 */
export const updateGroupsReducer = values => dispatch => {
  if (values.payload) {
    const { pagination } = values.payload;
    if (pagination) {
      return dispatch({
        type: ACTIONS.UPDATE_PAGINATED,
        payload: {
          groups: values.payload.groups,
          pagination: values.payload.pagination,
        },
      });
    }
  }
  return dispatch({ type: ACTIONS.UPDATE, ...values });
};


export const getAllGroups = (currentOrganizationId, withAllGroupElem = true) => async (dispatch, getState) => {
  try {
    const response = await Groups.getAllGroups(currentOrganizationId, withAllGroupElem);
    let getGroupResponse = [];
    if (response.status === 200) {
      getGroupResponse = response.data.data.data;
    }
    return getGroupResponse;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const getOrgAsync = () => async dispatch => {
  try {
    dispatch({
      type: ACTIONS.LOADING_GROUPS,
      payload: { loadingGroups: true },
    });
    const response = await Groups.getOrg();
    dispatch({
      type: ACTIONS.LOADING_GROUPS,
      payload: { loadingGroups: false },
    });
    let getOrgResponse = [];
    if (response.status === 200) {
      getOrgResponse = response.data.data;
      dispatch(updateGroupsReducer({ organizations: response.data.data }));
    }
    return getOrgResponse;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    dispatch({
      type: ACTIONS.LOADING_GROUPS,
      payload: { loadingGroups: false },
    });
    return error;
  }
};

/**
 * @param  {object} value - Group object
 */

export const removeMemberFromGroup = value => async dispatch => {
  try {
    const response = await Groups.removeMember({ ...value });
    let removeMemberResponse = [];
    if (response.status === 200) {
      removeMemberResponse = response.data;
      dispatch({ type: ACTIONS.REMOVE_MEMBER });
      // Remove from selected member
      dispatch({ type: memberActions.REMOVE_MEMBER_FROM_GROUP, payload: value.groupId });
    }
    return removeMemberResponse;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

/**
 * @param  {string} dataSearch - Search Data
 * @param  {string} currentOrganizationId - Organization publicId
 */
export const searchGroups = (dataSearch) => async (dispatch, getState) => {
  const { page } = dataSearch;

  const isKeepData = page && page > 1;
  try {
    const {
      organizationReducer: { organization: { public_id } },
      userProfileReducer: { organization_role },
    } = getState();

    dispatch({
      type: isKeepData ? ACTIONS.LOADING_MORE_GROUPS : ACTIONS.LOADING_NEW_GROUPS,
      payload: true,
    });

    const requestData = { token, dataSearch, currentOrganizationId: public_id, role: organization_role };
    const response = await Groups.searchGroups(requestData);
    let searchGroupsResponse = [];
    if (response.status === 200) {
      searchGroupsResponse = response.data.data.data;
      const groups = response.data.data.data;
      const pagination = {
        currentPage: response.data.data.currentPage,
        nextPage: response.data.data.nextPage,
        previousPage: response.data.data.previousPage,
        totalPages: response.data.data.totalPages,
      };

      const payload = {
        groups,
        pagination,
      };

      dispatch({
        type: isKeepData ? ACTIONS.LOAD_MORE_GROUPS : ACTIONS.LOAD_NEW_GROUPS,
        payload,
      });

    }
    dispatch({
      type: isKeepData ? ACTIONS.LOADING_MORE_GROUPS : ACTIONS.LOADING_NEW_GROUPS,
      payload: false,
    });
    return searchGroupsResponse;
  } catch (error) {
    dispatch({
      type: ACTIONS.SET_HTTP_CODE,
      payload: error.response ? error.response.status : undefined,
    });
    dispatch({
      type: isKeepData ? ACTIONS.LOADING_MORE_GROUPS : ACTIONS.LOADING_NEW_GROUPS,
      payload: false,
    });
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const selectGroup = value => dispatch => {
  dispatch({ type: ACTIONS.SELECT, payload: value });
};

export const saveSelectedGroup = () => async (dispatch, getState) => {
  const { groupsReducer: { selectedGroup } } = getState();

  try {
    loadingSettings(true)(dispatch);
    const response = await Groups.updateGroup(
      selectedGroup,
      selectedGroup.organization.public_id,
      selectedGroup.public_id,
    );
    let updatedGroup = {};
    if (response.status === 200) {
      updatedGroup = response.data.data;

      updateGroupFromList(updatedGroup)(dispatch);

      const {
        public_id, name, short_name, facebook_pages, twitter_integrations, organization_general_group, post_twitter,
        post_website, post_facebook, send_to_my_parish_app,
      } = updatedGroup;

      // Format group for dictionary
      updateGroupFromDictionary({
        publicId: public_id,
        name,
        value: public_id,
        label: name,
        group: {
          organization_general_group,
          post_twitter,
          post_website,
          post_facebook,
          send_to_my_parish_app,
          public_id,
          name,
          short_name,
          facebook_pages,
          twitter_integrations,
          group_parent: '',
          organizationGeneralGroup: organization_general_group,
        },
      })(dispatch);

      snackBarStatus({
        payload: {
          title: response.data.message,
          type: 'success',
          enable: true,
        },
      })(dispatch);
    }
    loadingSettings(false)(dispatch);
    return updatedGroup;
  } catch (error) {
    loadingSettings(false)(dispatch);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const updateGroupFromList = group => dispatch => {
  const {
    picture_url, default_time_zone, is_enabled, archived_messages, post_social_media_network, short_url,
    mpa_group_id, organization, composed_keyword, composedKeyword, ...rest
  } = group;
  dispatch({
    type: ACTIONS.UPDATE_GROUP_FROM_LIST,
    payload: {
      ...rest,
      composedKeyword: composed_keyword || composedKeyword, // TODO: avoid of composedKeyword
    },
  });
};

export const updateQuantityMemberInGroupOrSubGroup = (
  selectedSubGroup,
  selectedGroup,
  type,
) => dispatch => {
  if (type === 'subGroup') {
    dispatch({
      type: ACTIONS.UPDATE_MEMBER_IN_SUBGROUP,
      payload: {
        public_id: selectedSubGroup.public_id,
        members_quantity: selectedSubGroup.members_quantity + 1,
      },
    });
  } else {
    dispatch({
      type: ACTIONS.UPDATE_MEMBER_IN_GROUP,
      payload: {
        public_id: selectedGroup.public_id,
        members_quantity: selectedGroup.members_quantity + 1,
      },
    });
  }
};

export const initSelectedGroup = () => dispatch =>
  dispatch({ type: ACTIONS.INIT_SELECTED_GROUP });

export const updateSelectedGroup = (data, isPreventListUpdate) => async (dispatch, getState) => {
  const { groupsReducer: { selectedGroup } } = getState();

  if (!isPreventListUpdate) {
    updateGroupFromList({
      public_id: selectedGroup.public_id,
      ...data,
    })(dispatch);
  }

  const { name } = data;

  let objToUpd = {};

  if (data.hasOwnProperty('name')) {
    objToUpd = {
      ...objToUpd,
      name,
      label: name,
      group: { name },
    };
  }

  objToUpd = {
    publicId: selectedGroup.public_id,
    ...objToUpd,
    group: {
      ...objToUpd,
      ...data,
    },
  };

  updateGroupFromDictionary(objToUpd)(dispatch);

  return dispatch({ type: ACTIONS.UPDATE_SELECTED_GROUP, payload: data });
};

export const selectFacebookPage = value => (dispatch, getState) => {
  Facebook.setIntegration(value)
    .then(response => {
      if (response.status === 200 && response.data) {
        dispatch(
          updateSelectedGroup({
            ...value.payload,
            facebook_integrations: value.payload.facebook_integrations,
          }, getState),
        );
      }
      return response.data;
    })
    .catch(error => {
      dispatch({ type: ACTIONS.ERROR, payload: error.data.details });
    });
};

export const updateMiddleMenu = status => ({
  type: ACTIONS.MIDDLE_MENU_STATUS,
  payload: status,
});

export const toggleGroupActivation = () => async (dispatch, getState) => {
  try {
    const { groupsReducer: { selectedGroup: { name, isEnabled, public_id } } } = getState();

    loadingSettings(true)(dispatch);
    const response = await Groups.toggleGroupActivation(public_id, isEnabled ? 'disable' : 'enable');

    dispatch({ type: ACTIONS.UPDATE_GROUP_STATUS, payload: { publicId: public_id, isEnabled: !isEnabled } });

    snackBarStatus({
      payload: {
        enable: true,
        title: `Group ${name} ${isEnabled ? 'disabled' : 'enabled'} successfully `,
        type: 'success',
      },
    });

    loadingSettings(false)(dispatch);
    return response;
  } catch (error) {
    loadingSettings(false)(dispatch);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const updateMemberPreference = (preference) => async (dispatch, getState) => {
  try {
    const { groupsReducer: { selectedGroup: { public_id } } } = getState();

    loadingSettings(true)(dispatch);
    const response = await Groups.updateMemberPreference(public_id, preference);

    if (response.status === 200) {
      dispatch({ type: ACTIONS.UPDATE_GROUP_MEMBER_PREFERENCE, payload: preference });

      snackBarStatus({
        payload: {
          enable: true,
          title: response.data.message,
          type: 'success',
        },
      })(dispatch);
    }


    loadingSettings(false)(dispatch);
    return response;
  } catch (error) {
    loadingSettings(false)(dispatch);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const existsUrlShortener = url => async dispatch => {
  try {
    dispatch({
      type: ACTIONS.KEYWORD_STATUS_REQUEST,
      payload: true,
    });
    const response = await Groups.existsUrlShortener(url.toLowerCase());
    if (response.data && response.data.data) {
      dispatch({
        type: ACTIONS.SET_KEYWORD_ERROR,
        payload: response.data.data.availability,
      });
      dispatch({
        type: ACTIONS.SET_KEYWORD_VALIDATE,
        payload: response.data.data.availability,
      });
      dispatch({
        type: ACTIONS.SET_AVAILABLE_KEYWORD,
        payload: response.data.data.availability,
      });
      dispatch({
        type: ACTIONS.KEYWORD_STATUS_REQUEST,
        payload: false,
      });
    }

    if (!response.data.data.availability) {
      dispatch({
        type: ACTIONS.SET_KEYWORD_ERROR,
        payload: response.data.data.availability,
      });
      dispatch({
        type: ACTIONS.SET_AVAILABLE_KEYWORD,
        payload: response.data.data.availability,
      });
      dispatch({
        type: ACTIONS.KEYWORD_STATUS_REQUEST,
        payload: false,
      });

      snackBarStatus({
        payload: {
          title: response.data.data.message,
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }

    return response.data.data;
  } catch (error) {
    dispatch({
      type: ACTIONS.KEYWORD_STATUS_REQUEST,
      payload: false,
    });
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const clearValidateKeyword = () => ({
  type: ACTIONS.SET_KEYWORD_VALIDATE,
  payload: false,
});

export const setValidateKeyword = status => ({
  type: ACTIONS.SET_KEYWORD_VALIDATE,
  payload: status,
});

export const setKeywordError = status => ({
  type: ACTIONS.SET_KEYWORD_ERROR,
  payload: status,
});

export const changeFormValue = (change, form, field, value) => dispatch => {
  dispatch(change(form, field, value));
  return value;
};

export const allGroupsGet = () => async dispatch => {
  try {
    const response = await Groups.allGroupsGet({
      token,
    });
    let getGroupResponse = [];
    if (response.status === 200) {
      getGroupResponse = response.data.data;
    }
    return getGroupResponse;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const handleMiddleMenuLeft = status => ({
  type: ACTIONS.MIDDLE_MENU_LEFT,
  payload: status,
});

// export const getStatistics = groupId => async dispatch => {
//   try {
//     const response = await Groups.getStatistics(groupId);
//     if (response.status === 200) {
//       const groupStat = response.data.data.group;
//       const engagedSubGroups = response.data.data.subGroups.engaged;
//       const activeSubGroups = response.data.data.subGroups.active;
//       const staticSubGroups = response.data.data.subGroups.static;
//       const inactiveSubGroups = response.data.data.subGroups.inactive;
//       dispatch({
//         type: ACTIONS.GET_STATISTICS,
//         payload: {
//           groupStat,
//           engagedSubGroups,
//           activeSubGroups,
//           staticSubGroups,
//           inactiveSubGroups,
//         },
//       });
//     }
//     return response;
//   } catch (error) {
//     dispatch({ type: ACTIONS.ERROR, payload: error });
//     return error;
//   }
// };

export const getStatisticsMetrics = (
  periodTypeSelected,
  groupId,
  datesFilter = null,
) => async dispatch => {
  const response = await Groups.getStatisticsMetrics({ periodTypeSelected, groupId, datesFilter });
  let statisticsMetrics = [];
  if (response.status === 200) {
    statisticsMetrics = response.data.data;
    dispatch({
      type: ACTIONS.GET_STATISTICS_METRICS,
      payload: {
        statisticsMetrics,
      },
    });
  }
  return response;
};

export const getGroupsActivity = (org, role) => async dispatch => {
  try {
    const response = await Groups.getGroupsActivity(org, role);
    if (response.status === 200) {
      dispatch({ type: ACTIONS.GET_ACTIVITY_GROUPS, payload: response.data.data });
    }
    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const changeStatusButton = status => ({
  type: ACTIONS.CHANGE_STATUS,
  ...status,
});

export const getNotificationPreferences = groupsPublicIds => async dispatch => {
  try {
    const { data: { data }, status } = await Groups.getNotificationPreferences(groupsPublicIds);
    if (status === 200) {
      const preferences = {
        smsPreferences: data.sms,
        emailPreferences: data.email,
      };
      dispatch({ type: ACTIONS.CHANGE_STATUS, payload: preferences });
    }
    return { status, data };
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const sendWelcomeMessageByEmail = groupsPublicIds => async dispatch => {
  try {
    const response = await Groups.sendWelcomeMessageByEmail(groupsPublicIds);
    if (response.status === 200) {
      snackBarStatus({
        payload: {
          title: response.data.message,
          type: 'success',
          enable: true,
        },
      })(dispatch);
    }
    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const sendWelcomeMessageByText = groupsPublicIds => async dispatch => {
  try {
    const response = await Groups.sendWelcomeMessageByText(groupsPublicIds);
    if (response.status === 200) {
      snackBarStatus({
        payload: {
          title: response.data.message,
          type: 'success',
          enable: true,
        },
      })(dispatch);
    }
    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const removeMemberFromOrganization = value => async dispatch => {
  try {
    const response = await Groups.removeMemberOrganization({ ...value });
    let removeMemberResponse = [];
    if (response.status === 200) {
      removeMemberResponse = response.data;
      dispatch({ type: ACTIONS.REMOVE_MEMBER_ORGANIZATION });
    }
    return removeMemberResponse;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const updateSelectedGroupOnMembersRemove = opts => async dispatch => {
  dispatch({
    type: ACTIONS.REMOVE_SELECTED_GROUP_MEMBERS,
    payload: opts,
  });
};

export const removeAllGroupMembers = groupId => async dispatch => {
  dispatch({
    type: ACTIONS.REMOVE_ALL_GROUP_MEMBERS,
    payload: groupId,
  });
};

export const removeGroup = (groupId) => async dispatch => {
  try {
    const response = await Groups.removeGroup(groupId);
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.REMOVE_GROUP,
        payload: groupId,
      });

      snackBarStatus({
        payload: {
          enable: true,
          title: 'Group Removed',
          type: 'success',
        },
      })(dispatch);
    }
    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const updateSelectedGroupFacebookPages = publicIds => async (dispatch, getState) => {
  const { groupsReducer: { selectedGroup: { public_id } } } = getState();

  try {
    const response = await Facebook.updateGroupFbPages(public_id, publicIds);

    if (response.status === 200) {
      dispatch({
        type: ACTIONS.UPDATE_SELECTED_GROUP_FB_PAGES,
        payload: publicIds,
      });
    }
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const getGroupSettings = (groupPublicId) => async (dispatch) => {
  try {
    loadingSettings(true)(dispatch);
    const response = await Groups.getGroupSettings(groupPublicId);
  
    if (response.status === 200) {
      let groupData = response.data.data;
      dispatch(selectGroup(groupData));
    }

    loadingSettings(false)(dispatch);
  } catch(error) {
    loadingSettings(false)(dispatch);

    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const removeGroupsFbConnections = () => dispatch => {
  dispatch({ type: ACTIONS.REMOVE_FB_CONNECTIONS });
};


export const clearSelectedGroup = () => async dispatch => {
  dispatch({ type: ACTIONS.CLEAR_SELECTED_GROUP });
};

export const increaseGroupsMembersCounters = (groupsPublicIds, count) => dispatch => {
  dispatch({ type: ACTIONS.INCREASE_MEMBERS_COUNTERS, payload: { groupsPublicIds, count } });
};
