import React, { useEffect, useState } from 'react';
import { getCountries } from '../../Actions/countriesDictionaryActions';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import style from '../EditMember/styles';

const CountryPreview = ({ value, data, isLoading, getCountries }) => {

  const [name, setName] = useState('');

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (!value) {
      setName('');
      return;
    }

    const item = data.find((i) => i.value === value);
    if (item) {
      setName(item.text);
    } else {
      setName('');
    }
  }, [value, data]);

  return (
    <>{isLoading ? 'Loading...' : name}</>
  );
};

const mS = state => ({
  data: state.countriesDictionaryReducer.list,
  isLoading: state.countriesDictionaryReducer.isLoading,
});

const mD = { getCountries };

export default connect(mS, mD)(withStyles(style)(CountryPreview));
