import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import TemplatePreview from '../../Containers/TemplatePreview';
import TemplateSettings from '../../Containers/TemplateSettings';
import FolderSettings from '../../Containers/FolderSettings';
import TemplateListPropType from '../../PropTypes/TemplateListPropType';
import { enableRightDrawerTemplates } from '../../Actions/rightDrawerTemplatesActions';
import {
  applySelectedTemplate,
  changeTab,
  clearMessage,
  updateIsTemplateFlag,
  updateMessageSubject,
} from '../../Actions/messageActions';
import { enableRightDrawer } from '../../Actions/rightDrawerManageTemplateActions';
import {
  changeEditTemplateFlag,
  changeState,
  clearSelectedTemplate,
  clearTemplates,
  clearTemplateSelectedProps,
  deleteTemplate,
  getFolderByIdPaged,
  getFoldersList,
  getTemplateById,
  moveTemplatesToFolder,
  selectTemplate,
  showButtonAnimation,
  updateDialog,
  updateDialogTemplate,
  updateFolderListMove,
  updateTemplateShownStatus,
} from '../../Actions/templateActions';


import { statusLoading } from '../../Actions/loadingActions';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import Debouncer from '../../Helpers/debouncer';
import Helpers from '../../Helpers';
import './index.sass';
import EvaTooltip from '../EvaTooltip';
import DeleteButton from './DeleteButton';
import Notification from '../../Components/AnimatedNotification';
import ButtonStandard from '../../Components/Button';
import MoveTemplates from '../MoveTemplates';
import { openCustomModal } from '../../Actions/customModalActions';
import {
  DESKTOP,
  MESSAGE_COMPOSER_TYPE,
  MESSAGE_DESIGNER_TYPE,
  ROLE_SUPER_ADMIN,
  SORT_RECENT,
  TEMPLATE_PAGE_SIZE_GRID,
} from '../../Config/Constants';
import Avatar from '../../Components/Avatar';
import ButtonSelect from '../ButtonSelect';
import TemplatePreviewButtons from '../TemplatePreviewButtons';
import DisplayModeSwitcher from '../DisplayModeSwitcher';
import LoaderOverlay from '../LoaderOverlay';
import SearchInput from '../SearchInput';
import MessageModalManager, { MESSAGE_MODAL_MANAGER_VIEWS } from '../../Containers/MessageModalManager';

class TemplateList extends Component {
  constructor(props) {
    super(props);

    this.counter = 0;
  }

  state = {
    viewMode: 'grid',
    itemSelected: null,
    searchTerm: '',
    moveTemplates: false,
  };

  UNSAFE_componentWillMount() {
    this.debouncerInstance = Debouncer(1000, this.searchByTerm, {
      autoShutdown: false,
    });
    this.getFoldersList();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { templateFolderSelected } = nextProps;

    this.resetMoveTemplates(templateFolderSelected);
  }

  componentDidUpdate(prevProps) {
    try {
      // eslint-disable-next-line
      const {
        match: { params },
        // eslint-disable-next-line
        updateTemplateShownStatus,
        templateShown,
      } = this.props;
      const {
        match: { params: paramsPrev },
      } = prevProps;

      if (prevProps.folder.publicId !== this.props.folder.publicId) {
        this.eraseSearchInputValue();
      }

      if (!params.publicId) return;

      if (!templateShown || paramsPrev.publicId !== params.publicId) {
        this.handleTemplatePreviewOpen(params.publicId);
        if (params.publicId) {
          updateTemplateShownStatus(true);
        }
      }
    } catch (err) {
      // Template was not found better do nothing.
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  componentWillUnmount() {
    this.props.clearTemplateSelectedProps();
    this.debouncerInstance.clearDebounce();
    this.props.updateTemplateShownStatus(false);
    this.props.clearSelectedTemplate();
  }

  eraseSearchInputValue = () =>
    this.setState({ searchTerm: '' });

  getFoldersList = () => {
    const { userMainRole } = this.props;
    this.props.getFoldersList().then(rawFoldersList => {
      this.props.updateFolderListMove(rawFoldersList, userMainRole);
    });
  };

  getDate = date => moment(date).fromNow();

  getTitle = () => {
    const { folderData } = this.props;
    if (folderData) {
      if (folderData.name) {
        return folderData.parentFolder ? folderData.parentFolder : folderData.name;
      }
      return folderData.parentFolder ? folderData.parentFolder : folderData.label;
    }
    return null;
  };

  getSubTitle = () => {
    const { folderData, templateFolderUpdated } = this.props;
    if (templateFolderUpdated) {
      return templateFolderUpdated.name;
    } else if (folderData) {
      if (folderData.name) {
        return folderData.parentFolder ? folderData.name : '';
      }
      return folderData.parentFolder ? folderData.label : '';
    }
    return null;
  };

  getNotification = () => {
    const { featuredFolder, showButton } = this.props;
    const emptyMessage = ['Looks like you don\'t have any templates in this folder.'];

    return (
      <React.Fragment>
        <Notification texts={emptyMessage} onAnimationComplete={this.startNextAnimationState} />
        {showButton &&
          <React.Fragment>
            <ButtonStandard title="Create a new template" onClick={this.handleNewTemplateClick} />
            <ButtonStandard
              title="Use a Featured template"
              onClick={() => this.props.handleTreeItemClick(featuredFolder)}
            />
          </React.Fragment>}
      </React.Fragment>
    );
  };

  getTemplatesInScroll = () => {
    const {
      nextPage,
      folder: { publicId },
      page,
      numberOfItems,
    } = this.props;
    // eslint-disable-next-line
    if (!nextPage || nextPage === page || !publicId || undefined) {
      return;
    }
    // eslint-disable-next-line
    const size = numberOfItems > TEMPLATE_PAGE_SIZE_GRID ? TEMPLATE_PAGE_SIZE_GRID : numberOfItems;
    this.props.getFolderByIdPaged(
      publicId,
      undefined,
      undefined,
      nextPage,
      size,
      this.props.templateFolderSelected,
    );
  }
  startNextAnimationState = () => {
    this.props.showButtonAnimation(true);
  };

  resetMoveTemplates = templates => {
    let checkedTemplates = [];

    if (templates && Array.isArray(templates)) {
      checkedTemplates = templates.filter(template => template.statusCheck);
    }
    if (templates && Array.isArray(templates.data)) {
      checkedTemplates = templates.data.filter(template => template.statusCheck);
    }
    if (!checkedTemplates.length) {
      // Reset move templates options if no template is selected
      this.setState({
        destinationFolder: null,
        moveTemplates: false,
      });
    }
  };

  handleMoveTemplatesToggleClick = () =>
    this.setState({ moveTemplates: !this.state.moveTemplates });

  handleMoveTemplatesSet = ({ value }) => this.setState({ destinationFolder: value });

  handleMoveTemplatesClick = () => {
    const { folderData, templateFolderSelected } = this.props;
    const moveTo = this.state.destinationFolder;
    const templates = Helpers.getSelectedTemplates(templateFolderSelected);

    this.props.statusLoading(true);
    this.props.moveTemplatesToFolder(templates, folderData.publicId, moveTo).then(response => {
      this.props.statusLoading(false);
      if (response.status === 200) {
        this.searchByTerm();
      }
    });
  };

  handleEditTemplate = () => {
    const {
      publicId,
      title,
      messageContent: { type },
    } = this.props.template;

    this.props.updateIsTemplateFlag(true);
    this.props.changeEditTemplateFlag(true);
    this.props.updateMessageSubject(title);

    if (type === MESSAGE_DESIGNER_TYPE) {
      // this.props.changeTab('Design');
      // eslint-disable-next-line
      this.props.navigationControllerRedirect(`/templates/edit/${publicId}`);
    } else if (type === MESSAGE_COMPOSER_TYPE) {
      this.props.enableRightDrawer({
        payload: {
          enable: false,
          element: <div />,
        },
      });

      // TODO: remove template prop, use messageContent instead
      this.props.openCustomModal({
        content: <MessageModalManager view={MESSAGE_MODAL_MANAGER_VIEWS.EDITOR} onSend={this.searchByTerm} />,
      });
    }
  };

  selectTemplateCheckbox = publicId => this.props.changeState(publicId);

  getSelectedTemplates = () => this.props.templateFolderSelected.filter(tpl => tpl.statusCheck);

  isTemplatesSelected = () => this.props.templateFolderSelected.some(tpl => tpl.statusCheck);

  deleteTemplate = () => {
    this.props.deleteTemplate(this.getSelectedTemplates().map(tpl => tpl.publicId));
    this.props.updateDialogTemplate(false);
  };

  isDeleteEnabled = () => {
    const { featuredFolder, folderData, userMainRole } = this.props;

    const isFeaturedFolder = featuredFolder.publicId === folderData.publicId;

    let isFeaturedSubFolder = false;
    if (featuredFolder.childrens && Array.isArray(featuredFolder.childrens)) {
      isFeaturedSubFolder = !!featuredFolder.childrens.find(
        folder => folder.publicId === folderData.publicId,
      );
    }

    return !(isFeaturedFolder || isFeaturedSubFolder) || userMainRole === ROLE_SUPER_ADMIN;
  };

  click = type => {
    const { folderData } = this.props;
    const { searchTerm } = this.state;
    this.setState({
      itemSelected: type,
    });
    this.props.statusLoading(true);
    this.props.getFolderByIdPaged(folderData.publicId, type, searchTerm, 1, TEMPLATE_PAGE_SIZE_GRID).then(() => {
      this.props.statusLoading(false);
    });
  };

  handleSettings = () => {
    const { folderData, mountComponent } = this.props;
    const WindowHeight = window.innerHeight - 64;
    const secondTitle =
      this.props.viewType === DESKTOP ? (
        <DeleteButton onClick={() => this.props.updateDialog(true)} />
      ) : null;

    this.props.enableRightDrawerTemplates({
      payload: {
        enable: true,
        element: (
          <Scrollbars autoHeight autoHide autoHeightMax={WindowHeight}>
            <FolderSettings folder={folderData} mountComponent={mountComponent} />
          </Scrollbars>
        ),
        title: 'Folder Settings',
        secondTitle,
      },
    });
  };

  handleUseTemplate = () => {
    const { publicId, messageContent: { type } } = this.props.template;

    this.props.applySelectedTemplate(true);

    if (type === MESSAGE_DESIGNER_TYPE) {
      this.props.changeTab('Design');
      // eslint-disable-next-line
      this.props.navigationControllerRedirect(`/messages/new?fromTpl=${publicId}`);
      this.props.enableRightDrawer({
        payload: {
          enable: false,
          menuOptions: null,
        },
      });
    } else if (type === MESSAGE_COMPOSER_TYPE) {
      this.props.enableRightDrawer({
        payload: {
          enable: false,
          menuOptions: null,
        },
      });
      this.props.openCustomModal({ content: <MessageModalManager view={MESSAGE_MODAL_MANAGER_VIEWS.EDITOR} /> });
    }
  };

  handleTemplatePick = async publicId => {
    const response = await this.props.getTemplateById(publicId);
    if (response.code === 200) {
      this.props.selectTemplate(response.data);
      this.handleUseTemplate();
    }
  };

  handleSettingsTemplate = () => {
    const { template, template: { comments, sharedWith, folder, featuredFolder }, folderMoveList } = this.props;
    this.props.enableRightDrawer({
      payload: {
        enable: false,
      },
    });

    this.props.enableRightDrawer({
      payload: {
        enable: true,
        element: (
          <TemplateSettings
            {...template}
            comments={comments}
            folderList={folderMoveList}
            sharedWith={sharedWith}
            folder={folder}
            featuredSubFolder={featuredFolder}
            afterUpdate={this.searchByTerm}
            noModal
          />
        ),
        menuOptions: null,
        secondTitle: '',
        title: 'Settings',
      },
    });
  };

  handleDisplayModeChange = mode => {
    this.setState({ viewMode: mode });
  };

  searchByTerm = () => {
    const { folderData } = this.props;
    const { itemSelected, searchTerm } = this.state;
    this.props.clearTemplates();
    this.props.getFolderByIdPaged(folderData.publicId, itemSelected, searchTerm, 1, TEMPLATE_PAGE_SIZE_GRID);
  };

  canMoveTemplates = () => {
    const { folder, userMainRole } = this.props;
    let name = folder && folder.isSystemFolder ? folder.label : folder.parentFolder;
    // when is the first time
    name = name === undefined ? 'Featured' : name;
    return Helpers.getEditableFoldersByRole(userMainRole).includes(name);
  };

  handleChange = value => {
    this.setState({ searchTerm: value });
    /* eslint-disable */
    this.debouncerInstance.debounce(++this.counter);
    /* eslint-enable */
  };

  handleDeleteTemplate = () => {
    this.props.updateDialogTemplate(true);
  };

  handleCloseTemplate = () => {
    this.props.updateDialogTemplate(false);
  };

  handleTemplatePreviewOpen = async publicId => {
    const response = await this.props.getTemplateById(publicId);
    if (response.code !== 200) {
      return;
    }
    this.props.selectTemplate(response.data);

    const { folder, folderData, userProfilePicture, userMainRole, profilePicture, profileName, template } = this.props;
    this.props.enableRightDrawer({
      payload: {
        enable: true,
        isBackButton: true,
        element: (
          <TemplatePreview
            profilePicture={profilePicture}
            profileName={profileName}
            selectedTemplate={template}
            typeFolder={folderData.label}
            parentFolder={folderData.parentFolder}
          />
        ),
        title: template.title,
        subtitle: folderData.name || folderData.label,
        subtitleDate: template.created_at,
        secondTitle: (
          <React.Fragment>
            <div className="eva-logo separation-margin" />
            <Avatar image={userProfilePicture} borderless className="px-35" />
          </React.Fragment>
        ),
        menuOptions: (
          <TemplatePreviewButtons
            userMainRole={userMainRole}
            onEditTemplate={this.handleEditTemplate}
            onUseTemplate={this.handleUseTemplate}
            // eslint-disable-next-line
            onSettingsTemplate={this.handleSettingsTemplate}
            template={template}
            folder={!folder.public_id ? folderData : folder}
            afterUpdate={this.searchByTerm}
          />
        ),
      },
    });
    this.props.selectTemplate(template);
    this.props.applySelectedTemplate();
    this.props.updateIsTemplateFlag(true);
  };

  handleNewTemplateClick = () => {
    this.props.updateIsTemplateFlag(true);
    this.props.changeEditTemplateFlag(false);
    this.props.history.push('/templates/new');
  };

  canEditSettings = () => {
    const { folderData, userMainRole } = this.props;

    const allowedFolders = Helpers.getEditableFolderSettingsByRole(userMainRole);

    return allowedFolders.includes(folderData.parentFolder);
  };

  shouldShowSettings = () => {
    const { folderData } = this.props;

    return !folderData.isSystemFolder && this.canEditSettings();
  };

  renderTemplatesNew = () => {
    const { folderData, templateFolderSelected } = this.props;
    const { viewMode } = this.state;

    return (
      <Scrollbars
        onUpdate={({ top }) => {
          if (top > 0.75 && !this.props.templatesLoading) {
            this.getTemplatesInScroll();
            // this.handleScrollFrame();
          }
        }}
        autoHeight
        autoHeightMax={420}
        autoHide
        className="custom-scrollbar"
      >
        <section className={`templates-list-wrapper ${viewMode}-view`}>
          {templateFolderSelected.map(tpl => (
            <div className="template-list-item" key={tpl.publicId}>
              <button
                className={`template-checked ${tpl.statusCheck ? 'template-checked-active' : ''}`}
                tabIndex={0}
                onClick={() => this.selectTemplateCheckbox(tpl.publicId)}
              >
                <i className="checkbox-icon" />
              </button>

              <div className="tpl-picture" style={{ backgroundImage: `url(${tpl.mainPicture})` }} />

              <div
                className="tpl-info"
                role="button"
                onClick={() => this.handleTemplatePreviewOpen(tpl.publicId)}
                onKeyPress={() => this.handleTemplatePreviewOpen(tpl.publicId)}
              >
                <div className="tpl-title">{tpl.title}</div>
                <div className="tpl-date">{this.getDate(tpl.createdAt)}</div>
                <div className="tpl-parent">
                  {folderData && folderData.parentFolder ? folderData.parentFolder : folderData.label}
                </div>
              </div>

              <button
                className="message-button"
                onClick={() => this.handleTemplatePick(tpl.publicId)}
              >
                <EvaTooltip title="New Message">
                  <i className="message-icon" />
                </EvaTooltip>
              </button>
            </div>
          ))}
        </section>
      </Scrollbars>
    );
  }

  renderHeader = () => {
    const {
      folderData,
      templateFolderSelected,
      templatesLoaded,
      templatesLoading,
      folderMoveList,
    } = this.props;
    const {
      viewMode,
      searchTerm,
      moveTemplates,
      destinationFolder,
    } = this.state;
    const canMoveTemplates = this.canMoveTemplates();

    const isEmptyFolder = templatesLoaded && !templatesLoading &&
      templateFolderSelected && templateFolderSelected.length === 0 && searchTerm === '';

    const title = this.getTitle();
    const subTitle = this.getSubTitle();

    return (
      <header className="templates-list-header">
        <div className="templates-list-toolbar">
          <div className="templates-title-wrapper">
            <h3 className="title-eva">{title}</h3>
            {subTitle && <div className="name-group">{subTitle}</div>}
          </div>
          <div className="settings-button">
            {this.shouldShowSettings() && (
              <EvaTooltip title="Folder Settings">
                <i
                  className="settings-icon"
                  onKeyPress={this.handleOnKeyPress}
                  role="menuitem"
                  tabIndex="0"
                  onClick={this.handleSettings}
                />
              </EvaTooltip>
            )}
          </div>
        </div>

        {!isEmptyFolder && (
          <React.Fragment>
            <SearchInput value={this.state.searchTerm} onChange={value => this.handleChange(value)} />

            <div className="templates-actions-pad">
              <div className="left-side-actions">
                <ButtonSelect
                  className="button-sort"
                  values={SORT_RECENT}
                  defaultValue={null}
                  onChange={this.click}
                />

                <DisplayModeSwitcher type={viewMode} onChange={this.handleDisplayModeChange} />

              </div>
              <div className="right-side-actions">
                {this.isTemplatesSelected() && (
                  <React.Fragment>
                    {this.isDeleteEnabled() && (
                      <EvaTooltip title="Delete">
                        <button className="icon-button remove-button" onClick={this.handleDeleteTemplate} />
                      </EvaTooltip>
                    )}
                    {canMoveTemplates && (
                      <EvaTooltip title="Move to Folder">
                        <button className={`icon-button folder ${moveTemplates ? 'active' : ''}`}
                                onClick={this.handleMoveTemplatesToggleClick}
                        />
                      </EvaTooltip>
                    )}
                  </React.Fragment>
                )}

                <EvaTooltip title="New Template">
                  <button className="icon-button add" onClick={this.handleNewTemplateClick} />
                </EvaTooltip>

              </div>
            </div>

            {moveTemplates && (
              <MoveTemplates
                data={
                  folderMoveList &&
                  folderMoveList.filter(({ value }) =>
                    value !== folderData.publicId)
                }
                value={destinationFolder}
                onClick={this.handleMoveTemplatesClick}
                onChange={this.handleMoveTemplatesSet}
              />
            )}
          </React.Fragment>
        )}
      </header>
    );
  }

  render() {
    const {
      templateFolderSelected,
      statusDialogTemplate,
      templatesLoaded,
      templatesLoading,
    } = this.props;
    const {
      searchTerm,
    } = this.state;
    const isEmptyFolder = templatesLoaded && !templatesLoading &&
      templateFolderSelected && templateFolderSelected.length === 0 && searchTerm === '';
    const isNoMatches = templatesLoaded && !templatesLoading &&
      searchTerm !== '' && templateFolderSelected && templateFolderSelected.length === 0;

    return (
      <React.Fragment>
        <section id="templates-list">
          <div className="content-inner-wrapper">
            {this.renderHeader()}

            {isEmptyFolder &&
              <div id="notification-container" key="notification-container">{this.getNotification()}</div>}

            {!isEmptyFolder && this.renderTemplatesNew()}

            {templatesLoading && <LoaderOverlay />}

            {isNoMatches && (<div className="no-match-message">No match found</div>)}

            {!isEmptyFolder && (
              <Dialog
                open={statusDialogTemplate}
                onClose={this.handleCloseTemplate}
                className="return-to-edit"
                maxWidth="sm"
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                }}
              >
                <DialogContent className="container-warning-button">
                  <div className="warning-Settings">
                    <div className="single-line">
                      <strong> WARNING:</strong>
                      Deleting this template will also delete all of its contents.
                      <div />
                      <strong>Are you sure you want to delete this template?</strong>
                    </div>
                  </div>

                  <button onClick={this.deleteTemplate} className="button-warning">
                      <span className="text-warning">
                        Yes, delete this template and all of its contents
                      </span>
                  </button>
                  <button onClick={this.handleCloseTemplate} className="button-warning">
                    <span className="text-warning">No, I want to keep this template</span>
                  </button>
                </DialogContent>
              </Dialog>
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

TemplateList.propTypes = TemplateListPropType;

const mS = state => ({
  templateFolderSelected: state.templateReducer.templateFolderSelected,
  numberOfItems: state.templateReducer.numberOfItems,
  nextPage: state.templateReducer.nextPage,
  prevPage: state.templateReducer.previousPage,
  totalPages: state.templateReducer.totalPages,
  page: state.templateReducer.page,
  template: state.templateReducer.template,
  folder: state.templateReducer.folder,
  statusDialogTemplate: state.templateReducer.statusDialogTemplate,
  templateListSelected: state.templateReducer.templateListSelected,
  featuredFolder: state.templateReducer.featuredFolder,
  showButton: state.templateReducer.showButton,
  templatesLoaded: state.templateReducer.templatesLoaded,
  templatesLoading: state.templateReducer.templatesLoading,
  userMainRole: state.userProfileReducer.main_role,
  userProfilePicture: state.userProfileReducer.member.profile_picture_url,
  viewType: state.responsiveReducer.viewType,
  folderMoveList: state.templateReducer.folderMoveList,
  templateShown: state.templateReducer.templateShown,
  templateFolderUpdated: state.templateReducer.templateFolderUpdated,
  profilePicture: state.userProfileReducer.member.profile_picture_url,
  profileName: state.userProfileReducer.display_name,
});

const mD = {
  applySelectedTemplate,
  changeEditTemplateFlag,
  changeState,
  changeTab,
  clearTemplates,
  clearTemplateSelectedProps,
  clearSelectedTemplate,
  clearMessage,
  deleteTemplate,
  getFolderByIdPaged,
  getFoldersList,
  getTemplateById,
  enableRightDrawer,
  enableRightDrawerTemplates,
  selectTemplate,
  moveTemplatesToFolder,
  navigationControllerRedirect,
  showButtonAnimation,
  statusLoading,
  updateDialog,
  updateDialogTemplate,
  updateMessageSubject,
  openCustomModal,
  updateFolderListMove,
  updateTemplateShownStatus,
  updateIsTemplateFlag,
};

export default withRouter(connect(mS, mD)(TemplateList));
