import Invitation from '../Api/Invitation';
import snackBarStatus from './snackbarActions';
import User from '../Api/User';

export const ACTIONS = {
  GET_ORGANIZATION: '[Invitation] Get organization info',
  GET_ORGANIZATION_ADMIN_PROMOTION: '[Invitation] Get organization info (admin promotion)',
  GET_ORGANIZATION_SUPER_ADMIN_PROMOTION: '[Invitation] Get organization info (super admin promotion)',
  NOT_FOUND: '[Invitation] Not found',
  GET_GROUP: '[Invitation] Get group info',
  GET_GROUP_ADMIN_PROMOTION: '[Invitation] Get group info (admin promotion)',
  CLEAR_INVITATION: '[Invitation] Clear invitation',
  LOADING: '[Invitation] Loading',
};

const setLoading = bool => dispatch => {
  dispatch({
    type: ACTIONS.LOADING,
    payload: bool,
  });
};

export const requestOrganizationAction = (organizationId, callback) => async dispatch => {
  try {
    setLoading(true)(dispatch);
    const {
      status,
      data: { data },
    } = await Invitation.requestOrganization(organizationId);
    if (status === 200) {
      dispatch({ type: ACTIONS.GET_ORGANIZATION, payload: data });
    }
    setLoading(false)(dispatch);
    return data;
  } catch (error) {
    setLoading(false)(dispatch);
    callback?.();
    return error;
  }
};

export const requestGroupAction = (groupId, callback) => async dispatch => {
  try {
    setLoading(true)(dispatch);
    const {
      status,
      data: { data },
    } = await Invitation.requestPublicGroupInfo(groupId);
    if (status === 200) {
      dispatch({ type: ACTIONS.GET_GROUP, payload: data });
    }
    setLoading(false)(dispatch);
    return data;
  } catch (error) {
    callback?.();
    setLoading(false)(dispatch);
    return error;
  }
};

export const requestPublicOrganizationAdminPromotionInfoAction = (organizationId, token) =>
  async dispatch => {
    try {
      setLoading(true)(dispatch);
      const {
        status,
        data: { data },
      } = await Invitation.requestPublicOrganizationInfoAdminPromotion(organizationId, token);
      if (status === 200) {
        dispatch({ type: ACTIONS.GET_ORGANIZATION_ADMIN_PROMOTION, payload: data });
      }
      setLoading(false)(dispatch);
      return data;
    } catch (error) {
      dispatch({ type: ACTIONS.NOT_FOUND, payload: error });
      setLoading(false)(dispatch);
      return error;
    }
  };
export const requestPublicOrganizationSuperAdminPromotionInfoAction = (organizationId, token) =>
  async dispatch => {
    try {
      setLoading(true)(dispatch);
      const {
        status,
        data: { data },
      } = await Invitation.requestPublicOrganizationInfoSuperAdminPromotion(organizationId, token);
      if (status === 200) {
        dispatch({ type: ACTIONS.GET_ORGANIZATION_SUPER_ADMIN_PROMOTION, payload: data });
      }
      setLoading(false)(dispatch);
      return data;
    } catch (error) {
      dispatch({ type: ACTIONS.NOT_FOUND, payload: error });
      setLoading(false)(dispatch);
      return error;
    }
  };


export const requestPublicGroupAdminPromotionInfoAction = (groupId, token) => async dispatch => {
  try {
    setLoading(true)(dispatch);
    const {
      status,
      data: { data },
    } = await Invitation.requestPublicGroupInfoAdminPromotion(groupId, token);
    if (status === 200) {
      dispatch({ type: ACTIONS.GET_GROUP_ADMIN_PROMOTION, payload: data });
    }
    setLoading(false)(dispatch);
    return data;
  } catch (error) {
    dispatch({ type: ACTIONS.NOT_FOUND, payload: error });
    setLoading(false)(dispatch);
    return error;
  }
};

export const clearInvitation = () => dispatch => {
  dispatch({ type: ACTIONS.CLEAR_INVITATION });
};
