import User from '../Api/User';
import snackBarStatus, { clearSnackBarStatus } from './snackbarActions';
import { ACTIONS as navigationActions } from './navigationControllerActions';
import { isValuesFromArrayInArray } from '../Helpers/isValuesFromArrayInArray';
import { ROLES_ADMIN } from '../Config/Constants';

export const ACTIONS = {
  GET_USER_PROFILE: 'user/get_profile',
  SET_IS_ADMIN: 'user/set_is_admin',
  SET_IS_SUPER_ADMIN: 'user/set_is_super_admin',
  GET_MEMBER_PROFILE: 'user/get_member_profile',
  SELECT_ORG: 'user/select_organization',
  UPDATE_SUGGESTIONS_SETTINGS: 'user/update_suggestions_settings',
  DELETE_ACCOUNT: '/user/delete-account',
  UNSUBSCRIBE_FROM_GROUP: 'user/unsubscribe-from-group',
  GET_UNSUBSCRIBE_CASE: 'user/unsubscribe-get-case',
};

export const getUserProfile = (org = null) => async dispatch => {
  try {
    if (!org) {
      const response = await User.getProfile();

      return response;
    }

    const response = await User.getProfile(org);
    let userProfile = {};
    if (response.status === 200) {
      userProfile = response.data.data;
      let isSuperAdmin = false;
      let isAdmin = false;
      if (
        userProfile &&
        userProfile.member &&
        userProfile.member.roles
      ) {
        isSuperAdmin = userProfile.member.roles.includes('ROLE_SUPER_ADMIN');
        isAdmin = isValuesFromArrayInArray(userProfile.member.roles, ROLES_ADMIN);
      }
      dispatch({
        type: ACTIONS.GET_USER_PROFILE,
        payload: userProfile,
      });

      dispatch({
        type: ACTIONS.SET_IS_SUPER_ADMIN,
        payload: isSuperAdmin,
      });

      dispatch({
        type: ACTIONS.SET_IS_ADMIN,
        payload: isAdmin,
      });
    }
    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const updateSuggestionsSettings = data => async dispatch => {
  try {
    const response = await User.updateSuggestionsSettings(data);
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.UPDATE_SUGGESTIONS_SETTINGS,
        payload: response,
      });
      snackBarStatus({
        payload: {
          title: 'Updated EVA suggestions',
          type: 'success',
          enable: true,
        },
      })(dispatch);
    }
    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const deleteAccount = () => async dispatch => {
  try {
    const response = await User.deleteAccount();
    if (response.status === 200) {
      localStorage.removeItem('token');
      localStorage.removeItem('sso_token');
      localStorage.removeItem('expiration');
      localStorage.removeItem('roles');
      localStorage.clear();
      clearSnackBarStatus()(dispatch);
      dispatch({
        type: navigationActions.REDIRECT,
        payload: '/delete-account',
      });
    }
    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

/**
 * Sets the unsusbcribe process to its initial state.
 */
export function cleanUnsusbcribeProcess() {
  // eslint-disable-next-line
  return function(dispatch) {
    dispatch({
      type: ACTIONS.GET_UNSUBSCRIBE_CASE,
      payload: null,
    });
  };
}

/**
 * Performs API call to unsubscribe a member from a group.
 * @param {string} groupId
 * @param {string} memberId
 */
export function unsubscribeUser(groupId, memberId, token) {
  // eslint-disable-next-line
  return async function(dispatch) {
    try {
      const { data: { data: result } } = await User.unsubscribeUserFromGroup(groupId, memberId, token);
      dispatch({
        type: ACTIONS.GET_UNSUBSCRIBE_CASE,
        payload: result,
      });
      return;
    } catch (err) {
      throw err;
    }
  };
}
