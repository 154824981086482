import React, { useEffect } from 'react';
import classNames from 'classnames';
import AddMember from './AddMember';
import { formatDate } from '../../Helpers/date';
import GroupCardPropType from '../../PropTypes/GroupCardPropType';
import EvaTooltip from '../EvaTooltip';
import './index.sass';

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const GroupCard = ({
  isSelected,
  data,
  onSelect,
  onNewMessage,
  onAddMember,
  parentName,
  onSettingsClick,
}) => {
  const cardRef = React.createRef();

  const memberQuantity = data.members_quantity;
  const displayedDate = formatDate(data.last_message_date ? data.last_message_date : data.updated_at);
  const subGroupQuantity= data.sub_groups_quantity;
  const privacyLevel= data.privacyLevel || data.privacy_level;

  // TODO: remove?
  useEffect(() => {
    triggerClickIfSelected();
  }, []);

  const triggerClickIfSelected = () => {
    if (isSelected && cardRef) {
      cardRef.current.click();
      cardRef.current.parentNode.scrollIntoView();
    }
  };

  const renderPermissionIcon = () => (
    <EvaTooltip
      title={capitalize(privacyLevel)}
    >
      <div
        className={`permission permission-${privacyLevel}`}
      />
    </EvaTooltip>
  );

  const handleNewMessage = (e) => {
    e.stopPropagation();
    onNewMessage(e, data);
  };

  const handleSettings = (e) => {
    e.stopPropagation();
    onSettingsClick(e, data);
  };

  const hasCaminoSubGroup = (group) => {
    return group.subGroups?.flat(Infinity).some(subGroup => subGroup.type === 'camino') || false;
  };
  
  return (
    <div className={classNames(
      'group-card', {
        'style-selected': isSelected,
        'style-general': !!data.organization_general_group,
        'style-inactive': !data.isEnabled,
      })
    } onClick={e => onSelect(e, data)}>
      <header ref={cardRef}>
        {parentName && (
          <div className="parent-name" title={parentName}>
            {parentName}
          </div>
        )}

        <div className="header-inner-wrapper">
          <div className="name" title={data.name}>{data.name}</div>
          <div className="date">{displayedDate}</div>
        </div>
      </header>

      <div className="group-body">
        <div className="group-counters">
          <span>{memberQuantity} member{memberQuantity !== 1 && 's'}</span>

          {subGroupQuantity > 0 && (
            <>
              <span className="separator">|</span>
              <span>{subGroupQuantity} sub-group{subGroupQuantity !== 1 && 's'}</span>
            </>
          )}
        </div>

        {data.isEnabled && (
          <EvaTooltip title="New Message">
            <button
              className="icon-button new-message"
              onClick={handleNewMessage}
            />
          </EvaTooltip>
        )}
      </div>

      <footer>
        <div className="footer-inner-wrapper">
          {renderPermissionIcon()}
          {data.type === 'camino' && 'Camino'}
        </div>

        <div className="card-actions">
          <EvaTooltip title="Settings">
            <button className="icon-button settings" onClick={handleSettings} />
          </EvaTooltip>

          {data.isEnabled && data.type === 'eva' && !hasCaminoSubGroup(data) && (
            <AddMember
              className="button-add-member"
              afterMembersAction={onAddMember}
              group={data}
              handleSelectEvaCard={onSelect}
            />
          )}
        </div>
      </footer>
    </div>
  );
};

GroupCard.propTypes = GroupCardPropType;

export default GroupCard;
