import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TopBar from './TopBar';
import ScheduledMessagePropTypes from '../../PropTypes/ScheduledMessage/ScheduledMessagePropTypes';
import { styles } from './styles';
import CancelScheduledMessage from '../CancelScheduledMessage';
import MessageThreadItem from '../MessageThreadItem';
import { getDateInTimezone } from '../../Helpers/date';
import { SCHEDULED_MSG_DATE_FORMAT } from '../../Config/Constants';

const ScheduledMessagePreview = ({ classes, message, organizationTimezone, onCancelClick, ...props }) => {
  const { subject, groups, sentByMember: { profilePictureUrl }, messageContent: { webContent: { text } } } = message;
  const date = `Scheduled for: ${getDateInTimezone(message.deliveryTime, organizationTimezone, SCHEDULED_MSG_DATE_FORMAT)}`;

  return (
    <div className={classes.root}>
      <TopBar subject={subject} groups={groups} onCancelClick={onCancelClick} {...props} />
      <MessageThreadItem
        rtl
        highlighted
        fullWidth
        messageHtml={text}
        userImage={profilePictureUrl}
        date={date}
      />

      <CancelScheduledMessage messageObject={message} />
    </div>
  );
};

ScheduledMessagePreview.propTypes = ScheduledMessagePropTypes;

export default withStyles(styles)(ScheduledMessagePreview);
