import React, { useEffect, useState } from 'react';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';
import { Select } from '@mobiscroll/react';
import { withStyles } from '@material-ui/core/styles';
import style from '../EditMember/styles';
import { getStates } from '../../Actions/statesDictionaryActions';
import { connect } from 'react-redux';

const StatePicker = ({
  className = '', statesHash, isLoading, countryId = '', placeholder = 'State', disabled = false, value,
  onChange, getStates,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (countryId) {
      getStates(countryId);
    }
  }, [countryId]);

  useEffect(() => {
    setOptions(statesHash[countryId] || []);
  }, [statesHash, countryId]);

  return (
    <div className={className}>
      <Select
        disabled={disabled || !countryId || isLoading || options.length === 0}
        inputProps={{
          cssClass: 'dropdown-state-picker',
        }}
        data={options}
        value={value}
        onChange={(o) => onChange(o?.value)}
        name="state"
        responsive={mbscResponsiveOptions}
        placeholder={placeholder}
      />
    </div>
  );
};

const mS = state => ({
  statesHash: state.statesDictionaryReducer.hash,
  isLoading: state.statesDictionaryReducer.isLoading,

});

const mD = {
  getStates,
};

export default connect(mS, mD)(withStyles(style)(StatePicker));
