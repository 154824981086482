import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import moment from 'moment';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ScheduleMessagePropType from '../../PropTypes/ScheduleMessagePropType';
import ButtonStandard from '../Button';
import EvaTooltip from '../EvaTooltip';
import {
  SCHEDULED_MSG_API_REQUEST_DATE_FORMAT,
  SCHEDULED_MSG_API_REQUEST_TIME_FORMAT,
  SCHEDULED_MSG_CUSTOM_TIME,
  SCHEDULED_MSG_TIMES_SETTINGS,
} from '../../Config/Constants';
import './index.sass';
import LoaderOverlay from '../LoaderOverlay';
import { Datepicker, Dropdown, Select } from '@mobiscroll/react';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';

const DEFAULT_TIMEZONES_USA = [
  { name: 'America/New_York', GMT: 'GMT-04:00' },
  { name: 'America/Chicago', GMT: 'GMT-05:00' },
  { name: 'America/Denver', GMT: 'GMT-06:00' },
  { name: 'America/Los_Angeles', GMT: 'GMT-07:00' },
  { name: 'America/Anchorage', GMT: 'GMT-08:00' },
  { name: 'Pacific/Honolulu', GMT: 'GMT-10:00' },
];

// TODO: refactoring
class ScheduleMessage extends Component {
  state = {
    value: '',
    statusCustomTime: false,
    date: moment().format(SCHEDULED_MSG_API_REQUEST_DATE_FORMAT),
    list: localStorage.getItem('timeZoneList') !== null ? localStorage.getItem('timeZoneList') : [],
  };

  componentDidMount() {
    const { messageTimeZone, orgTimeZone, onChange } = this.props;
    const { list } = this.state;
    onChange({ isScheduledMessage: true });

    const utc = JSON.parse(list).find(timezone => timezone.name === orgTimeZone);
    if (orgTimeZone && !messageTimeZone) {
      const updSettings = {
        timeZone: orgTimeZone,
      };
      if (utc && 'GMT' in utc) {
        updSettings.gmt = utc.GMT;
      }

      onChange(updSettings);
    }
  }

  handleChangeRadio = event =>
    this.setState({ value: event.target.value })

  handleTimeZoneChange = ({ value }) => {
    const { list } = this.state;

    if (value === 'changeTimeZone') {
      this.props.onTimezonePick();
    } else {
      const filter = JSON.parse(list).filter(
        zone => zone.name.toLowerCase().indexOf(value.toLowerCase()) >= 0,
      );
      this.props.onChange({
        timeZone: value,
        gmt: filter[0].GMT,
        // deliveryTime: ''
      });
      this.setState({ value: '', statusCustomTime: false });
    }
    this.forceUpdate();
  };

  handleCalendar = event => {
    if (!event.value) return;
    this.handleChangeTime({ target: { value: 'customTime' } });
    const dateTimeText = event.value;
    this.props.onChange({
      deliveryTime: moment(dateTimeText).format(SCHEDULED_MSG_API_REQUEST_DATE_FORMAT),
      isScheduledMessage: true,
    });
    this.setState({
      date: dateTimeText,
    });
  };

  handleChangeTime = e => {
    const { value } = e.target;
    let deliveryTime = '';

    this.setState({ statusCustomTime: false });

    switch (value) {
      case SCHEDULED_MSG_CUSTOM_TIME:
        deliveryTime = moment(this.state.date).format(SCHEDULED_MSG_API_REQUEST_DATE_FORMAT);
        this.setState({ value, statusCustomTime: true });
        break;
      default:
        const selectedTime = this.getTodayDateWithTime(value);
        if (!this.isToday(value)) {
          selectedTime.add(1, 'days');
        }
        deliveryTime = selectedTime.format(SCHEDULED_MSG_API_REQUEST_DATE_FORMAT);
        break;
    }

    this.props.onChange({
      deliveryTime,
      isScheduledMessage: true,
    });
  };

  handleMessageSend = () => {
    this.props.onChange({ isScheduledMessage: true });
    this.props.onSendMessage();
  };

  getTodayDateWithTime = time => {
    const fullDate = moment(time, SCHEDULED_MSG_API_REQUEST_TIME_FORMAT);
    const currentTime = moment.tz(new Date(), this.props.messageTimeZone);
    const pickedDate = moment(currentTime).clone();
    return pickedDate
      .hours(fullDate.hours())
      .minutes(fullDate.minutes())
      .seconds(fullDate.seconds());
  }

  isToday = (time) => {
    const currentTime = moment.tz(new Date(), this.props.messageTimeZone);
    return this.getTodayDateWithTime(time).isAfter(currentTime);
  }

  getScheduledDay = time => this.isToday(time) ? 'today' : 'tomorrow';

  timezoneToDropdownItem = zone => ({
    text: zone ? `(${zone.GMT}) - ${zone.name}` : 'EST:',
    value: zone?.name,
  })

  getTimezones = () => {
    const { list } = this.state;
    const { messageTimeZone } = this.props;

    const timeZonesList = DEFAULT_TIMEZONES_USA.map(zone => this.timezoneToDropdownItem(zone));

    if (messageTimeZone && list.length) {
      const currentTimeZone = JSON.parse(this.state.list)
        .find(zone => zone.name === messageTimeZone);

      if (currentTimeZone) {
        timeZonesList.unshift(this.timezoneToDropdownItem(currentTimeZone));
      }
    }

    timeZonesList.push({ text: 'View more time zones', value: 'changeTimeZone' });

    return timeZonesList;
  }

  render() {
    const { date, statusCustomTime } = this.state;
    const { isLoading, messageTimeZone } = this.props;
    const timeZonesList = this.getTimezones();

    return (
      <section id="schedule-message-container">
        <Grid container spacing={0} className="form-create-group">
          <Grid item xs={10} className="title-eva title-schedule-no-background">
            Schedule message delivery
          </Grid>
          <Grid item xs={1} className="help-icon-margin-schedule-message">
            <EvaTooltip title="Select a time the following day or a specific date and time in future.">
              <i id="help-icon-shcedule-message"/>
            </EvaTooltip>
          </Grid>
          <Grid item xs={12} id="schedule-message-delivery" className="input-eva">
            <RadioGroup
              className="radio-eva"
              aria-label="schedule-message-delivery"
              name="schedule"
              value={this.state.value}
              onChange={this.handleChangeRadio}
            >
              <FormControlLabel
                className="radio-eva-label"
                onChange={this.handleChangeTime}
                value={SCHEDULED_MSG_TIMES_SETTINGS.morning}
                control={<Radio disableRipple className="radio-eva-sunrise" color="default"/>}
                label={`Morning - 9:00am (${this.getScheduledDay(SCHEDULED_MSG_TIMES_SETTINGS.morning)})`}
              />
              <FormControlLabel
                className="radio-eva-label"
                onChange={this.handleChangeTime}
                value={SCHEDULED_MSG_TIMES_SETTINGS.lunch}
                control={<Radio disableRipple className="radio-eva-sun" color="default"/>}
                label={`Lunch time - 12:00pm (${this.getScheduledDay(SCHEDULED_MSG_TIMES_SETTINGS.lunch)})`}
              />
              <FormControlLabel
                className="radio-eva-label"
                onChange={this.handleChangeTime}
                value={SCHEDULED_MSG_TIMES_SETTINGS.afternoon}
                control={<Radio disableRipple className="radio-eva-sunset" color="default"/>}
                label={`Afternoon - 4:00pm (${this.getScheduledDay(SCHEDULED_MSG_TIMES_SETTINGS.afternoon)})`}
              />
              <FormControlLabel
                className="radio-eva-label"
                onChange={this.handleChangeTime}
                value={SCHEDULED_MSG_TIMES_SETTINGS.evening}
                control={<Radio disableRipple className="radio-eva-moon" color="default"/>}
                label={`Evening - 8:00pm (${this.getScheduledDay(SCHEDULED_MSG_TIMES_SETTINGS.evening)})`}
              />
              <Grid container spacing={0} className="form-custom-time">
                <Grid item xs={1} className="radio-custom-time">
                  <FormControlLabel
                    checked={statusCustomTime}
                    className="radio-eva-label"
                    onChange={this.handleChangeTime}
                    value={SCHEDULED_MSG_CUSTOM_TIME}
                    control={<Radio disableRipple color="default"/>}
                  />
                </Grid>
                <Grid item xs={11} className="custom-time-picker">
                  <Datepicker
                    value={date}
                    controls={['calendar', 'time']}
                    onChange={this.handleCalendar}
                    headerText="{value}"
                    placeholder="Custom time"
                    buttons={['set', 'cancel']}
                    returnFormat="iso8601"
                    min={new Date()}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <div id="dropdown-mobis" className="dropdown-mobis dropdown-confirm">
              <Select
                data={timeZonesList}
                value={messageTimeZone}
                onChange={this.handleTimeZoneChange}
                name="customTimeSelect"
                responsive={mbscResponsiveOptions}
                placeholder="Select time zone"
              />
            </div>
          </Grid>
          <Grid item xs={12} className="input-eva">
            <ButtonStandard title="Schedule Message" onClick={this.handleMessageSend}/>
          </Grid>
        </Grid>
        {isLoading && <LoaderOverlay/>}
      </section>
    );
  }
}

ScheduleMessage.propTypes = ScheduleMessagePropType;

export default ScheduleMessage;
