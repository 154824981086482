import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { openCustomModal } from '../../Actions/customModalActions';
import { clearMessages, getMessages } from '../../Actions/messagesActions';
import { clearFormGroup } from '../../Actions/groupsActions';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import { updateRecipientGroups } from '../../Actions/messageActions';
import Notifications from '../../Components/AnimatedNotification';
import ButtonStandard from '../../Components/Button';
import MessagesGroups from '../../Components/MessagesGroups';
import MessageModalManager, { MESSAGE_MODAL_MANAGER_VIEWS } from '../MessageModalManager';
import './index.sass';

class GroupMessages extends Component {
  state = {
    texts: ['This group is quiet right now. Everyone is waiting to hear from you.'],
    buttonText: 'Let\'s send a new message',
    buttonVisible: false,
  };

  componentWillUnmount() {
    this.props.clearMessages();
  }

  handleAnimationComplete = () => {
    this.setState({ buttonVisible: true });
  };

  handleNewMessage = () => {
    const { selectedGroup, afterMembersAction } = this.props;

    this.props.clearFormGroup();

    const autoCompleteValue = [];

    autoCompleteValue.push({
      publicId: selectedGroup.public_id,
      value: selectedGroup.public_id,
      label: selectedGroup.name,
      group: selectedGroup,
    });

    this.props.updateRecipientGroups([{ group: selectedGroup }]);

    this.props.openCustomModal({
      content: <MessageModalManager view={MESSAGE_MODAL_MANAGER_VIEWS.EDITOR} onSend={afterMembersAction} />,
    });
  };

  render() {
    // TODO: implement isLoaded.
    const { texts, buttonText, buttonVisible } = this.state;
    const { selectedGroup: { hasMessages } } = this.props;

    return (
      <div className="group-messages-panel">
        {hasMessages && <MessagesGroups />}

        {!hasMessages && (
          <React.Fragment>
            <Notifications
              texts={texts}
              onAnimationComplete={this.handleAnimationComplete}
            />
            {buttonVisible && (
              <ButtonStandard
                className="send-new-group-message"
                title={buttonText}
                onClick={this.handleNewMessage}
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mD = {
  openCustomModal,
  getMessages,
  clearFormGroup,
  updateRecipientGroups,
  enableRightDrawer,
  clearMessages,
};

const mS = state => ({
  messages: state.messagesReducer.messages,
  isMessagesLoading: state.messagesReducer.isMessagesLoading,
  isLoadedMessages: state.messagesReducer.isLoadedMessages,
  selectedGroup: state.groupsReducer.selectedGroup,
});

export default withRouter(connect(mS, mD)(GroupMessages));
