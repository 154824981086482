import AXIOS from '../Config/AxiosConfig';
import { redirectToLogin } from '../Helpers/redirectToLogin';
import { REQUEST_CANCELLED_MESSAGE } from '../Config/Constants';

export const setupInterceptors = () => {
  AXIOS.interceptors.response.use(
    response => response,
    error => {
      if (error.response !== undefined) {
        if (
          Object.prototype.hasOwnProperty.call(error.response, 'status') ||
          Object.prototype.hasOwnProperty.call(error.response, 'data')
        ) {
          const { status } = error.response;

          if (status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('sso_token');
            localStorage.removeItem('expiration');
            if (!/^\/login/.test(window.location.pathname)) {
              redirectToLogin();
            }
          }
        } else {
          localStorage.removeItem('token');
          localStorage.removeItem('sso_token');
          localStorage.removeItem('expiration');
          redirectToLogin();
        }
      } else if (error.message === REQUEST_CANCELLED_MESSAGE) {
        error.response = { data: { message: REQUEST_CANCELLED_MESSAGE } };
      } else {
        /* eslint-disable */
        error.response = { data: { message: 'Unknown Network Error' } };
        /* eslint-enable */
      }

      return Promise.reject(error.response.data);
    },
  );
};
