import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ContentEditable from 'react-contenteditable';
import moment from 'moment';
import Avatar from '../Avatar';
import speechBubble from '../../Theme/Icons/speech-bubble.svg';
import StartIcon from '../../Theme/Icons/start.png';
import CommentIcon from '../../Theme/Icons/mpa-comment.png';
import ReplyIcon from '../../Theme/Icons/mpa-reply.png';
import Helpers from '../../Helpers';
import MessageMyParishAppPreviewPropType from '../../PropTypes/MessageMyParishAppPreviewPropType';
import MessagePreviewToolbar from '../MessagePreviewToolbar';
import { withStyles } from '@material-ui/core/styles';
import './index.sass';

const styles = theme => ({
  starIcon: {
    width: '17px',
    height: '17px',
  },
  replyIcon: {
    width: '19px',
    height: '18px',
  },
  commentIcon: {
    width: '18px',
    height: '18px',
  },
});

class MessageMyParishAppPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      html: '',
    };
  }

  componentDidMount() {
    this.setState({ html: this.props.content });
  }

  handleChange = evt => {
    const value = evt.target.value;
    this.setState({ html: value});
    this.props.onChange(value);
  };

  renderPreview = () => (
    <div className="message-container">
      <div className="my-parish-app-content" id="scroll">
        <div
          className={`my-parish-app-message ${
            this.props.enabled ? 'show' : 'blur'
          }`}
        >
          <div className="my-parish-app-header">
            <Grid item xs={1} className="member-message-my-parish-app">
              <Avatar
                borderless
                className="my-parish-app-member-picture"
                image={this.props.profilePic}
              />
            </Grid>
            <div className="my-parish-app-header-text">
              <p className="my-parish-app-name">{this.props.profileName}</p>
              <div className="my-parish-app-lower-header">
                <p className="my-parish-app-comments-count">
                  Now | 0 Comments{' '}
                </p>
              </div>
            </div>
            <div className="my-parish-app-speech-bubble">
              <img src={speechBubble} alt="speech-bubble" />
              <span>Comment</span>
            </div>
          </div>
          <div className="my-parish-app-title">{this.props.subject}</div>
          <ContentEditable
            className="my-parish-app-body-text"
            id="scroll"
            tagName="p"
            disabled={!this.props.enabled}
            html={this.state.html}
            onChange={this.handleChange}
            onPaste={Helpers.handleOnlyTextPreviewPaste}
          />
        </div>
      </div>
    </div>
  );

  renderPreviewAllMembers = () => {
    const { classes, enabled } = this.props;

    return (
      <div className="message-container">
        <div className="my-parish-app-all-content" id="scroll">
          <div
            className={`my-parish-app-message ${
              enabled ? 'show' : 'blur'
            }`}
          >
            <div className="my-parish-app-header">
              <ContentEditable
                className="my-parish-app-body-text"
                id="scroll"
                tagName="p"
                disabled={!enabled}
                html={this.state.html}
                onChange={this.handleChange}
                onPaste={Helpers.handleOnlyTextPreviewPaste}
              />
              <div className="mpa-preview-icons-container">
                <div className="mpa-preview-icons">
                  <img
                    className={classes.starIcon}
                    src={StartIcon}
                    alt="speech-bubble"
                  />
                </div>
                <div className="mpa-preview-icons">
                  <img
                    className={classes.replyIcon}
                    src={ReplyIcon}
                    alt="speech-bubble"
                  />
                </div>
                <div className="mpa-preview-icons">
                  <img
                    className={classes.commentIcon}
                    src={CommentIcon}
                    alt="speech-bubble"
                  />
                </div>
              </div>
            </div>
            <div className="mpa-all-footer">
              {moment().format('MMM DD, h:mm A')} | Message
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { enabled, toAllMembers, allowed, onSendByChange } = this.props;

    return (
      <div>
        {allowed ? (
          <div className="send-my-parish-app-container">

            <MessagePreviewToolbar
              text="Send to myParish App"
              enabled={enabled}
              onChange={onSendByChange}
            />

            {toAllMembers
              ? this.renderPreviewAllMembers()
              : this.renderPreview()}
          </div>
        ) : (
          <div className="not-available">
            <p>
              Posting to myParish app is not currently enabled for this group(s).
              <br />
              Please check the group(s) settings or contact Diocesan for
              assistance enabling this feature.
            </p>
          </div>
        )}
      </div>
    );
  }
}
MessageMyParishAppPreview.propTypes = MessageMyParishAppPreviewPropType;

export default withStyles(styles)(MessageMyParishAppPreview);;
