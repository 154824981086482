import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import EvaTooltip from '../EvaTooltip';
import API_URL from '../../Config/env';
import { downloadFile } from '../../Helpers/download';
import './index.sass';

class StatisticsReport extends Component {
  handleDownload = (publicId, type) =>
    downloadFile(`${API_URL}/eva/messages/${publicId}/metrics/${type}/export/csv`, `${type}.csv`);

  handleBrowser = (publicId, type) => 
    window.open(`/messages/${publicId}/statistics?type=${type}`);
  

  render() {
    const {publicId, type} = this.props;
    return (
     <div className="statistics-report">
        <div className="report-buttons">
          <button
            className="download-link"
            onClick={(e) => this.handleDownload(publicId, type)}
          >
            Download
          </button>

          <button
            className="open-in-browser-link"
            onClick={(e) => this.handleBrowser(publicId, type)}
          >
            Open in Browser
          </button>
        </div>
      </div>
    );
  }
}
export default StatisticsReport;
