import React, { Component } from 'react';
import Avatar from '../Avatar';
import PhotoEditMember from '../../Theme/Icons/editprofile.svg';
import getMemberInfo from '../../Helpers/getMemberInfo';
import Helpers from '../../Helpers';
import LoaderOverlay from '../LoaderOverlay';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import ButtonSimple from '../ButtonSimple';
import PropTypes from 'prop-types';
import './index.sass';
import MemberPictureView from '../MemberPictureView';
import CountryPreview from '../CountryPreview';
import StatePreview from '../StatePreview';
import EvaButton from '../EvaButton';
import EvaTooltip from '../EvaTooltip';

function getPhoneIconUrl(phoneNumber) {
  if (phoneNumber) {
    phoneNumber = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumber && phoneNumber.isValid() && phoneNumber.country) {
      return `https://lipis.github.io/flag-icon-css/flags/4x3/${phoneNumber.country.toLowerCase()}.svg`;
    } else {
      return null;
    }
  }

  return null;
}

class MemberInfoPad extends Component {

  setCountry = selectedMember => selectedMember.country && selectedMember.country.name ? selectedMember.country.name : '';

  renderButtons = (birthday) => {
    const { onDirectClick, onShareClick } = this.props;
    return (
      <div className="member-info-actions">
        {(birthday && Helpers.getYears(birthday) >= 18) && (
          <div className="button-wrapper">
            <ButtonSimple text="Send direct message" onClick={onDirectClick}/>
          </div>
        )}

        <div className="button-wrapper">
          <button className="button-share-link" onClick={onShareClick}>Send profile link</button>
        </div>
      </div>
    );
  }

  render() {
    const { isSelf, isShowPasswordField, member, onClearBounceClick, showBounceInfo } = this.props;
    const birthday =
      moment(member.birthday || member.birthDate).isValid() &&
      moment(member.birthday || member.birthDate); //  TODO: provide correct prop name

    const phoneIconUrl = getPhoneIconUrl(member.phoneNumber);
    const bouncedMessage = member.bouncedMessage ?? 'Bounced message not available';

    return (
      <section className="member-info-pad">

        <MemberPictureView
          imageUrl={member.profilePictureUrl ? member.profilePictureUrl + `?t=${new Date().getTime()}` : member.profilePictureUrl}
          name={getMemberInfo(member).displayedFullName}
        />

        {showBounceInfo && member.isEmailBounced && (
          <div className="member-bounced-info">
            <div className="member-bounced-status">
              <div className="member-bounced-status-text">Status: Bounced</div>
              <EvaTooltip title={bouncedMessage}>
                <span className="icon-mark exclamation" />
              </EvaTooltip>
            </div>
            {!!onClearBounceClick && <EvaButton onClick={onClearBounceClick}>Clear bounce</EvaButton>}
          </div>
        )}

        <div className="member-info-fields">

          <div className="field-item field-email">{member.email}</div>
          <div className="field-item field-phone">
            {phoneIconUrl && <img src={phoneIconUrl} alt={member.country ? member.country.name : ''}/>}
            {member.phoneNumber}
          </div>
          <div className="field-item field-birthday">{birthday && Helpers.formatDate(birthday)}</div>
          <div className="field-item field-address">
            <div>{member.referenceAddress}</div>
            <div>
              {member.cityText && member.cityText}
              {member.cityText && ', '}
              {member.countryPublicId && (
                <>
                  <StatePreview countryId={member.countryPublicId} value={member.statePublicId} />,&nbsp;
                </>
              )}
              {member.countryPublicId && (
                <>
                  <CountryPreview value={member.countryPublicId} />,&nbsp;
                </>
              )}
              {member.postalCode}
            </div>
            <div>{this.setCountry(member)}</div>
          </div>

          {isShowPasswordField && <div className="field-item field-password">***********</div>}
        </div>

        {!isSelf && this.renderButtons(birthday)}

        {!member.publicId && <LoaderOverlay/>}
      </section>
    );
  }
}

export default MemberInfoPad;

MemberInfoPad.propTypes = {
  isSelf: PropTypes.bool,
  isShowPasswordField: PropTypes.bool,
  member: PropTypes.instanceOf(Object).isRequired, // TODO: provide Member type
  onDirectClick: PropTypes.func,
  onShareClick: PropTypes.func,
};

MemberInfoPad.defaultProps = {
  isSelf: false,
  isShowPasswordField: false,
  onDirectClick: () => {},
  onShareClick: () => {},
};
