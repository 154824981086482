import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getMessagesStatistics } from '../../Actions/messageActions';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { downloadFile } from '../../Helpers/download';
import API_URL from '../../Config/env';

import './index.sass';

class MessagesStatisticsPage extends Component {

  componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search);
    this.props.getMessagesStatistics(this.props.match.params.messageId, queryParams.type);
  }

  handleDownload = (publicId, type) =>
    downloadFile(`${API_URL}/eva/messages/${publicId}/metrics/${type}/export/csv`, `${type}.csv`);

  renderAllReport = () => (
    <table className="statistics-data">
      <thead>
      <tr className="header-table">
        <th>Member</th>
        <th>Name</th>
        <th>Status</th>
        <th>Email Status</th>
        <th>SMS Status</th>
      </tr>
      </thead>
      <tbody>
      {this.props.statistics.map((item, index) => {
        const { member, name, status, emailStatus, smsStatus } = item;
        return (
          <tr className="table-item" key={`dot-${index}`}>
            <td>{member}</td>
            <td>{name}</td>
            <td>{status}</td>
            <td>{emailStatus}</td>
            <td>{smsStatus}</td>
          </tr>);
      })}
      </tbody>
    </table>
  );

  renderClicksReport = () => (
    <table className="statistics-data">
      <thead>
      <tr className="header-table">
        <th>Link Url</th>
        <th>TotalClicks</th>
      </tr>
      </thead>
      <tbody>
      {this.props.statistics.map((item, index) => {
        const { linkUrl, totalClicks } = item;
        return (
          <tr className="table-item" key={`dot-${index}`}>
            <td>{linkUrl}</td>
            <td>{totalClicks}</td>

          </tr>);
      })}
      </tbody>
    </table>
  )

  renderEmailReport = () => (
    <table className="statistics-data">
      <thead>
      <tr className="header-table">
        <th>Email Adress</th>
        <th>Name</th>
      </tr>
      </thead>
      <tbody>
      {this.props.statistics.map((item, index) => {
        const { name, email } = item;
        return (
          <tr className="table-item" key={`dot-${index}`}>
            <td>{email}</td>
            <td>{name}</td>
          </tr>);
      })}
      </tbody>
    </table>
  );

  render() {
    const queryParams = queryString.parse(this.props.location.search);
    const { type } = queryParams;

    let header = '';
    let reportTable = '';

    switch (type) {
      case 'all':
        header = 'All Recipient';
        reportTable = this.renderAllReport();
        break;
      case 'clicks':
        header = 'Clicks';
        reportTable = this.renderClicksReport();
        break;
      case 'email-views':
        header = 'Email Views';
        reportTable = this.renderEmailReport();
        break;
    }

    return (
      <div className="fixed-wrapper" id="message-statistics-page">
        <div className="content-inner-wrapper">
          <div className="header-wrapper">
            <div className="header-text">{header} Report</div>

            <button onClick={() => this.handleDownload(this.props.match.params.messageId, queryParams.type)}>
              Download CSV
            </button>

          </div>

          {reportTable}
        </div>
      </div>
    );
  }
}

const mS = state => ({
  statistics: state.messageReducer.statistics,
});

export default withRouter(connect(mS, { getMessagesStatistics },)(MessagesStatisticsPage));
