import React, { Component } from 'react';
import { connect } from 'react-redux';
import ButtonStandard from '../../Components/Button';
import Notification from '../../Components/AnimatedNotification';
import { changeTab } from '../../Actions/middleMenuActions';
import { clearMember, getMemberFullInfo, setMemberError } from '../../Actions/memberActions';
import Groups from '../Groups';
import {
  addCaminoMembersToAll,
  clearMembersList,
  clearSelectedMembers,
  getGroupMembers,
  getMoreMembers,
  removeMembers,
  searchInMembersListWithDebounce,
  selectMember,
  updateImportMessage,
} from '../../Actions/membersActions';
import { changeMembersFilters, changeMembersSearchTerm, resetMembersFilters, toggleMembersFilters } from '../../Actions/membersFiltersActions';
import { getNotificationsMessages } from '../../Actions/notificationsActions';
import { updateSelectedGroupOnMembersRemove } from '../../Actions/groupsActions';
import MemberListPropType from '../../PropTypes/MemberListPropType';
import { enableRightDrawer } from '../../Actions/rightDrawerShortActions';
import snackBarStatus from '../../Actions/snackbarActions';
import ViewMember from '../ViewMember';
import CustomMessage from '../CustomMessage';
import MembersPicker from '../MembersPicker';
import { debounce } from '../../Helpers/debounce';
import getMemberInfo from '../../Helpers/getMemberInfo';
import MembersFilters from '../MembersFilters';
import { FilterButton } from '../FilterButton';
import './index.sass';

class MemberList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterString: '',
      remove: false,
      addToAll: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.clearSelectedMembers();
    this.getGroupMembers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedGroup.public_id !== this.props.selectedGroup.public_id) {
      this.props.clearSelectedMembers();
      this.props.resetMembersFilters();
      this.getGroupMembers();
    }
  }

  componentWillUnmount() {
    // TODO: move to container
    this.props.updateImportMessage({
      show: false,
      type: 'info',
      message: '',
    });
    this.props.clearMembersList();
    this.props.resetMembersFilters();
  }

  UNSAFE_componentWillReceiveProps(prevProps) {
    // TODO: move to container
    if (prevProps.selectedGroup.public_id !== this.props.selectedGroup.public_id) {
      this.props.setMemberError('');
    }
  }

  getGroupMembers = () => {
    const { filters, searchTerm, selectedGroup } = this.props;

    if (selectedGroup && selectedGroup.public_id) {
      this.props.clearMembersList();

      this.props.getGroupMembers(selectedGroup.public_id, searchTerm, false, 1).then(members => {
        if (members.length === 0 && !searchTerm && !filters.status) {
          this.props.changeTab(<Groups/>, 1);
        }
      });
    }
  };

  handleEditMemberClick = async (publicId) => {
    this.props.clearMember();
    this.props.getMemberFullInfo(publicId);
    this.props.enableRightDrawer({
      payload: {
        enable: true,
        element: <ViewMember/>,
        title: '',
        secondTitle: '',
        // afterUpdate: this.getGroupMembers, TODO: remove?
      },
    });
  };

  onMemberRemoveConfirm = () => {
    const { selectedGroup, members, selectedMembersHash } = this.props;
    const membersToRemoveNames = [];
    let names = '';

    /* eslint-disable no-underscore-dangle */
    Object.keys(selectedMembersHash).forEach(key => {
      const existMember = members.find(list => list.publicId === key);
      if (existMember) {
        const { firstName, lastName } = existMember;

        membersToRemoveNames.push({
          name: firstName && lastName ? `${firstName} ${lastName}` : getMemberInfo(existMember).sortString,
        });
      }
    });
    const last = membersToRemoveNames[membersToRemoveNames.length - 1];

    Object.keys(membersToRemoveNames).forEach(key => {
      if (membersToRemoveNames[key].name === last.name && membersToRemoveNames.length > 1) {
        names = `${names} and ${last.name}`;
      } else if (membersToRemoveNames.length === 1) {
        names += membersToRemoveNames[key].name;
      } else {
        names += `${membersToRemoveNames[key].name}, `;
      }
    });

    const arrayMessage = [];
    const nameGroup = selectedGroup.name;
    arrayMessage.push(`Are you sure you want to remove ${names} from ${nameGroup}?`);

    return (
      <React.Fragment>
        <div className="notification-messages-container">
          <Notification texts={arrayMessage}/>
        </div>
        <ButtonStandard
          className="remove-from-group"
          title="Remove from Group"
          onClick={this.onMembersRemove}
        />
        <br/>
        <ButtonStandard
          className="remove-from-group"
          style={{ display: 'initial' }}
          title="Cancel"
          onClick={this.handleMemberRemoveCancel}
        />
      </React.Fragment>
    );
  };

  handleAddCaminoMembersToAllConfirm = () => {
    const { selectedGroup, members, selectedMembersHash } = this.props;
    const membersToRemoveNames = [];
    let names = '';

    /* eslint-disable no-underscore-dangle */
    Object.keys(selectedMembersHash).forEach(key => {
      const existMember = members.find(list => list.publicId === key);
      if (existMember) {
        const { firstName, lastName } = existMember;

        membersToRemoveNames.push({
          name: firstName && lastName ? `${firstName} ${lastName}` : getMemberInfo(existMember).sortString,
        });
      }
    });
    const last = membersToRemoveNames[membersToRemoveNames.length - 1];

    Object.keys(membersToRemoveNames).forEach(key => {
      if (membersToRemoveNames[key].name === last.name && membersToRemoveNames.length > 1) {
        names = `${names} and ${last.name}`;
      } else if (membersToRemoveNames.length === 1) {
        names += membersToRemoveNames[key].name;
      } else {
        names += `${membersToRemoveNames[key].name}, `;
      }
    });

    const arrayMessage = [];
    const nameGroup = selectedGroup.name;
    arrayMessage.push('Would you like to add member to All members?');

    return (
      <React.Fragment>
        <div className="notification-messages-container">
          <Notification texts={arrayMessage}/>
        </div>
        <ButtonStandard
          className="remove-from-group"
          title="Yes"
          onClick={this.onAddCaminoMembersToAll}
        />
        <br/>
        <ButtonStandard
          className="remove-from-group"
          style={{ display: 'initial' }}
          title="No"
          onClick={this.handleAddCaminoMembersCancel}
        />
      </React.Fragment>
    );
  };

  handleSearchTermChange = value =>
    this.props.changeMembersSearchTerm(value).then(() => {
      this.props.searchInMembersListWithDebounce();
    });

  handleMemberRemoveCancel = () => {
    this.setState({
      remove: false,
    });
  };

  handleRemoveMembersOpen = () => {
    this.setState({
      remove: true,
    });
  };

  handleAddCaminoMembersCancel = () => {
    this.setState({
      addToAll: false,
    });
  };

  handleAddCaminoMembersOpen = () => {
    this.setState({
      addToAll: true,
    });
  };

  onMembersRemove = () => {
    const { selectedMembersHash, selectedGroup, afterMembersAction } = this.props;
    const selectedMembers = Object.keys(selectedMembersHash);

    if (selectedMembers.length > 0) {
      this.props
        .removeMembers(selectedGroup.public_id, selectedMembers)
        .then(res => {
          const { status, data: { data: { membersFound } } } = res;
          if (status === 200) {
            this.props.snackBarStatus({
              payload: {
                enable: true,
                title: 'Members Removed',
                type: 'success',
              },
            });
            this.props.updateSelectedGroupOnMembersRemove({
              publicId: selectedGroup.public_id, membersPublicIds: membersFound,
            });
            this.props.clearSelectedMembers();
            if (typeof afterMembersAction === 'function') {
              afterMembersAction();
            }
            this.setState({
              remove: false,
            });
            this.props.getNotificationsMessages();
          }
        })
        .catch(() => {
          this.props.snackBarStatus({
            payload: {
              enable: true,
              title: 'Error , try again',
              type: 'error',
            },
          });
        });
    }
  };

  onAddCaminoMembersToAll = () => {
    const { selectedMembersHash, organization } = this.props;
    const selectedMembers = Object.keys(selectedMembersHash);

    if (selectedMembers.length > 0) {
      this.props.addCaminoMembersToAll(organization.public_id, selectedMembers);
      this.setState({
        addToAll: false,
      });
      this.props.clearSelectedMembers();
    }
  };

  close = () => {
    this.props.updateImportMessage({
      show: false,
      message: '',
    });
  };

  handleLoadMore = () => {
    const { loading, loadingMore } = this.props;
    if (!loading && !loadingMore) {
      this.props.getMoreMembers();
    }
  }

  // TODO: review
  handleFiltersChange = filters =>
    this.props.changeMembersFilters(filters)
      .then(this.getGroupMembers);

  render() {
    /* eslint-disable no-shadow */
    const {
      filters,
      isFiltersOpen,
      selectedGroup,
      loading,
      loadingMore,
      selectedMembersHash,
      customMessage,
      afterMembersAction,
      members,
      searchTerm,
    } = this.props;
    const { remove, addToAll } = this.state;
    const name = (selectedGroup && (selectedGroup.name ? selectedGroup.name : '')) || '';
    const isAllMebersGroup = selectedGroup.organization_general_group;

    return (
      <React.Fragment>
        {remove && (<div id="members">{this.onMemberRemoveConfirm()}</div>)}
        {addToAll && (<div id="members">{this.handleAddCaminoMembersToAllConfirm()}</div>)}
        {(!remove && !addToAll) && (
          <section className="members-list" id="members">
            <header className="members-header">
              <div className="title-wrapper">
                <h3 className="title"> Members </h3>
                <div className="subtitle"> {name} </div>
              </div>
              {isAllMebersGroup && (
                <FilterButton
                  isActive={isFiltersOpen}
                  isFiltersSelected={filters.status}
                  onClick={() => this.props.toggleMembersFilters(!isFiltersOpen)}
                />
              )}
            </header>

            {customMessage.show && (
              <div className="members-custom-message">
                <CustomMessage
                  isButton={false}
                  type={customMessage.type}
                  title={customMessage.title}
                  message={customMessage.message}
                />
              </div>
            )}

            <MembersFilters
              filters={filters}
              isOpen={isFiltersOpen}
              onFiltersChange={this.handleFiltersChange}
            />

            <MembersPicker
              searchTerm={searchTerm}
              members={members}
              selectedMembersHash={selectedMembersHash}
              groupType={selectedGroup.type}
              isFiltered={filters.status || searchTerm}
              isFiltersOpen={isFiltersOpen}
              isAddButton
              isRemoveButton
              isLoading={loading}
              isLoadingMore={loadingMore}
              onAddMemberCallback={afterMembersAction}
              onAddCaminoMembersToAll={this.handleAddCaminoMembersOpen}
              onEditMemberClick={this.handleEditMemberClick}
              onSelectMember={this.props.selectMember}
              onRemoveSelected={this.handleRemoveMembersOpen}
              onSearchInputChange={this.handleSearchTermChange}
              onScrollFinished={this.handleLoadMore}
            />
          </section>
        )}
      </React.Fragment>
    );
  }
}

/* eslint-enable */

MemberList.propTypes = MemberListPropType;

const mS = state => ({
  selectedGroup: state.groupsReducer.selectedGroup,
  members: state.membersReducer.membersList,
  selectedMember: state.memberReducer.selectedMember,
  error: state.memberReducer.error,
  loading: state.membersReducer.loading,
  loadingMore: state.membersReducer.loadingMore,
  type: state.membersReducer.type,
  selectedMembersHash: state.membersReducer.selectedMembersHash,
  customMessage: state.membersReducer.customMessage,
  organization: state.organizationReducer.organization,
  filters: state.membersFiltersReducer.filters,
  isFiltersOpen: state.membersFiltersReducer.isFiltersOpen,
  searchTerm: state.membersFiltersReducer.searchTerm,
});

const mD = {
  setMemberError,
  enableRightDrawer,
  removeMembers,
  addCaminoMembersToAll,
  snackBarStatus,
  clearSelectedMembers,
  updateImportMessage,
  clearMembersList,
  changeTab,
  getGroupMembers,
  getMemberFullInfo,
  selectMember,
  clearMember,
  updateSelectedGroupOnMembersRemove,
  searchInMembersListWithDebounce,
  getMoreMembers,
  toggleMembersFilters,
  changeMembersFilters,
  changeMembersSearchTerm,
  resetMembersFilters,
  getNotificationsMessages,
};

export default connect(
  mS,
  mD,
)(MemberList);
