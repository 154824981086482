import React from 'react';
import CustomMessagePropType from '../../PropTypes/CustomMessagePropType';
import './index.sass';

const CustomMessage = ({ buttonMessageAction, handleClickAction, hideMessage, isButton, message, title, type }) => {
return (
  <div className={`custom-message-container ${type}`}>
    <p className="form-text title">{title}</p>
    <div className="form-text body" dangerouslySetInnerHTML={{ __html: message }} />
    {isButton && (
      <div className="button-container">
        <button
          className="button"
          onClick={handleClickAction}
        >
          {buttonMessageAction}
        </button>
      </div>
    )}
    {hideMessage && (
      <button
        onClick={hideMessage}
        className="form-close"
        type="button"
        aria-label="Close"
      >
        <i className="close-icon"/>
      </button>
    )}
  </div>
);
};

CustomMessage.propTypes = CustomMessagePropType;

export default CustomMessage;
