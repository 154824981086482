import React from 'react';
import PropTypes from 'prop-types';
import './index.sass';
import PhotoEditMember from '../../Theme/Icons/editprofile.svg';

const MemberPictureView = ({ imageUrl, name }) => (
  <div className="member-picture-view">
    <figure>
      <img className={`${!imageUrl ? 'no-image' : ''}`} src={imageUrl ? imageUrl : PhotoEditMember} alt="..."/>
      <figcaption>{name}</figcaption>
    </figure>
  </div>
);

export default MemberPictureView;

MemberPictureView.defaultProps = {
  imageUrl: null,
};

MemberPictureView.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
};
