export const ACTIONS = {
  OPEN: '[Custom modal] Open',
  CLOSE: '[Custom modal] Close',
  UPDATE_TITLE: '[Custom modal] Update title',
  UPDATE_SIZE: '[Custom modal] Update size',
  SET_CUSTOM_CLOSE_HANDLER: '[Custom modal] Set custom close handler',
  SET_BACK_HANDLER: '[Custom modal] Set back handler',
  STATUS: '[Custom modal] Status',
};

export const openCustomModal = params => dispatch => {
  dispatch({ type: ACTIONS.OPEN, payload: params });
};

export const closeCustomModal = () => dispatch => {
  dispatch({ type: ACTIONS.CLOSE });
};

export const updateCustomModalSize = size => dispatch =>
  dispatch({ type: ACTIONS.UPDATE_SIZE, payload: size });

export const updateCustomModalTitle = title => dispatch =>
  dispatch({ type: ACTIONS.UPDATE_TITLE, payload: title });

export const setCustomModalCloseHandler = customFunction => dispatch =>
  dispatch({ type: ACTIONS.SET_CUSTOM_CLOSE_HANDLER, payload: customFunction });

export const setCustomModalBackHandler = customFunction => dispatch =>
  dispatch({ type: ACTIONS.SET_BACK_HANDLER, payload: customFunction });

export const closeCustomModalFromHandler = () => dispatch => {
  setCustomModalCloseHandler(null)(dispatch);
  closeCustomModal()(dispatch);
};

export const updateCustomModal = obj => dispatch =>
  dispatch({ type: ACTIONS.STATUS, payload: obj });
