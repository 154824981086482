import React from 'react';
import DisplayModeSwitcher from '../DisplayModeSwitcher';
import AddMember from '../GroupCard/AddMember';
import PropTypes from 'prop-types';
import EvaTooltip from '../EvaTooltip';
import './index.sass';

const MemberActionsBar = (
  { groupType, isAllSelectButtons, isAddButton, isRemoveButton, isSelected, onAddMemberCallback, 
    onAddCaminoMembersToAll, onSelectAllMembers, onUnselectAllMembers, onRemoveSelected },
) => (
  <div className="members-actions-bar">
    <div className="left-side">
      {isAllSelectButtons && (
        <React.Fragment>
          <button className="selection-btn" onClick={onSelectAllMembers}>Select all</button>
          <button className="selection-btn" onClick={onUnselectAllMembers}>Unselect all</button>
        </React.Fragment>
      )}
    </div>

    <div className="right-side">
      {groupType === 'camino' &&
        <EvaTooltip title="Add to All Members Group" interactive={!isSelected}>
          <button className="icon-button add-to-all" disabled={!isSelected} onClick={onAddCaminoMembersToAll}>+All Member</button>
        </EvaTooltip>}
      {isRemoveButton &&
        <EvaTooltip title="Remove Members" interactive={!isSelected}>
          <button className="icon-button remove-button" disabled={!isSelected} onClick={onRemoveSelected} />
        </EvaTooltip>
      }
      {(isAddButton && groupType === 'eva') && <AddMember afterMembersAction={onAddMemberCallback}/>}
    </div>

  </div>
);

MemberActionsBar.propTypes = {
  isAddButton: PropTypes.bool,
  isRemoveButton: PropTypes.bool,
  isAllSelectButtons: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  onSelectAllMembers: PropTypes.func,
  onUnselectAllMembers: PropTypes.func,
  onAddMemberCallback: PropTypes.func,
  onRemoveSelected: PropTypes.func,
};

MemberActionsBar.defaultProps = {
  isAddButton: false,
  isRemoveButton: false,
  isAllSelectButtons: false,
  onSelectAllMembers: null,
  onUnselectAllMembers: null,
  onAddMemberCallback: null,
  onRemoveSelected: null,
};

export default MemberActionsBar;
