import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@mobiscroll/react';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';
import './index.sass';

class GroupsPicker extends PureComponent {
  constructor(props) {
    super(props);
    this.mbscInst = null;
  }

  saveMbscInst = (e, inst) => this.mbscInst = inst;

  handleGroupsUpdate = ({ value }) => this.props.onUpdate(value)

  render() {
    const { dictionary, isInvalid, values } = this.props;
    const data = dictionary
      .map(g => ({ text: g.label, value: g.value }))
      .sort((a, b) => {
        if (a.text.toLowerCase() > b.text.toLowerCase()) {
          return 1;
        }
        if (b.text.toLowerCase() > a.text.toLowerCase()) {
          return -1;
        }
        return 0;
      });

    // TODO: hide select box
    return (
      <div className="groups-picker">
        <div className={`select-wrapper ${isInvalid ? 'error' : ''}`}>
          <Select
            inputProps={{
              placeholder: 'Search Groups',
              dropdown: false,
            }}
            selectMultiple
            filter
            filterPlaceholderText="Search"
            setText="Select"
            dropdown={false}
            data={data}
            value={values}
            onInit={this.saveMbscInst}
            onChange={this.handleGroupsUpdate}
            buttons={['cancel']}
            responsive={mbscResponsiveOptions}
          />
        </div>
      </div>
    );
  }
}

GroupsPicker.propTypes = {
  dictionary: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  isInvalid: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
};

GroupsPicker.defaultProps = {
  isInvalid: false,
  values: [],
};

export default GroupsPicker;
