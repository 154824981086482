import { ACTIONS } from '../Actions/customModalActions';

const initialState = {
  backHandler: null, // Back button handler
  closeHandler: null, // Close button custom handler
  content: null,
  isLoader: false,
  isOpen: false,
  size: 'default', // 'default' | 'small'
  title: '',
};

const CustomModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.STATUS:
      return {
        ...state,
        ...action.payload,
      };

    case ACTIONS.OPEN: {
      const newState = {
        isOpen: true,
      };

      if (action.payload) {
        const { content, size, title } = action.payload;
        if (content) {
          newState.content = content;
        }

        if (title) {
          newState.title = title;
        }

        newState.size = size ? size : 'default';
      }

      return {
        ...initialState,
        ...newState,
      };
    }

    case ACTIONS.CLOSE:
      return {
        ...state,
        isOpen: false,
      };

    case ACTIONS.UPDATE_SIZE:
      return {
        ...state,
        size: action.payload,
      };

    case ACTIONS.UPDATE_TITLE:
      return {
        ...state,
        title: action.payload,
      };

    case ACTIONS.SET_CUSTOM_CLOSE_HANDLER:
      return {
        ...state,
        closeHandler: action.payload,
      };

    case ACTIONS.SET_BACK_HANDLER:
      return {
        ...state,
        backHandler: action.payload,
      };

    default:
      return state;
  }
};

export default CustomModalReducer;
