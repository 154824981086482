import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiltersPosedWrapper } from '../FilterPosedWrapper';
import './index.sass';

class GroupsFilters extends Component {

  handleFilterChange = (type, name) => {
    const { filters, onFiltersChange } = this.props;
    const isActive = filters[type].includes(name);
    const updFilters = {
      ...filters,
      [type]: isActive ? filters[type].filter(t => t !== name) : filters[type].concat([name]),
    };

    onFiltersChange(updFilters);
  }

  handleFiltersGroupReset = (filterGroupName) => {
    const { filters, onFiltersChange } = this.props;
    if (filters[filterGroupName].length > 0) {
      const updFilters = {
        ...filters,
        [filterGroupName]: [],
      };

      onFiltersChange(updFilters);
    }
  }

  isFilterEnabled = (type, name) => this.props.filters[type].includes(name);

  activeClassGenerator = (type, name) => this.isFilterEnabled(type, name) ? 'active' : ''

  render = () => (
    <FiltersPosedWrapper className="filters-pad group-filters-pad" pose={this.props.isOpen ? 'open' : 'closed'}>

      <div className="filters-inner-wrapper">

        <section className="filters-group">
          <div className="filters-group-title">Group Type</div>
          <button className="btn-all" onClick={() => {
            this.handleFiltersGroupReset('privacyLevel');
          }}>
            All
          </button>

          <div className="filters-wrapper">
            <div className="short-view">
              <button
                className={`btn-filter-icon icon-filter-hidden ${this.activeClassGenerator('privacyLevel', 'hidden')}`}
                onClick={() => this.handleFilterChange('privacyLevel', 'hidden')}
              />
              <button
                className={`btn-filter-icon icon-filter-closed ${this.activeClassGenerator('privacyLevel', 'closed')}`}
                onClick={() => this.handleFilterChange('privacyLevel', 'closed')}
              />
              <button
                className={`btn-filter-icon icon-filter-open ${this.activeClassGenerator('privacyLevel', 'open')}`}
                onClick={() => this.handleFilterChange('privacyLevel', 'open')}
              />
              <button
                className={`btn-filter-icon ${this.activeClassGenerator('groupType', 'camino')}`}
                onClick={() => this.handleFilterChange('groupType', 'camino')}
              />
            </div>

            <div className="extended-view">
              <button
                className={`filter-button with-icon ${this.activeClassGenerator('privacyLevel', 'hidden')}`}
                onClick={() => this.handleFilterChange('privacyLevel', 'hidden')}
              >
                Hidden
                <i className="icon-filter-hidden" />
              </button>
              <button
                className={`filter-button with-icon ${this.activeClassGenerator('privacyLevel', 'closed')}`}
                onClick={() => this.handleFilterChange('privacyLevel', 'closed')}
              >
                Closed
                <i className="icon-filter-closed" />
              </button>
              <button
                className={`filter-button with-icon ${this.activeClassGenerator('privacyLevel', 'open')}`}
                onClick={() => this.handleFilterChange('privacyLevel', 'open')}
              >
                Open
                <i className="icon-filter-open"  />
              </button>
              <button
                className={`filter-button ${this.activeClassGenerator('groupType', 'camino')}`}
                onClick={() => this.handleFilterChange('groupType', 'camino')}
              >
                Camino
              </button>
            </div>
          </div>
        </section>

        {/*<section className="filters-group">*/}
        {/*  <div className="filters-group-title">Activity</div>*/}
        {/*  <button*/}
        {/*    className='btn-all'*/}
        {/*    onClick={() => {*/}
        {/*      this.handleFiltersGroupReset('activity')*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    All*/}
        {/*  </button>*/}

        {/*  <div className="filters-wrapper">*/}
        {/*    <button*/}
        {/*      className={`filter-button ${this.activeClassGenerator('activity', 'engaged')}`}*/}
        {/*      onClick={() => this.handleFilterChange('activity', 'engaged')}*/}
        {/*    >*/}
        {/*      Engaged*/}
        {/*    </button>*/}

        {/*    <button*/}
        {/*      className={`filter-button ${this.activeClassGenerator('activity', 'active')}`}*/}
        {/*      onClick={() => this.handleFilterChange('activity', 'active')}*/}
        {/*    >*/}
        {/*      Active*/}
        {/*    </button>*/}

        {/*    <button*/}
        {/*      className={`filter-button ${this.activeClassGenerator('activity', 'static')}`}*/}
        {/*      onClick={() => this.handleFilterChange('activity', 'static')}*/}
        {/*    >*/}
        {/*      Static*/}
        {/*    </button>*/}

        {/*    <button*/}
        {/*      className={`filter-button ${this.activeClassGenerator('activity', 'inactive')}`}*/}
        {/*      onClick={() => this.handleFilterChange('activity', 'inactive')}*/}
        {/*    >*/}
        {/*      Inactive*/}
        {/*    </button>*/}
        {/*  </div>*/}

        {/*</section>*/}

        <section className="filters-group">
          <div className="filters-group-title">Share Options</div>
          <button
            className='btn-all'
            onClick={() => {
              this.handleFiltersGroupReset('shareOptions');
            }}
          >
            All
          </button>

          <div className="filters-wrapper">
            <button
              className={`filter-button ${this.activeClassGenerator('shareOptions', 'facebook')}`}
              onClick={() => this.handleFilterChange('shareOptions', 'facebook')}
            >
              Facebook
            </button>

            <button
              className={`filter-button ${this.activeClassGenerator('shareOptions', 'twitter')}`}
              onClick={() => this.handleFilterChange('shareOptions', 'twitter')}
            >
              Twitter
            </button>

            <button
              className={`filter-button ${this.activeClassGenerator('shareOptions', 'mpa')}`}
              onClick={() => this.handleFilterChange('shareOptions', 'mpa')}
            >
              myParish
            </button>

            <button
              className={`filter-button ${this.activeClassGenerator('shareOptions', 'web')}`}
              onClick={() => this.handleFilterChange('shareOptions', 'web')}
            >
              Web
            </button>
          </div>
        </section>

      </div>
    </FiltersPosedWrapper>
  )

}

GroupsFilters.propTypes = {
  filters: PropTypes.objectOf(PropTypes.array).isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default GroupsFilters;

