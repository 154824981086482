import { ACTIONS } from '../Actions/composeActions';

const initialState = {
  composeChildren: null,
  isBackButton: false,
  backTitle: '',
  titleHeader: 'New Message',
  buttons: null,
  isEditDraft: false,
  isMPALoginCompose: false,
  messageDraft: {},
  Authgroup: {},
  composerActiveState: {
    hidden: false,
    simple: false,
    selectedMessage: false,
    thread: false,
  },
};

const composeReducer = (state = initialState, action) => {
  switch (action.type) {

    case ACTIONS.UPDATE_COMPOSER_STATE:
      return {
        ...state,
        composerActiveState: {
          ...initialState.composerActiveState,
          ...action.payload,
        },
      };

    case ACTIONS.UPDATE_MPA_LOGIN_STATUS:
      return {
        ...state,
        isMPALoginCompose: action.payload,
      };

    case ACTIONS.CLEAR:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default composeReducer;
