import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import Hidden from '@material-ui/core/Hidden';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TemplateEmailPreview from '../../Components/TemplateEmailPreview';
import TemplateSmsPreview from '../../Components/TemplateSmsPreview';
import TemplateWebPreview from '../../Components/TemplateWebPreview';
import TemplateFacebookPreview from '../../Components/TemplateFacebookPreview';
import TemplateTwitterPreview from '../../Components/TemplateTwitterPreview';
import TemplateMyParishAppPreview from '../../Components/TemplateMyParishAppPreview';
import TemplatePreviewPropType from '../../PropTypes/TemplatePreviewPropType';
import EvaTooltip from '../../Components/EvaTooltip';
import LoaderOverlay from '../../Components/LoaderOverlay';
import AttachmentsPreview from '../../Components/AttachmentsPreview';
import './index.sass';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    '@media only screen and (min-width: 960px)': {
      marginRight: '36px !important',
      marginLeft: '41px !important',
    },
    '@media only screen and (min-width: 600px) and (max-width: 959px)': {
      marginRight: '91px !important',
      marginLeft: '92px !important',
    },
    '@media only screen and (max-width: 599px)': {
      marginRight: '15px !important',
      marginLeft: '19px !important',
    },
  },
  centered: {
    justifyContent: 'center',
  },
  wrapper: {},
  tab: {
    color: theme.palette.background.paper,
  },
  tabsIndicator: {
    background: 'linear-gradient(to right, #9966bf 0%, #6174c9 98%)',
  },
  tabsRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    color: '#a9a9a9',
    width: '100%',

    '&:focus': {
      color: '#9a66bf',
    },
  },
  tabSelected: { color: '#9a66bf' },
  iconColor: {
    color: '#a9a9a9',
    width: '28.8px',
    height: '28.8px',
  },
  iconColorActive: {
    color: '#9a66bf',
    width: '28.8px',
    height: '28.8px',
  },
  grayBorder: {
    borderBottom: '2px #f3f3f3 solid',
  },
  iconTabTemplateDetail: {
    width: '40px',
    height: '40px',
    display: 'inline-flex',
    alignItems: 'center',
    justiyContent: 'center',
    marginTop: '-6px',
  },
});

const SMS_PREVIEW_TAB = 1;
const EMAIL_PREVIEW_TAB = 0;
const MY_PARISH_PREVIEW_TAB = 2;
const FACEBOOK_PREVIEW_TAB = 3;
const TWITTER_PREVIEW_TAB = 4;
const WEB_PREVIEW_TAB = 5;

const TabContainer = props => <Typography component="div">{props.children}</Typography>;
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class TemplatePreview extends PureComponent {
  state = {
    value: 0,
    open: false,
  };

  UNSAFE_componentWillMount() {
    if (this.isFolder('Featured') || this.isFolder('Shared with me')) {
      this.handleOpen();
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  isFolder = mainFolder => {
    const { typeFolder, parentFolder } = this.props;

    if (!typeFolder && !parentFolder && mainFolder === 'Featured') return true;

    if (
      (typeFolder === mainFolder && parentFolder === '') ||
      (typeFolder !== '' && parentFolder === mainFolder)
    ) {
      return true;
    }

    return false;
  };

  handleOpen = () => {
    const { selectedTemplate } = this.props;
    const { open } = this.state;
    const { comments } = selectedTemplate;
    if (comments) this.setState({ open: true });

    this.renderComments();
    if (open === true) {
      this.setState({ open: false });
    }
  };
  // eslint-disable-next-line consistent-return
  renderComments = () => {
    const { selectedTemplate } = this.props;
    const { open } = this.state;
    const { comments } = selectedTemplate;
    if (open === true) {
      return (
        <div className="template-comments">
          <div className="template-comment">
            <p
              style={{
                margin: '1px',
                overflowWrap: 'break-word',
                fontSize: '16px',
                fontFamily: 'GothamSSm-Book',
              }}
            >
              <strong>Comments: </strong>
              {comments}
            </p>
          </div>
        </div>
      );
    }
  };

  render() {
    const { value } = this.state;
    const {
      classes, selectedTemplate, profilePicture, profileName } = this.props;

    if (!selectedTemplate || !selectedTemplate.messageContent) {
      return <LoaderOverlay />;
    }

    const { messageContent: {
      emailContent, facebookContent, mpaContent, smsContent, twitterContent, webContent,
    } } = selectedTemplate;

    const smsPreview = (smsContent.prefix ? smsContent.prefix : '') + (smsContent.text ? smsContent.text : '');

    return (
      <div className={'tpl-preview message-preview-container reduced-margin '}>
        <Hidden smDown>
          <div className={'comments-pad/TemplateSettings/index.jsx:39'}>
            {selectedTemplate.comments && this.renderComments()}
          </div>
        </Hidden>
        <div className={`tabs-container ${classes.grayBorder}`}>
          <Tabs
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
            value={value}
            onChange={this.handleChange}
          >
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
              label=""
              icon={
                <EvaTooltip title="Email">
                  <i className="email-icon"/>
                </EvaTooltip>
              }
            />
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
              label=""
              icon={
                <EvaTooltip title="Texting">
                  <i className="sms-icon"/>
                </EvaTooltip>
              }
            />
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
              label=""
              icon={
                <EvaTooltip title="myParish App">
                  <i className="t-icon"/>
                </EvaTooltip>
              }
            />
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
              label=""
              icon={
                <EvaTooltip title="Facebook">
                  <i className="facebook-icon"/>
                </EvaTooltip>
              }
            />
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
              label=""
              icon={
                <EvaTooltip title="X">
                  <i className="x-icon"/>
                </EvaTooltip>
              }
            />
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
              label=""
              icon={
                <EvaTooltip title="Web">
                  <i className="web-icon"/>
                </EvaTooltip>
              }
            />
          </Tabs>
          <Hidden smDown>
            {selectedTemplate.comments &&
            (this.isFolder('Favorites') || this.isFolder('My Templates') ||
              this.isFolder('Featured') || this.isFolder('Shared with me')) && (
              <div className={classes.iconTabTemplateDetail}>
                <EvaTooltip title="View Comments">
                  <ErrorIcon
                    className={
                      this.state.open === true ? classes.iconColorActive : classes.iconColor
                    }
                    onClick={this.handleOpen}
                  />
                </EvaTooltip>
              </div>
            )}
          </Hidden>
        </div>
        {value === EMAIL_PREVIEW_TAB && (
          <TabContainer>
            <TemplateEmailPreview content={emailContent.text}/>
          </TabContainer>
        )}
        {value === SMS_PREVIEW_TAB && (
          <TabContainer>
            <TemplateSmsPreview content={smsPreview}/>
          </TabContainer>
        )}
        {value === MY_PARISH_PREVIEW_TAB && (
          <TabContainer>
            <TemplateMyParishAppPreview
              content={mpaContent.text}
              profilePicture={profilePicture}
              profileName={profileName}
            />
          </TabContainer>
        )}
        {value === FACEBOOK_PREVIEW_TAB && (
          <TabContainer>
            <TemplateFacebookPreview
              content={facebookContent.text}
              picture={facebookContent.image}
              profilePicture={profilePicture}
              profileName={profileName}
            />
          </TabContainer>
        )}
        {value === TWITTER_PREVIEW_TAB && (
          <TabContainer>
            <TemplateTwitterPreview
              content={twitterContent.text}
              picture={twitterContent.image}
              profilePicture={profilePicture}
              profileName={profileName}
            />
          </TabContainer>
        )}
        {value === WEB_PREVIEW_TAB && (
          <TabContainer>
            <TemplateWebPreview content={webContent.text}/>
          </TabContainer>
        )}
        {this.props.selectedTemplate?.files && this.props.selectedTemplate?.files.length > 0 && (
          <AttachmentsPreview files={this.props.selectedTemplate.files} />
        )}
      </div>
    );
  }
}

TemplatePreview.propTypes = TemplatePreviewPropType;

export default withStyles(styles)(TemplatePreview);
