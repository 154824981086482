import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ContentEditable from 'react-contenteditable';
// Proptypes
import PropTypes from 'prop-types';
// Components
import EvaTooltip from '../EvaTooltip';
// Action Creators
import { CONSTANTS } from '../../Actions/messageActions';
// Styles
import emailPreviewstyles from './styles';
import MessageEmailPreviewPropType from '../../PropTypes/MessageEmailPreviewPropType';
import MessagePreviewToolbar from '../MessagePreviewToolbar';
import GenericMessage from '../GenericMessage';
import './index.sass';

const TabContainer = props => <Typography component="div">{props.children}</Typography>;

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => emailPreviewstyles(theme);

class MessageEmailPreview extends Component {
  state = {
    value: 0,
  };

  disabledMessage = 'Disabling Email may restrict some of your group members from receiving this message.';

  handleChange = (event, value) => {
    this.setState({ value });
  };

  renderViewportTabs = () => {
    const { value } = this.state;
    const { classes } = this.props;
    return (
      <div className="responsive-preview-icons">
        <Tabs
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator,
          }}
          value={value}
          onChange={this.handleChange}
        >
          <Tab
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
            label=""
            icon={
              <EvaTooltip title="Desktop Preview">
                <i className="desktop-icon"/>
              </EvaTooltip>
            }
          />
          <Tab
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
            label=""
            icon={
              <EvaTooltip title="Mobile Phone Preview">
                <i className="mobile-icon"/>
              </EvaTooltip>
            }
          />
        </Tabs>
      </div>
    );
  }
  render() {
    const { value } = this.state;
    const { allowed, content, enabled, isPreventChange, onSendByChange, onChange } = this.props;
    return (
      <div className="message-email-container" id="message-email-container">
        <div className="send-email-container">
          <MessagePreviewToolbar
            text="Send as Email"
            enabled={enabled && allowed}
            isPreventChange={isPreventChange}
            onChange={onSendByChange}
          >
            {this.renderViewportTabs()}
          </MessagePreviewToolbar>

          {!enabled && (
            <div className="message-container">
              <GenericMessage
                hideMessage={this.close}
                type="info"
                title="Warning"
                message={this.disabledMessage}
              />
            </div>
          )}

        </div>
        <div className="page-container designerContent" id="email-page-container">
          <TabContainer>
            {value === 0 ? <div className="content" id="scroll" dangerouslySetInnerHTML={{__html: content}} /> : <iframe id="scroll" srcDoc={content} />}
          </TabContainer>
        </div>
      </div>
    );
  }
}

MessageEmailPreview.propTypes = MessageEmailPreviewPropType;

export default withStyles(styles)(MessageEmailPreview);
