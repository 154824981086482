import React, { Component } from 'react';
import { connect } from 'react-redux';
import EvaTooltip from '../../Components/EvaTooltip';
import { getActiveClass } from '../../Helpers/getActiveClass';
import './index.sass';

class MessageChannels extends Component {

  render() {
    const {
      allowedChannels: { email, facebook, mpa, sms, twitter, web },
      isInvalid,
      sendByEmail, sendByFacebook, sendByMpa, sendBySms, sendByTwitter, sendByWeb,
    } = this.props;

    return (
      <div id="message-channels" className={isInvalid ? 'invalid' : ''}>
        <EvaTooltip title="Email">
          <i className={`channel-email ${getActiveClass(email && sendByEmail)}`}/>
        </EvaTooltip>
        <EvaTooltip title="Texting">
          <i className={`channel-sms ${getActiveClass(sms && sendBySms)}`}/>
        </EvaTooltip>
        <EvaTooltip title="myParish App">
          <i className={`channel-mpa ${getActiveClass(mpa && sendByMpa)}`}/>
        </EvaTooltip>
        <EvaTooltip title="Facebook">
          <i className={`channel-facebook ${getActiveClass(facebook && sendByFacebook)}`}/>
        </EvaTooltip>
        <EvaTooltip title="X">
          <i className={`channel-x ${getActiveClass(twitter && sendByTwitter)}`}/>
        </EvaTooltip>
        <EvaTooltip title="Web">
          <i className={`channel-web ${getActiveClass(web && sendByWeb)}`}/>
        </EvaTooltip>
      </div>
    );
  }
}

const mS = state => ({
  selectedGroup: state.groupsReducer.selectedGroup,

  recipientType: state.messageReducer.recipientType,
  selectedMember: state.messageReducer.selectedMember,
  allowedChannels: state.messageReducer.allowedChannels,
  sendByEmail: state.messageReducer.sendByEmail,
  sendByFacebook: state.messageReducer.sendByFacebook,
  sendByMpa: state.messageReducer.sendByMpa,
  sendBySms: state.messageReducer.sendBySms,
  sendByTwitter: state.messageReducer.sendByTwitter,
  sendByWeb: state.messageReducer.sendByWeb,
});

export default connect(mS, null)(MessageChannels);
