import { ACTIONS } from '../Actions/groupInviteMemberActions';

const initialState = {
  steps: [{ phone: '', email: '' }, {}, {}],
  currentStep: 0,
  members: [],
  selectedMembers: [],
  permissionToAdd: false,
  groups: [],
  matchPermissionToAdd: false,
};

const groupInviteMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CREATE:
      return action.payload;
    case ACTIONS.CLEAR:
      return { ...state, ...initialState };
    case ACTIONS.UPDATE:
      return { ...state, ...action.payload };
    case ACTIONS.UPDATE_GROUPS:
      return { ...state, groups: action.payload };

    case ACTIONS.REMOVE_GROUP:
      return {
        ...state,
        groups: state.groups.filter(publicId => publicId !== action.payload),
      };
    case ACTIONS.SELECT_MEMBER:
      return {
        ...state,
        selectedMembers: state.selectedMembers.includes(action.payload) ?
          state.selectedMembers.filter(i => i !== action.payload) :
          state.selectedMembers.concat(action.payload),
      };
    default:
      return state;
  }
};

export default groupInviteMemberReducer;
