import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';
import { Select } from '@mobiscroll/react';
import { withStyles } from '@material-ui/core/styles';
import style from '../EditMember/styles';
import { getCountries } from '../../Actions/countriesDictionaryActions';

const CountryPicker = ({
  className = '', data = [], placeholder = 'Country', isLoading = false, disabled = false, value,
  getCountries, onChange,
}) => {
  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (!value && data.length === 1) {
      onChange(data[0].value);
    }
  }, [data]);

  return (
    <div className={className}>
      <Select
        disabled={disabled || isLoading || data.length <= 1}
        inputProps={{
          cssClass: 'dropdown-country-picker',
        }}
        data={data}
        value={value}
        onChange={(o) => onChange(o?.value)}
        name="country"
        responsive={mbscResponsiveOptions}
        placeholder={placeholder}
      />
    </div>
  );
};

const mS = state => ({
  data: state.countriesDictionaryReducer.list,
  isLoading: state.countriesDictionaryReducer.isLoading,
});

const mD = { getCountries };

export default connect(mS, mD)(withStyles(style)(CountryPicker));
