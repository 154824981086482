import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  requestPublicOrganizationAdminPromotionInfoAction,
  requestPublicOrganizationSuperAdminPromotionInfoAction,
} from '../../Actions/invitationActions';
import organizationImage from '../../Theme/Icons/org-icon.svg';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import { logout } from '../../Actions/loginActions';
import InviteScreen from '../../Components/InviteScreen';
import getMemberInfo from '../../Helpers/getMemberInfo';
import { camelCaseObjects } from '../../Helpers/Utility';
import { redirectToLogin } from '../../Helpers/redirectToLogin';
import LoadingScreen from '../../Components/LoadingScreen';
import { isEmpty } from '../../Helpers/ObjectUtilities';

class InvitationOrgAdmin extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isInvitedUserLogged: false,
      isUserLogged: false,
    };
  }

  componentDidMount() {
    const {
      superAdmin,
      match: {
        params: { publicId, invitationToken },
      },
    } = this.props;

    const infoActionMethod = superAdmin ? this.props.requestPublicOrganizationSuperAdminPromotionInfoAction
      : this.props.requestPublicOrganizationAdminPromotionInfoAction;

    infoActionMethod(publicId, invitationToken);
  }

  static getDerivedStateFromProps = nextProps => {
    const { loggedMember, member } = nextProps;
    return {
      isInvitedUserLogged: member && loggedMember && member.public_id && member.public_id === loggedMember.public_id,
      isUserLogged: !!loggedMember.public_id,
    };
  };

  handleLogin = () => {
    if (this.state.isUserLogged) {
      this.props.logout(this.props.location.pathname);
    } else {
      redirectToLogin(this.props.location.pathname);
    }
  };

  handleLogout = () => this.props.logout(this.props.location.pathname);

  handleJoin = () => {
    const { publicId } = this.props.match.params;
    const url = `/invitation-submit/organization/${publicId}`;

    if (this.state.isUserLogged) {
      this.props.logout(url);
    } else {
      this.props.navigationControllerRedirect(url);
    }
  };

  handleRegister = () => {
    const {
      match: {
        params: { publicId, invitationToken },
      },
      organization,
    } = this.props;

    let allMembersGroupId = (organization.all_members_group) ?
      organization.all_members_group.public_id : publicId;

    this.props.navigationControllerRedirect(`/complete-profile/${allMembersGroupId}/${invitationToken}`);
  };

  render() {
    const { intl, organization, member, superAdmin, notFound } = this.props;

    let displayedName = getMemberInfo(camelCaseObjects(member)).displayedFullName;

    if (isEmpty(organization)) {
      return <LoadingScreen/>;
    }

    if (isEmpty(organization) && notFound) {
      this.props.navigationControllerRedirect('/not-found');
    }

    const picture = organization.main_picture_url ?
      organization.main_picture_url : organizationImage;
    const title = organization ? organization.name : 'Nameless Org';

    const role = `${superAdmin ? 'Super ' : ''}Admin`;

    return (
      <Scrollbars autoHeight autoHeightMin="100vh">
        <InviteScreen
          title={title}
          pictureUrl={picture}
          inviteTextHtml={`You have been promoted to <br />Organization ${role} of:`}
          registerButtonText={intl.formatMessage({ id: 'action.registerToJoin' })}
          loginButtonText={intl.formatMessage({ id: 'action.login' })}
          userName={displayedName}
          isInvitedUserLogged={this.state.isInvitedUserLogged}
          isActiveUser={member.status === 'active'}
          onRegister={this.handleRegister}
          onJoin={this.handleJoin}
          onLogin={this.handleLogin}
          onLogout={this.handleLogout}
        />
      </Scrollbars>
    );
  }
}

const mS = state => ({
  organization: state.invitationReducer.organization,
  member: state.invitationReducer.member,
  loggedMember: state.userProfileReducer.member,
  notFound: state.invitationReducer.notFound,
});


const mD = {
  requestPublicOrganizationAdminPromotionInfoAction,
  requestPublicOrganizationSuperAdminPromotionInfoAction,
  navigationControllerRedirect,
  logout,
};

InvitationOrgAdmin.propTypes = {
  superAdmin: PropTypes.bool,
};

export default connect(
  mS,
  mD,
)(injectIntl(InvitationOrgAdmin));
