import React, { Component } from 'react';
import { connect } from 'react-redux';
// Components
import GroupInviteMembers from '../../Containers/GroupInviteMembers';
import EvaTooltip from '../EvaTooltip';
// Action Creators
import { changeTab } from '../../Actions/middleMenuActions';
import { updateMember } from '../../Actions/memberActions';
import { getGroupMembers } from '../../Actions/membersActions';
import { update } from '../../Actions/groupInviteMemberActions';
import { selectGroup } from '../../Actions/groupsActions';

// Proptypes
import AddMemberPropType from '../../PropTypes/AddMemberPropType';
import MemberList from '../MemberList';
import GroupComponent from '../Groups';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

// TODO: Refactor to a presentational component.
class AddMember extends Component {
  handleClick = e => {
    e.stopPropagation();
    e.preventDefault();

    const { group } = this.props;

    const groupsIdsToAdd = [];
    if (group) {
      groupsIdsToAdd.push(group.public_id);
    } else if (this.props.selectedGroup) {
      groupsIdsToAdd.push(this.props.selectedGroup.public_id);
    }

    this.props.update({ groups: groupsIdsToAdd });

    if (this.props.handleSelectEvaCard && group) {
      this.props.handleSelectEvaCard(e, group, true);
    }
    this.props.updateMember({ fromGroup: true, type: 'group' });
    this.props.changeTab(this.renderGroupInviteTab(), 1);
  };

  handleBack() {
    const { selectedGroup } = this.props;
    if (selectedGroup) {
      this.props.selectGroup(selectedGroup);
      this.props.getGroupMembers(selectedGroup.public_id, '', false, 1);
      if (selectedGroup.members.length > 0) {
        this.props.changeTab(<MemberList/>, 1);
      } else {
        this.props.changeTab(<GroupComponent/>, 1);
      }
    } else this.props.changeTab(<GroupComponent/>, 1);
  }

  renderGroupInviteTab = () => {
    return (
      <div>
        <div className="group-invite-header">
          <IconButton style={{ fontSize: '32px', padding: '0px' }} onClick={this.handleBack.bind(this)}>
            <KeyboardArrowLeft/>
          </IconButton>
          <span className="back-title">Add members</span>
        </div>
        <GroupInviteMembers afterMembersAction={this.props.afterMembersAction}/>
      </div>
    );
  }

  render() {
    return (
      <EvaTooltip title="Add Member">
        <button className={`icon-button new-member ${this.props.className}`} onClick={this.handleClick}/>
      </EvaTooltip>
    );
  }
}

AddMember.propTypes = AddMemberPropType;

AddMember.defaultProps = {
  className: '',
};

const mS = state => ({
  selectedGroup: state.groupsReducer.selectedGroup,
});

const mD = {
  changeTab,
  getGroupMembers,
  selectGroup,
  update,
  updateMember,
};

export default connect(
  mS,
  mD,
)(AddMember);
