import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Helpers from '../../Helpers';
import { ALPHABET } from '../../Config/Constants';
import MemberItem from '../MemberItem';
import getMemberInfo from '../../Helpers/getMemberInfo';
import LoaderOverlay from '../LoaderOverlay';
import { debounce } from '../../Helpers/debounce';
import './index.sass';

/* eslint-disable no-underscore-dangle */
class MembersAlphabeticList extends PureComponent {
  constructor(props) {
    super(props);
    this.callScrollEndWithDebounce = debounce(this.props.onScrollFinished, 100);
  }
  renderMembersList = members => {
    const { viewMode = 'list', onEditMemberClick, onSelectMember, selectedMembersHash } = this.props;
    return members.map(
      member => {
        const { firstName, publicId, profilePictureUrl } = member;
        const { hasFirstName, hasLastName, lastName, sortString, isEmailBounced } = getMemberInfo(member);
        return (
          <MemberItem
            key={publicId}
            id={publicId}
            firstName={hasLastName && hasFirstName ? firstName : ''}
            lastName={hasLastName ? lastName : sortString}
            pictureUrl={profilePictureUrl}
            viewMode={viewMode}
            isChecked={!!selectedMembersHash[publicId]}
            onEditMember={onEditMemberClick}
            onSelectMember={onSelectMember}
            isEmailBounced={isEmailBounced}
          />
        );
      },
    );
  };

  renderMembersGroupByLetter = (letter, members) => {
    const { isLoading } = this.props;
    return members && members.length > 0 && !isLoading ? (
      <React.Fragment key={letter}>
        <div
          href={`#${letter}`}
          className="letter-title"
        >
          {letter}
        </div>
        <div className="letter-group-wrapper">
          {this.renderMembersList(members)}
        </div>
      </React.Fragment>
    ) : '';
  };

  renderSortedMembers = () => {
    const { members } = this.props;
    const sortedMembers = members.sort(Helpers.compareMembers);
    const dictionary = Helpers.getDictionary(ALPHABET);

    sortedMembers.forEach(member => {
      // Sort by Full name, if not exist - by email, otherwise - by phone
      const memberInfo = getMemberInfo(member);
      const sortField = memberInfo.sortString !== 'Unknown' ? memberInfo.sortString : '#';
      const sortChar = sortField[0].toLowerCase();

      if (dictionary[sortChar]) {
        dictionary[sortChar].push(member);
      } else {
        dictionary['#'].push(member);
      }
    });

    return ALPHABET.map(letter => this.renderMembersGroupByLetter(letter, dictionary[letter]));
  };

  render() {
    const { members, isLoading, viewMode, isFiltersOpen, isMemberAddMode } = this.props;

    let WindowHeight = 0;
    WindowHeight = isFiltersOpen ? WindowHeight + 515 : WindowHeight + 415;
    WindowHeight = isMemberAddMode ? WindowHeight + 200 : WindowHeight;

    return (
      <div className="members-list">
        <Scrollbars
          style={{ height: `calc(100vh - ${WindowHeight}px)` }}
          autoHide
          autoHeightMin={'100vh'}
          autoHeightMax={'100vh'}
          onUpdate={({ top }) => {
            if (top > 0.98 && !!this.props.onScrollFinished) {
              this.callScrollEndWithDebounce();
            }
          }}
        >
          <div className={`members-list-container ${viewMode}-view`}>
            {isLoading && <LoaderOverlay/>}
            {!isLoading && members.length > 0 && this.renderSortedMembers()}
          </div>
        </Scrollbars>
      </div>
    );
  }
}

MembersAlphabeticList.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedMembersHash: PropTypes.objectOf(PropTypes.bool).isRequired,
  isLoading: PropTypes.bool.isRequired,
  viewMode: PropTypes.oneOf(['grid', 'list']).isRequired,
  onEditMemberClick: PropTypes.func,
  onSelectMember: PropTypes.func.isRequired,
  onScrollFinished: PropTypes.func.isRequired,
};

MembersAlphabeticList.defaultProps = {
  onEditMemberClick: null,
};

export default MembersAlphabeticList;
