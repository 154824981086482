import Members from '../Api/Members';
import snackBarStatus from './snackbarActions';
import { removeAllGroupMembers } from './groupsActions';
import { REQUEST_CANCELLED_MESSAGE, SEARCH_MEMBERS_DELAY } from '../Config/Constants';
import { debounce } from 'lodash';

export const ACTIONS = {
  ADD: '[Members] Add',
  SET: '[Members] Set',
  SET_SEARCH: '[Members] Set from search',
  SET_LOAD_MORE: '[Members] Set by load more',
  SET_IMPORT_MEMBERS: '[Members] Set import members',
  SET_GROUP_ADMINS: '[Members] Set group admins',
  SET_REQ_PROPS: '[Members] Set request props',
  SET_PAGING: '[Members] Set paging',
  SET_LOADING: '[Members] Set loading',
  SET_LOADING_MORE: '[Members] Set loading more',
  CLEAR: '[Members] Clear',
  SELECT_MEMBER: '[Members] Select member',
  SELECT_ALL_CSV: '[Members] Select all csv members',
  CLEAR_SELECTED_MEMBERS: 'Members (Clear selected)',
  UPDATE_MEMBERS_TAB: '[Members] Update members tab',
  IMPORT_FILE: '[Members] Import file',
  UPDATE_IMPORT_MESSAGE: '[Members] Update import message',
  CHANGE_VIEW: '[Members] Change view',
  UPDATE_SPECIFIC_MEMBER: '[Members] Update specific member',
  REMOVE_MEMBERS: '[Members] Remove members from list',
  REMOVE_ALL_MEMBERS: '[Members] Remove all members from list',
};

export const setLoadingState = state => dispatch => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: state });
};

export const setLoadingMoreState = state => dispatch => {
  dispatch({ type: ACTIONS.SET_LOADING_MORE, payload: state });
};

export const setMembers = members => dispatch => {
  dispatch({ type: ACTIONS.SET, payload: members });
};

const setMembersBySearch = members => dispatch => {
  dispatch({ type: ACTIONS.SET_SEARCH, payload: members });
};

const setMembersByLoadMore = members => dispatch => {
  dispatch({ type: ACTIONS.SET_LOAD_MORE, payload: members });
};

export const setReqProps = props => dispatch => {
  dispatch({ type: ACTIONS.SET_REQ_PROPS, payload: props });
};

export const setMembersPaging = paging => dispatch => {
  dispatch({ type: ACTIONS.SET_PAGING, payload: paging });
};

const setGroupAdminsMembersList = (members) => dispatch => {
  dispatch({ type: ACTIONS.SET_GROUP_ADMINS, payload: members });
};

export const addMembers = (membersList, groupId) => async dispatch => {
  try {
    const data = {
      members: membersList,
      grant_permission: true,
    };
    const response = await Members.addMembers(data, groupId);
    if (response.status === 200) {
      snackBarStatus({
        payload: {
          title: response.data.message,
          type: 'success',
          enable: true,
        },
      })(dispatch);
    }
    return response.data;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const clearSelectedMembers = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_SELECTED_MEMBERS,
  });
};

/**
 * Performs an API call to search the group members given its id and a search term,
 * this is the version 2.0 with perfomance increased
 * @param {string} groupId publicId of the group which requested members belong
 * @param {string} term string to filter members by name/lastname/phone, etc.
 */

export const getGroupMembers = (
  groupId,
  term = '',
  isAdmin = false,
  page,
) => async (dispatch, getState) => {
  const {
    organizationReducer: { organization: { public_id: org } },
  } = getState();
  const {
    membersFiltersReducer: { filters: { status } },
  } = getState();
  
  try {
    setLoadingState(true)(dispatch);

    const req = {
      groups: [groupId],
      term: term ? term : '',
      isAdmin,
      org,
      status,
    };

    const response = await Members.getGroupsMembers(req, page);

    if (response.status === 200) {
      const { currentPage, nextPage, previousPage, totalPages, data: membersList } = response.data.data;

      setReqProps(req)(dispatch);

      setMembers(membersList)(dispatch);

      setMembersPaging({ currentPage, nextPage, previousPage, totalPages })(dispatch);
      setLoadingState(false)(dispatch);

      return membersList;
    }
    return [];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const getMembersToInvite = (groups, term = '', isPotentialGroupAdmin = false, page = 1) => async (dispatch, getState) => {
  const {
    organizationReducer: { organization: { public_id: org } },
  } = getState();

  try {

    setLoadingState(true)(dispatch);
    setMembers([])(dispatch);

    const req = {
      groups,
      term,
      org,
      isNotBelongToGroup: !isPotentialGroupAdmin,
      isPotentialGroupAdmin,
    };

    const response = await Members.getMembers(req, page);

    let membersList = [];
    if (response.status === 200) {

      const { currentPage, nextPage, previousPage, totalPages, data: membersList } = response.data.data;

      setReqProps(req)(dispatch);
      setMembers(membersList)(dispatch);
      setMembersPaging({ currentPage, nextPage, previousPage, totalPages })(dispatch);
      setLoadingState(false)(dispatch);
    }
    return membersList;
  } catch (error) {
    if (error.message !== REQUEST_CANCELLED_MESSAGE) {
      snackBarStatus({
        payload: {
          title: error.message,
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
    return error;
  }
};

export const searchInMembersListDebounce = debounce((dispatch, getState) => {
  clearMembersList()(dispatch);
  searchInMembersList()(dispatch, getState);
}, SEARCH_MEMBERS_DELAY);

export const searchInMembersListWithDebounce = () => (dispatch, getState) => {
  searchInMembersListDebounce (dispatch, getState);
};

export const searchInMembersList = () => async (dispatch, getState) => {
  const {
    membersReducer: { reqProps, membersList },
    membersFiltersReducer: { searchTerm },
  } = getState();
  const req = {
    ...reqProps,
    term: searchTerm,
  };

  try {
    setLoadingState(true)(dispatch);
    const response = await Members.getGroupsMembers(req, 1);
    if (response.status === 200) {
      const { currentPage, nextPage, previousPage, totalPages, data: newMembersList } = response.data.data;

      setReqProps(req)(dispatch);

      setMembersBySearch(newMembersList)(dispatch);

      setMembersPaging({ currentPage, nextPage, previousPage, totalPages })(dispatch);
      setLoadingState(false)(dispatch);

      return newMembersList;
    }

    setLoadingState(false)(dispatch);
    return membersList;

  } catch (error) {
    setLoadingState(false)(dispatch);
    if (error.message !== REQUEST_CANCELLED_MESSAGE) {
      snackBarStatus({
        payload: {
          title: error.message,
          type: 'error',
          enable: true,
        },
      });
    }
  }
};

export const getMoreMembers = () => async (dispatch, getState) => {
  const { membersReducer: { reqProps, paging, membersList } } = getState();
  try {
    if (paging.totalPages > paging.currentPage) {
      setLoadingMoreState(true)(dispatch);

      const response = await Members.getGroupsMembers(reqProps, paging.currentPage + 1);
      if (response.status === 200) {
        const { currentPage, nextPage, previousPage, totalPages, data: newMembersList } = response.data.data;

        setMembersByLoadMore(membersList.concat(newMembersList))(dispatch);

        setMembersPaging({ currentPage, nextPage, previousPage, totalPages })(dispatch);
        setLoadingMoreState(false)(dispatch);

        return newMembersList;
      }

      setLoadingMoreState(false)(dispatch);
      return membersList;
    }
  } catch (error) {
    setLoadingMoreState(false)(dispatch);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    });
  }
};

// didn't touch this one must take a look at it
export const removeMembers = (groupId, data) => async dispatch => {
  try {
    const response = await Members.removeMembers(groupId, data);
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.REMOVE_MEMBERS,
        payload: response.data.data.membersFound,
      });

      snackBarStatus({
        payload: {
          enable: true,
          title: 'Members Removed',
          type: 'success',
        },
      })(dispatch);
    }
    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};
export const addCaminoMembersToAll = (orgId, data) => async dispatch => {
  try {
    const response = await Members.addCaminoMembersToAll(orgId, data);
    if (response.status === 200) {

      snackBarStatus({
        payload: {
          enable: true,
          title: 'Member Added to All members',
          type: 'success',
        },
      })(dispatch);
    }
    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};
export const removeAllMembers = (groupId) => async dispatch => {
  try {
    const response = await Members.removeAllMembers(groupId);
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.REMOVE_ALL_MEMBERS,
      });

      removeAllGroupMembers(groupId)(dispatch);

      snackBarStatus({
        payload: {
          enable: true,
          title: 'All Members Removed',
          type: 'success',
        },
      })(dispatch);
    }
    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const updateMembersCamino = (groupId) => async dispatch => {
  try {
    const response = await Members.updateMembersCamino(groupId);
    if (response.status === 200) {
      snackBarStatus({
        payload: {
          enable: true,
          title: response.data.message,
          type: 'success',
        },
      })(dispatch);
    }
    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const removeRoleGroupAdmin = (groupId, publicId) => async (dispatch, getState) => {
  const { membersReducer: { membersList } } = getState();
  try {
    const response = await Members.removeRoleGroupAdmin({ members: [publicId] }, groupId);
    let adminList = [];
    if (response.status === 200) {
      adminList = membersList.filter(e => e.publicId !== publicId);
      setGroupAdminsMembersList(adminList)(dispatch);
      snackBarStatus({
        payload: {
          title: response.data.message,
          type: 'success',
          enable: true,
        },
      })(dispatch);
    }
    return adminList;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const updateGroupRoleMembers = (membersList, groupId) => async dispatch => {
  try {
    const data = {
      members: membersList,
    };
    const response = await Members.updateGroupRoleMembers(data, groupId);
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.ADD,
        payload: response.data,
      });
      snackBarStatus({
        payload: {
          title: response.data.message,
          type: 'success',
          enable: true,
        },
      })(dispatch);
    }
    return response.data;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const selectMember = publicId => dispatch => {
  dispatch({
    type: ACTIONS.SELECT_MEMBER,
    payload: publicId,
  });
};

export const selectAllCsvMembers = () => dispatch => {
  dispatch({
    type: ACTIONS.SELECT_ALL_CSV,
  });
};

export const updateMembersTab = tab => ({
  type: ACTIONS.UPDATE_MEMBERS_TAB,
  payload: tab,
});

export const updateImportMessage = message => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_IMPORT_MESSAGE,
    payload: message,
  });
};

export const importFile = file => async dispatch => {
  try {
    setLoadingState(true)(dispatch);
    const response = await Members.importFile(file);
    if (response.status === 200) {
      const {
        data: { details, process, summary },
      } = response.data;
      let title = '';
      let messageType = 'warning';
      let errMessage = '';
      let messageFooter = '';
      let changeRenderView;
      const successfullImports = [];
      let errCount = 0;
      details.forEach(element => {
        if (element.errors.length > 0) {
          errCount++;
          errMessage += `${element.line}, `;
        } else {
          successfullImports.push(element);
        }
      });
      let successMessage = `<p>${successfullImports.length} members can be imported.</p>`;

      const { emails, phones } = summary.duplicates;
      const isDuplicatedEmails = emails > 0;
      const isDuplicatedPhones = phones > 0;


      if (isDuplicatedEmails > 0 || isDuplicatedPhones) {
        let duplicatedMessage = `<br>Duplicates were found: 
          ${isDuplicatedEmails ? `<br>Emails - ${emails}.` : ''}
          ${isDuplicatedPhones ? `<br>Phones - ${phones}.` : ''}`;

        successMessage += `<p>${duplicatedMessage}</p>`;
      }

      if (successfullImports.length === 0 && errMessage === '') {
        title = 'Empty File';
      } else {
        title = 'Your import file has been reviewed.';
      }

      if (successfullImports.length > 0) {
        changeRenderView = 'membersList';
        messageFooter = '<br><p><b>Please select members below for import.</b></p>';
      } else {
        messageType = 'danger';
        changeRenderView = 'buttonImport';
        messageFooter = '<br><p><b>Please correct errors and upload a new file.</b></p>';
      }

      if (errMessage !== '') {
        errMessage = `<br><p>${errCount} lines failed because of errors.</p>
          <p>Invalid data on lines ${errMessage.trim().slice(0, -1)}</p>`;
      }

      const message = `${successMessage}${errMessage}${messageFooter}`;

      dispatch({
        type: ACTIONS.SET_IMPORT_MEMBERS,
        payload: {
          // TODO: return a correct format instead of mapping.
          memberImportList: successfullImports
            .map(i => {
              const { _source: { phonenumber, ...others } } = i;
              return { ...i, _source: { ...others, phoneNumber: phonenumber } };
            }),
          importProcessDetail: process,
          changeRenderView,
        },
      });

      updateImportMessage({
        show: true,
        message: message,
        title,
        type: messageType,
        buttonMessageAction: 'Upload a new file',
      })(dispatch);
    }
    setLoadingState(false)(dispatch);
    return response;
  } catch (error) {
    updateImportMessage({
      show: true,
      message: error.message,
      type: 'danger',
      buttonMessageAction: 'Upload a new file',
    })(dispatch);
    return error;
  }
};

export const importMembers = (
  processId,
  recipientGroups,
  desicion,
  selectedMembers,
) => async dispatch => {
  try {
    const response = await Members.importMembers(
      processId,
      recipientGroups,
      desicion,
      selectedMembers,
    );
    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const changeRenderView = view => dispatch => {
  dispatch({
    type: ACTIONS.CHANGE_VIEW,
    payload: view,
  });
};

export const clearMembersList = state => dispatch => {
  dispatch({ type: ACTIONS.CLEAR, payload: state });
};


export const updateSpecificMember = (publicId, data) => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_SPECIFIC_MEMBER,
    payload: {
      publicId,
      data,
    },
  });
};
